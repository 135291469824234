.details-header {
  .device-box__head-icon.no-state {
    svg {
      [stroke] {
        opacity: .4;
      }
      [fill] {
        opacity: .4;
      }
    }
  }
}
