.set-color-time-wrapper {
  margin-top: 16px;
  min-width: 77px;
  display: flex;
  align-items: center;
  padding: 12px 8px;
  background: var(--color-element);
  border-radius: 8px;
  box-sizing: border-box;

  &__icon {
    width: 32px;
    height: 32px;
  }

  &__value {
    margin-left: 8px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 600;
  }
}