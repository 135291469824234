.device-box--exalus {
  &.device-box {
    cursor: pointer;

    &--list-item {
      margin-bottom: 16px;
      min-height: 64px;

      &.device-box--details {
        min-height: 72px;

        .device-box__head {
          width: auto;
        }
      }

      .device-box__head-icon {
        width: 24px;
        height: 24px;
      }
    }

    &--hidden {
      display: none;
    }

    &--show-hidden {
      opacity: 0.6!important;
    }

    &:not(.device-box--list-item) {
      .device-box__head {
        &--small-height {
          height: 72px;
        }
      }
    }

    .device-box__head {
      .device-box {
        &__name {
          font-size: 14px;
        }

        &__color {
          height: 12px;
          width: 12px;
          border-radius: 50%;
          margin-right: 6px;
          background-color: var(--rgb-color);
        }
      }
    }

    .device-box__warning {
      padding: 16px;
      display: flex;
      align-items: center;
      color: var(--color-red);
      font-size: 12px;
      line-height: 24px;
      font-weight: 700;

      &--no-padding {
        padding: 0;
      }

      &.small-line-height {
        line-height: 16px;
      }
    }

    .device-box__head-icon {
      &.no-state {
        svg {
          [stroke] {
            opacity: .4;
          }
          [fill] {
            opacity: .4;
          }
        }
      }

      .icon-toast-warning-2.color-error, .icon-toast-warning-2.color-warning {
        position: absolute;
        font-size: 12px;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        top: -7px;
        right: -7px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        z-index: 1;
      }
    }

    .device-box__head-icon {
      position: relative;
      margin: 0 0 16px 0;

      &--small-margin {
        margin-bottom: 8px;
      }
    }

    .device-box__controls {
      margin-left: auto;
      padding: 0 16px;

      .cover-control-box__control {
        &:not(&:first-child) {
          margin-left: 24px;

          &.no-margin {
            margin-left: 0;
          }
        }
      }

      .cover-control-box__control--cover {
        &:not(&:first-child) {
          margin-left: 16px;
        }
      }

      .checkbox {
        margin-bottom: 0;
      }
    }
  }

  &.task-active {
    border-color: var(--color-accent);
    animation: borderAnimation 2s linear infinite;
  }

  @keyframes borderAnimation {
    0% {
      border-color: var(--color-accent);
    }
  
    50% {
      border-color: var(--color-element);
    }

    100% {
      border-color: var(--color-accent);
    }
  }

  &.is-error {
    border-color: var(--color-red);
  }

  &.is-warning {
    border-color: var(--color-warning);
  }

  &.device-box--list-item {
    .device-box__head-icon {
      margin: 0 16px 0 0;
    }
  }

  &.device-box--turn-on {
    &--turn-on {
      background-image: var(--turn-on);
    }
  }
}