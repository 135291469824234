.network-state {
  display: flex;
  align-items: center;

  .remembered {
    display: flex;
    align-items: center;
    margin-right: 12px;

    .icon-padlock {
      [stroke] {
        stroke: var(--color-type-primary);
      }
    }
  }
}