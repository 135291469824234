@import '../../../../../../styles/mixins.scss';

body {
  .eco-diagram-simple-container {
    position: relative;
    min-height: 270px;

    .icon-eco {
      position: absolute;
      left: 140px;
      top: 20px;

      @include rwd(365) {
        left: 105px;
        top: 30px;
      }

      [stroke] {
        stroke: var(--color-green);
      }
    }

    .eco-diagram-simple {
      max-height: 500px;

      @include rwd(365) {
        width: 260px;
      }
  
      .route-line {
        &.green {
          stroke: var(--color-green);
        }
  
        &.red {
          stroke: var(--color-red);
  
        }
      }
    
      .border-1, .border-2, .border-3 {
        stroke: #CBCBD1;
        stroke-width: 2px;
      }
    
      @keyframes moveGreenHalf {
        from { transform: translate3d(340px, 0, 0); }
        to { transform: translate3d(170px, 0, 0); }
      }

      @keyframes moveGreen {
        from { transform: translate3d(340px, 0, 0); }
        to { transform: translate3d(20px, 0, 0); }
      }
    
      @keyframes moveRed {
        from { transform: translate3d(20px, 0, 0); }
        to { transform: translate3d(220px, 0, 0); }
      }
    
      &.green {
        .border-3 {
          stroke: var(--color-green);
        }
    
        .border-2 {
          stroke: var(--color-green);
        }
    
        .border-1:not(.default) {
          stroke: var(--color-green);
        }
    
        .energy-circle {
          animation: moveGreen 6s linear infinite;

          &.half {
            animation: moveGreenHalf 3s linear infinite;
          }
        }
      }
    
      &.red {
        .border-1 {
          stroke: var(--color-red);
        }
    
        .border-2 {
          stroke: var(--color-red);
        }
    
        .energy-circle {
          animation: moveRed 4s linear infinite;
        }
      }
    }
  }

  

  &.dark {
    .eco-diagram-simple-container {
      .eco-diagram-simple {
        fill: var(--color-background);

        text {
          fill: var(--color-type-primary);
        }
    
        path {
          stroke: var(--color-type-primary);
        }
      }
    }
  }
}
