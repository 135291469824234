.submit-button {
  box-sizing: border-box;
  background-color: var(--color-background);
  padding: 20px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 3;
  display: flex;
  justify-content: center;

  .button {
    max-width: 600px;
  }

  &.tranparent {
    background-color: transparent;
  }

  &.above-all {
    z-index: 99999999;
  }

  &--background-element {
    background-color: var(--color-element);
  }
}
