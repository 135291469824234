.eco-bar {
  display: flex;
  flex-direction: column;

  &.disabled {
    opacity: 0.4;
  }

  &__element {
    position: relative;
    width: 96px;
    height: 4px;
    border-radius: 4px;
    background: var(--color-background);

    &::before {
      content: '';
      position: absolute;
      width: var(--width);
      height: 100%;
      border-radius: 4px;
      left: 0;
      top: 0;
      background: var(--background);
    }
  }
}