body {
  .chart {
    width: 100%;
    position: relative;
    margin: 24px -8px 0 -8px;
    
    .recharts-yAxis {
      .recharts-cartesian-axis-tick {
        font-size: 8px;
        line-height: 12px;
        
        text {
          fill: var(--color-type-secondary);
        }
      }
    }
    
    .recharts-xAxis {
      .recharts-cartesian-axis-tick {
        font-size: 11px;
        line-height: 14px;
        font-weight: 500;
        
        text {
          fill: var(--color-type-secondary);
        }
      }
    }
  }

  .dark {
    .chart {
      .recharts-yAxis {
        .recharts-cartesian-axis-tick {
          fill: var(--color-type-primary);
        }
      }

      .recharts-xAxis {
        .recharts-cartesian-axis-tick {
          text {
            fill: var(--color-type-primary);
          }
        }
      }
    }
  }
}
