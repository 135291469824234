.details-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  padding: 12px 24px 24px;
  margin: 0 -24px;
  background-color: var(--color-background);

  &--reverse {
    background-color: var(--color-element);
    width: calc(100% + 2 * 24px);
  }

  &--left {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 0 0 4px;

    &-flex {
      flex: 1;
    }
  }

  &__icon {
    border-radius: 10px;
    width: 42px;
    height: 42px;
    box-sizing: border-box;
    background: var(--color-element);
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    svg {
      width: 32px;
      height: 32px;

      [stroke] {
        stroke: var(--color-accent);
      }
  
      [fill] {
        fill: var(--color-accent);
      }
    }

    &--color-primary {
      svg {
        [stroke] {
          stroke: var(--color-type-primary);
        }

        [fill] {
          fill: var(--color-type-primary);
        }
      }
    }

    &--text {
      font-weight: 500;
      color: var(--color-accent);
    }
  }

  .divider {
    width: calc(100% - 2 * 25px);
  }

  &__relative-wrapper {
    position: relative;

    .icon-recovery,
    .icon-error-small,
    .icon-connection-error-wrapper {
      right: 8px;
    }
  }

  &__name {
    width: calc(100% - 96px);

    h2 {
      line-height: 24px;
    }
  }

  &__type {
    color: var(--color-type-secondary);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  &__subheader {
    background-color: var(--color-element);
    padding: 0 24px;
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  .on-off-control__icon--off {
    padding-right: 0;
    margin-left: 16px;
  }
}
