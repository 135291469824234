.backdrop {
  z-index: 10;
  color: var(--color-element);

  &-content {
    position: relative;

    .circle-button {
      position: absolute;
      border-radius: 50%;
      height: 78px;
      width: 78px;
      color: var(--color-element);
      background-color: var(--color-type-primary);
      min-width: 0;
      text-transform: none;
      font-size: 14px;
      top: 6px;
      left: 6px;
    }
  }
}