.icon-door {
  .sun {
    stroke: #fff!important;
  }
}

body.dark {
  .icon-door {
    .sun {
      stroke: #000!important;
    }
  }
}