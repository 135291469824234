.parsed-message {
  margin-bottom: 24px;

  p {
    font-size: 14px;

    &:first-of-type {
      font-weight: bold;
      font-size: 16px;
    }
  }
}