.group-item {
  border-radius: 8px;
  background-color: var(--color-element);
  height: 72px;
  margin-bottom: 16px;
  padding: 24px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  justify-content: space-between;
  font-size: 16px;
  font-weight: bold;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 24px);
  }

  &__action-icon {
    width: 24px;
    height: 24px;
  }

  &__draggable {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-handle {
      width: 36px;
      height: 36px;
      cursor: grab;

      // https://docs.dndkit.com/api-documentation/draggable#touch-action
      touch-action: none;
    }
  }
}
