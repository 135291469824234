.gate-controls {
  width: 100%;
  min-width: 320px;

  .direction {
    display: flex;
    justify-content: center;

    p {
      margin: 0;
      padding: 6px 12px;
      color: var(--color-type-primary);
      border: 1px solid var(--color-type-secondary);
      border-radius: 8px;
      font-size: 14px;
    }
  }

  &__content {
    max-width: 260px;
    width: 100%;
  }

  &__background {
    background-color: var(--color-background);
    border-radius: 32px;
    padding: 24px;
    max-width: 360px;
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &.active {
      border: 2px solid var(--color-accent);
    }
    
    .on-off-wrapper {
      box-shadow: 6.0625px 6.0625px 18.1875px rgba(0, 0, 0, 0.15);
      display: flex;
      align-items: center;
      border-radius: 16px;
      background-color: var(--color-element);
      width: 100%;

      &__divider {
        width: 2px;
        height: 77px;
        background-color: var(--color-background);
      }

      &__btn {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        height: 112px;
        background-color: var(--color-element);
        z-index: 1;
        width: 50%;

        &:not(:active) {
          transition: background-color var(--button-active-transition), color var(--button-active-transition);
        }

        &--active {
          background-color: var(--color-accent);
          color: var(--color-element);
        }

        &--on {
          border-bottom-left-radius: 16px;
          border-top-left-radius: 16px;
        }

        &--off {
          border-bottom-right-radius: 16px;
          border-top-right-radius: 16px;
        }

        &:active {
          color: var(--color-element);
          background-color: var(--color-accent);
          z-index: 2;

          svg {
            [stroke] {
              stroke: #fff;
            }
          }
        }
      }
    }

    .dependant-channels {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .btn-custom {
        &:first-of-type {
          margin-right: 4px;
        }
        &:last-of-type {
          margin-left: 4px;
        }
      }
    }

    .btn-custom {
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      height: 60px;
      background-color: var(--color-element);
      z-index: 1;
      width: 100%;
      border-radius: 16px;
      font-weight: 700;
      font-size: 18px;
      line-height: 24px;

      &:not(:active) {
        transition: background-color var(--button-active-transition), color var(--button-active-transition);
      }

      &.active {
        background-color: var(--color-accent);
      }

      &:active {
        color: var(--color-element);
        background-color: var(--color-accent);
        z-index: 2;

        .icon {
          [stroke] {
            stroke: #fff;
          }

          [fill] {
            fill: #fff;
          }
        }
      }

      &.shadow {
        box-shadow: 6.0625px 6.0625px 18.1875px rgba(0, 0, 0, 0.15);
      }

      .icon {
        margin-right: 16px;
        display: flex;
        align-items: center;

        .icon-vertical-gate {
          transform: scale(0.7);
        }
      }
    }
  }
}