.nav-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  width: 100%;
  height: 40px;

  .icon-close svg {
    width: 32px;
    height: 32px;
  }

  .icon-chevron {
    padding: 12px 12px 12px 0;
  }

  &--reverse {
    background-color: var(--color-element);
    width: calc(100% + 2 * 24px);
    padding: 20px 24px;
    margin: -24px -24px 0;
    box-sizing: border-box;
    height: 72px;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      left: 25px;
      background-color: var(--color-type-primary);
      width: calc(100% - 2 * 25px);
      opacity: 0.1;
      right: 25px;
      height: 1px;
      top: 71px;
    }
  }

  &__nav {
    padding: 0;
  }

  &__left {
    display: flex;
    align-items: center;
  }

  &__children {
    display: flex;
    align-items: center;

    > *:not(:last-child) {
      margin-right: 12px;
    }
  }

  &__divider {
    margin: 8px 0;
    width: calc(100% - 16px);
    position: relative;
    left: 8px;
  }
}
