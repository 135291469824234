.eco-box {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 16px;
  height: 154px;
  border-radius: 16px;
  background: var(--color);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__text {
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;

    &-with-background {
      padding: 4px 19px;
      background: #FFFFFF;
      color: var(--color);
      border-radius: 8px;
      margin-top: 2px;
    }
  }

  &__icon {
    position: absolute;
    top: 4px;
    right: -10px;
  }

  &--list-item {
    padding: 12px 16px;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    margin-bottom: 16px;
    height: auto;
    box-sizing: border-box;
    min-height: 64px;

    .eco-box {
      &__content {
        flex-direction: row;
      }

      &__icon {
        right: 0;
      }

      &__text {
        &-with-background {
          margin-top: 0;
          margin-left: 8px;
        }
      }
    }
  }
}