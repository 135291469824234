.rssi-button {
  margin-left: 8px;

  &:disabled {
    opacity: 0.7;
  }

  @keyframes rotating {
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
    }
  }

  &.rotating {
    animation: rotating 4s linear infinite;
  }
}