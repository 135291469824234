.reed-control {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: 600;

  span {
    margin-left: 8px;
  }

  &--details {
    font-size: 16px;
    font-weight: 700;

    span {
      margin-left: 16px;
    }
  }

  [stroke] {
    stroke: var(--color-accent);
  }
}