.icon-warning {
  [stroke] {
    stroke: var(--color-type-primary);
  }

  &.fill-yellow {
    circle {
      &:first-child {
        fill: var(--color-warning-yellow);
      }
    }

    [stroke] {
      stroke: #fff;
    }
  }

  &.fill-red {
    circle {
      &:first-child {
        stroke: var(--color-red);
        fill: var(--color-red);
      }
    }

    [stroke] {
      stroke: #fff;
    }
  }

  &.yellow {
    [stroke] {
      stroke: var(--color-warning-yellow);
    }
  }
}
