@import '../../styles/mixins.scss';

.header-with-help {

  .header__title {
    @include md() {
      width: auto;
      justify-content: flex-start;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    background-color: #fff;
    color: #15141d;
    border-radius: 50%;

    @include md() {
      margin-left: 24px;
    }
  }

  .no-padding {
    padding: 0;
  }
}
