.rgb-scene {
  padding: 16px;
  margin-bottom: 16px;
  border-radius: 16px;
  background: var(--gradient-background);
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  border: 1px solid var(--color-background);
  box-sizing: border-box;

  &__icon {
    margin-right: 16px;
    width: 40px;
    height: 40px;
    background: var(--color-element);
    border: 1px solid var(--color-background);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;

    svg {
      [stroke] {
        stroke: var(--color-type-primary);
      }
      [fill] {
        fill: var(--color-type-primary);
      }
    }

    &--custom-size {
      svg {
        width: 28px;
        height: 28px;
      }
    }
  }

  &__name-wrapper {
    padding: 0 8px;
    border-radius: 8px;
    background: rgba(var(--reverse-color), .4);
  }

  &__name {
    color: var(--color);
    margin: 0;
    font-weight: 600;
    line-height: 24px;
  }

  &--add-button {
    background: var(--color-background);

    .rgb-scene__name {
      color: var(--color-type-primary);
    }
  }
}