.button {
  width: 100%;
  border-radius: 8px;
  padding: 16px;
  font-size: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border: 1.5px solid var(--color-type-primary);
  background-color: var(--color-type-primary);
  color: var(--color-element);
  opacity: 1;
  cursor: pointer;
  background-position: center;
  transition: opacity 0.6s;

  &:active {
    opacity: 0.7;
    transition: opacity 0s;
  }

  &[disabled] {
    background-color: var(--color-element-inactive);
    border: 1.5px solid var(--color-element-inactive);
    cursor: default;
    opacity: 0.4;

    &:active {
      opacity: 1;
    }
  }

  &--invert {
    color: var(--color-type-primary);
    background-color: var(--color-element);
    border: 1.5px solid var(--color-element);

    &[disabled] {
      color: var(--color-element-inactive);
      background-color: var(--color-element);
      border: 1.5px solid var(--color-element);
      font-weight: 400;
      opacity: 0.4;
    }
  }

  &--secondary {
    background-color: transparent;
    color: var(--color-type-primary);

    &.no-disabled-background {
      &[disabled] {
        background-color: transparent;
        color: var(--color-element-inactive);
        opacity: 0.4;
      }
    }
  }

  & > * {
    margin: 0 16px;
  }

  &--accent {
    background-color: transparent;
    color: var(--color-accent);
    border: 1.5px solid var(--color-accent);
  }

  &--active-on-click {
    &:not(:active) {
      transition: background-color var(--button-active-transition), border-color var(--button-active-transition),
        color var(--button-active-transition);

      [stroke] {
        transition: stroke var(--button-active-transition);
      }
    }

    &:not([disabled]):active {
      background-color: var(--color-accent);
      border-color: var(--color-accent);
      color: var(--color-element);

      [stroke] {
        stroke: var(--color-element);
      }
    }
  }

  &--hidden {
    visibility: hidden;
  }

  &--link {
    background: none;
    border: none;
    width: auto;
    padding: 0;
    color: var(--color-accent);
  }

  &--select {
    background: var(--color-element);
    border: none;
    justify-content: space-between;
    color: var(--color-type-primary);
    height: 56px;
    margin: 24px 0;

    > * {
      margin: 0;
    }
  }
}

.header .button {
  padding: 0;
}
