.import-export {
  .import-area {
    border-radius: 12px;
    box-sizing: border-box;
    padding: 22px 16px;
    display: flex;
    background-color: var(--color-background);
    height: 72px;
    align-items: center;
    cursor: pointer;

    p {
      margin-left: 24px;
      font-weight: 700;
      font-size: 14px;
    }
  }
}