@mixin badge {
  position: absolute;
  bottom: -5px;
  right: -7px;
  background-color: var(--color-element);
  border: 1px solid var(--color-type-secondary);
  color: var(--color-type-primary);
  font-weight: 600;
  border-radius: 6px;
  height: 12px;
  width: 16px;
  font-size: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.channel-number {
  @include badge();
}

.channel-value-badge {
  @include badge();
  width: auto;
  min-width: 25px;
  padding: 3px;
  bottom: -12px;
  right: -16px;

  &.accent {
    color: var(--color-accent);
  }
}