.add-exalus-devices {
  height: 100%;
  position: relative;

  .exalus-devices-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .grouped-list {
      overflow-y: auto;
    }
  }

  .buttons {
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      max-width: 600px;
    }
  }
}

.stop-button {
  z-index: 333333;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);
  padding: 24px;
}