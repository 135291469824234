@import './mixins.scss';

:root {
  // light-mode by default
  --color-default: #ff4016;
  --color-background: #ebedf0;
  --color-background-secondary: #dfe1e4;
  --color-element: #ffffff;
  --color-accent: #ff4016;
  --color-type-primary: #15141d;
  --color-type-secondary: #55555c;
  --color-type-secondary-dark: #b9b8bb;
  --color-element-inactive: #cbcbd1;
  --color-element-inactive-dark: #9a9ba2;
  --color-red: #CC0909;
  --color-green: #00B191;
  --color-blue: #009EE3;
  --color-warning: #ff6016;
  --color-warning-yellow: #FCAF00;
  --color-success-green: #00B191;
  --hyperlink: #2929e3;

  --color-backdrop: rgba(22, 21, 20, 0.8);
  --button-active-transition: 0.3s ease-in;
  --color-shadow: rgba(0, 0, 0, 0.15);

  --slider-gradient-color: #ffffff;
  // dashboard
  --dashboard-tabs-color: var(--color-accent);
  --turn-on: linear-gradient(180deg, rgba(255,255,255,1) 40%, rgba(255,234,49,.2) 100%);
  --turn-on-shadow: 0px 0px 80px -7px rgba(255,234,49,1);

  .exalus {
    --color-default: #009EE3;
    --color-accent: #009EE3;
  }
}

body.dark {
  --color-default: #ff4016;
  --color-background: #15141d;
  --color-background-secondary: #15141d;
  --color-element: #31313f;
  --color-accent: #ff4016;
  --color-type-primary: #ffffff;
  --color-type-secondary: #b9b8bb;
  --color-type-secondary-dark: #b9b8bb;
  --color-element-inactive: #505064;
  --color-element-inactive-dark: #9696a4;
  --color-red: #CC0909;
  --color-green: #00B191;
  --color-blue: #009EE3;
  --color-warning: #ff6016;
  --color-warning-yellow: #FCAF00;
  --color-success-green: #00B191;
  --hyperlink: #dadaff;

  --color-shadow: rgba(255, 255, 255, 0.15);

  // dashboard
  --dashboard-tabs-color: var(--color-type-primary);
  --turn-on: linear-gradient(180deg, rgba(49,49,63,1) 40%, rgba(255,234,49,.2) 100%);
  --turn-on-shadow: 0px 0px 80px -7px rgba(255,234,49,1);

  &.exalus {
    --color-default: #009EE3;
    --color-accent: #009EE3;
  }
}

* {
  -webkit-tap-highlight-color: transparent;

  &:not(input) {
    user-select: none;
  }
}

::-webkit-scrollbar {
  display: none;
}

html {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--color-type-primary);
  background-color: var(--color-background);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  font-size: 14px;
  line-height: 1.7;
  font-weight: 600;
  height: 100%;
  overscroll-behavior: contain;
  overflow-x: hidden;

  #root {
    height: 100%;
  }

  &.no-scroll {
    overflow: hidden;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

h1,
h3,
h4 {
  font-weight: bold;
  font-size: 16px;
}

h2 {
  font-weight: bold;
  font-size: 18px;
}

h4 {
  margin: 8px 0;
}

p {
  margin: 8px 0;
}

button {
  font-family: 'Manrope', sans-serif;
  cursor: pointer;
  border: 0;
  background: transparent;
  outline: 0;
  font-weight: 600;
  color: var(--color-type-primary);
}

a {
  color: currentColor;
  text-decoration: none;
}

input {
  font-family: 'Manrope', sans-serif;
  font-weight: 600;
}

.full-width {
  width: 100vw;
  margin-left: -24px;
}

hr {
  border: 0;
  border-bottom: 1px solid var(--color-type-primary);
  width: 100%;
  opacity: 0.1;
  margin: 0;
}

.app-version {
  position: fixed;
  font-size: 8px;
  bottom: 0;
  right: 2px;
  height: 10px;
  font-weight: 400;
  z-index: 10;
}

.page-wrapper {
  display: flex;
  height: 100vh;
  flex-direction: column;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.clickable {
  cursor: pointer;
}

.page-list {
  padding-bottom: 24px;

  &__items-list {
    padding-bottom: 24px;
    @include rwd-grid(16px);

    .max-width-desktop {
      @include md() {
        max-width: none;
      }
    }
  }

  &__item:not(:first-child) {
    margin-top: 24px;
  }

  &__item:not(:last-child) {
    border-bottom: 1px solid var(--color-element-inactive);
    margin-bottom: 0;
  }
}

.caption {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  opacity: 0.7;
  margin-bottom: 8px;
  display: block;
}

div#dots_loader {
  position: relative;
  margin-top: 42px;
  display: flex;
  justify-content: center;

  .dot {
    display: inline-block;
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin: 0 8px 0 8px;
    background: var(--color-default);
    animation: wave 1.3s linear infinite;

    &:nth-child(2) {
      animation-delay: -1.1s;
    }

    &:nth-child(3) {
      animation-delay: -0.9s;
    }
  }
}

@keyframes wave {
  0%, 60%, 100% {
    transform: initial;
  }

  30% {
    transform: translate3d(0, -15px, 0);
  }
}

iframe {
  display: none;
}

.grid-list-16 {
  @include rwd-grid(16px);

  .max-width-desktop {
    @include md() {
      max-width: none;
    }
  }
}

.grid-list-24 {
  @include rwd-grid(24px);

  .max-width-desktop {
    @include md() {
      max-width: none;
    }
  }
}

.max-width-desktop {
  @include md() {
    max-width: 700px;
  }
}

.center-button-container {
  display: flex;
  align-items: center;
  justify-content: center;

  .button {
    max-width: 600px;
  }
}

.align-end {
  align-items: flex-end;
}

.w-full {
  width: 100%;
}

.border-box {
  box-sizing: border-box;
}