@import '../../../styles/mixins.scss';

.donut-chart-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 365px;
  margin-top: 24px;
  overflow: hidden;

  &__donut {
    display: flex;
    position: relative;
  }

  &__donut-element {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 24%;

    @include rwd(300) {
      top: 28%;
    }

    &-bg {
      height: 187px;
      width: 187px;
      border-radius: 50%;
      background-color: var(--color-background);
      opacity: 0.2;

      @include rwd(300) {
        height: 160px;
        width: 160px;
      }
    }

    &-info {
      background: var(--color-element);
      box-shadow: var(--shadow);
      border-radius: 50%;
      position: absolute;
      width: 81%;
      height: 81%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }

    &-text {
      margin: 4px 0 4px;
      color: var(--color-accent);
    }

    &-icon {
      margin-top: 12px;
      line-height: 24px;
      display: flex;
      align-items: center;

      &.up {
        transform: rotate(-90deg);

        [stroke] {
          stroke: var(--color-green);
        }
      }

      &.down {
        transform: rotate(90deg);

        [stroke] {
          stroke: var(--color-red);
        }
      }
    }

    &-value {
      font-size: 31px;
      font-weight: 500;
      color: var(--color-type-primary);
      line-height: 32px;
      margin-right: 4px;
    }

    &-unit,
    &-text {
      font-size: 14px;
      line-height: 24px;
    }

    .icon-tip {
      position: absolute;
      right: 20px;
      bottom: 5px;
    }
  }

  &__axis {
    position: absolute;
    height: 300px;
    width: 340px;

    @include rwd(375) {
      display: none;
    }

    &-element {
      font-size: 14px;
      font-weight: 600;
      color: var(--color-element-inactive-dark);
      position: absolute;
      background: var(--color-element);
      border-radius: 8px;
      transition: color 0.15s;

      &--active {
        color: var(--color, var(--color-type-primary));

        &-red {
          color: var(--color-red);
        }

        &-green {
          color: var(--color-green);
        }
      }

      &:first-child {
        left: 13%;
        bottom: 11%;
      }

      &:nth-child(even) {
        bottom: 60%;
        transform: translate3d(0, -64%, 0);
      }

      &:nth-child(2) {
        left: 2%;
      }

      &:nth-child(4) {
        right: 2%;
      }

      &:nth-child(3) {
        left: 50%;
        top: -10%;
        transform: translate3d(-50%, 0, 0);
      }

      &:last-child {
        bottom: 11%;
        right: 8%;
      }
    }
  }
}
