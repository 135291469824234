.video-work-mode {
  .urls {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    a {
      width: 100%;
      background-color: var(--color-element);
      border-radius: 8px;
      height: 80px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:last-of-type {
        margin-left: 12px;
      }

      [stroke] {
        stroke: var(--color-type-primary);
      }

      span {
        margin-top: 12px;
      }
    }
  }
}