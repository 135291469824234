@mixin rwd($screen) {
  @media (max-width: $screen+'px') {
    @content;
  }
}

@mixin md {
  @media (min-width: 800px) {
    @content;
  }
}

@mixin sm {
  @media (max-width: 400px) {
    @content;
  }
}

@mixin rwd-grid($gap) {
  display: grid;
  grid-template-columns: 100%;
  column-gap: $gap;
  
  @media (min-width: 800px) {
    grid-template-columns: repeat(2, calc(50% - 12px));
  }
}