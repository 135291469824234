.action-icon {
  display: flex;
  margin-right: 20px;
  position: relative;

  &--header {
    margin-right: 16px;
  }

  &--quantity-1 {
    &.action-icon--header {
      .action-icon__icon {
        border: 0;
        border-radius: 10px;
        width: 42px;
        height: 42px;

        > [class^='icon']:first-child {
          width: 28px;
          height: 28px;
        }
      }
    }
  }

  &--quantity-2 {
    .action-icon__icon {
      &:last-child {
        position: absolute;
        left: 6px;
        top: 6px;
      }
    }

    &.action-icon--header {
      padding: 5px 11px 11px 5px;
      background: var(--color-element);
      border-radius: 10px;

      .action-icon__icon {
        width: 24px;
        height: 24px;

        &:last-child {
          position: absolute;
          left: 11px;
          top: 11px;
        }
      }
    }
  }

  &__icon {
    border-radius: 6px;
    background: var(--color-element);
    border: 1px solid var(--color-accent);
    line-height: 0;
    width: 26px;
    height: 26px;
    display: flex;
    align-items: center;
    justify-content: center;

    > [class^='icon']:first-child {
      width: 16px;
      height: 16px;

      > svg {
        width: 16px;
        height: 16px;

        [stroke] {
          stroke: var(--color-accent);
        }
      }

      [stroke] {
        stroke: var(--color-accent);
      }

      [fill] {
        fill: var(--color-accent);
      }
    }
  }
}
