.event-list {
  display: flex;
  align-items: center;
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid var(--color-background);

  &__item {
    background-color: var(--color-element);
    display: flex;
    margin-right: 16px;
    border-radius: 6px;

    &-icon {
      height: 32px;
      width: 32px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      padding: 5px;
      justify-content: center;

      svg {
        &:not(.icon-arrow-link) {
          [stroke] {
            stroke: var(--color-accent);
          }

          [fill] {
            fill: var(--color-accent);
          }
        }
      }

      .icon-more--shape-square {
        svg {
          [fill] {
            fill: var(--color-background);
          }

          [stroke] {
            stroke: var(--color-type-primary);
          }
        }
      }
    }

    &--background {
      background: var(--color-background);
    }

    &-wrapper {
      text-align: left;
    }

    &-content {
      color: var(--color-type-secondary);
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
    }

    &-title {
      line-height: 24px;
      font-weight: 600;
      font-size: 16px;
      color: var(--color-type-primary);
    }
  }
}
