.icon-play {
  line-height: 0;

  path {
    transform: scale(0.75) translate3d(10px, 9px, 0);
  }

  [stroke] {
    stroke: var(--color-type-primary);
  }

  &__large {
    height: 96px;
    width: 96px;

    [stroke] {
      stroke-width: 3px;
    }
  }
}
