.popup-icon.popup-icon--user-edit {

  circle {
    fill: transparent;
  }

  g {
    path {
      fill: var(--color-type-secondary);
    }
  }
}