.share-groupsEdit {

  .loader {
    height: auto;
  }
  
  &-item {
    padding: 24px 16px;
    border-radius: 12px;
    background-color: var(--color-element);;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    p {
      width: 100%;
      margin: 0;

      &.access {
        color: var(--color-type-secondary);
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
}