.icon-toast-warning-2 {
  line-height: 0;

  &.color-error {
    svg {
      [fill] {
        fill: var(--color-red);
      }

      [stroke] {
        stroke: #fff;
      }
    }
  }

  &.color-warning {
    svg {
      [fill] {
        fill: var(--color-warning);
      }

      [stroke] {
        stroke: #fff;
      }
    }
  }
}