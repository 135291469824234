.info_page {
  .page {
    &__container {
      padding: 0;
    }

    &__header {
      padding: 24px 24px 0 24px;
    }

    &__content {
      padding: 24px;
      background-color: var(--color-element);;
      color: var(--color-type-primary);
      font-size: 14px;
    }
  }
}

.channel_configuration {

  &--content {
    width: 100%;

    .arrow_button, form {
      width: 100%;   
    }
  }

  .row_container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.margin_top {
      margin-top: 8px;
    }

    &.space {
      margin-bottom: 24px;
    }

    p {
      color: var(--color-type-primary);
      font-weight: 400;
      font-size: 16px;
      margin: 0;
    }

    > span {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: var(--color-type-secondary);
    }

    .switch {
      width: auto;
    }

    &.space-between {
      button {
        &:first-of-type {
          margin-right: 8px;
        }
  
        &:last-of-type {
          margin-left: 8px;
        }
      }
    }
  }

  hr:not(.tabs__line) {
    border: 0;
    border-bottom: 1px solid var(--color-type-primary);
    width: 100%;
    margin: 24px 0;
  }

  .radios_group {
    label {
      padding: 0;
      border-bottom: 0;
      font-weight: 400;

      &:first-of-type {
        margin-bottom: 16px;
      }
    }

    hr {
      display: none;
    }
  }

  .accordion__content {
    padding-top: 12px;
  }

  .page__container {
    padding-top: 0;

    .above {
      .page__container {
        padding-top: 24px;
      }
    }
  }
}
