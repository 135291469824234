.summary {
  &--background-element {
    background: var(--color-element);
  }

  &__caption {
    display: flex;
    align-items: center;
    padding: 24px;

    &-text {
      margin-left: 16px;
      font-weight: 700;
      font-size: 16px;
      line-height: 24px;
    }
  }

  &__section {
    position: relative;
    padding: 16px 24px;
    display: flex;
    flex-direction: column;

    &--border {
      &:after {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        border-top: 1px solid var(--color-element-inactive-dark);
        opacity: 0.2;
        width: 100%;
      }
    }

    &-row {
      padding: 16px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-weight: 500;
      font-size: 16px;
      line-height: 24px;
    }

    &-label {
      font-weight: 700;
      font-size: 16px;
      margin-bottom: 12px;
      display: flex;
      align-items: center;

      .label-color {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        margin-left: 12px;
      }
    }

    &-value {
      font-weight: 700;
    }
  }
}
