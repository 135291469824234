.advanced-action-device-parameters {
  &__rgb {
    white-space: nowrap;
  }

  &__rgb-indicator {
    background-color: var(--rgb-color);
    border-radius: 50%;
    border: 1px solid var(--color-element-inactive);
    width: 12px;
    height: 12px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    margin-left: 6px;
  }
}
