.action-details {
  &__title {
    font-size: 24px;
    line-height: 32px;
    width: 100%;
  }

  &__subtitle {
    opacity: 0.5;
  }

  &__details {
    display: flex;
    align-items: flex-start;
  }

  &__controls {
    margin-top: 26px;
  }

  .cover--detail-view.action-details__controls {
    margin-top: 24px;
  }

  &__divider {
    margin: 40px 0;
  }

  &__rgb-slider {
    min-height: 132px;
  }
}