.controller-settings {

  &.no-padding {
    .page__container {
      padding: 0;
    }
  }
  
  .controller-info {
    display: flex;
    align-items: center;
    margin: 32px 0 32px 0;

    .readonly-info {

      &:last-child {
        margin-left: 24px;
      }

      p {
        margin: 0;
      }

      .label {
        color: var(--color-type-primary);
        font-weight: 700;
        font-size: 16px;
      }

      .value {
        color: var(--color-type-secondary);
        font-size: 16px;
        font-weight: 500;
      }

      .show_pin {
        background-color: var(--color-element);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 28px;
        width: 56px;
        border-radius: 8px;
      }
    }
  }
}

.controller-updates {
  .page__container {
    background-color: var(--color-element);
    flex: 1 0 100%;
  }
}