.empty-state-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 80px;

  &__text {
    text-align: center;
    white-space: pre-wrap;
    max-width: 251px;
  }

  &__caption {
    color: var(--color-element-inactive-dark);
    font-size: 16px;
    line-height: 24px;
    font-weight: bold;
  }
}
