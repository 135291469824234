.time-ranges {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 16px;

  &__element {
    border-radius: 8px;
    background-color: var(--color-background);
    padding: 8px 22px;
    line-height: 24px;
    font-weight: bold;
    font-size: 16px;
    text-align: center;
    width: 100%;
    max-width: 100px;
    cursor: pointer;
    transition: color 0.15s;
    color: var(--color-element-inactive-dark);
    margin-bottom: 12px;
    margin-right: 12px;

    &--active {
      color: var(--color-accent);
    }
  }
}
