.location_map_page {
  position: relative;

  .page__container {
    padding: 0;

    .page__header {
      padding: 24px;

      .header {
        margin-bottom: 0;
      }
    }
  }

  .search_container {
    padding: 24px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
  }

  .submit_location {
    background-color: var(--color-background);
    padding: 12px 24px 20px 24px;
    height: 126px;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    box-sizing: border-box;

    .address {
      font-size: 12px;
      font-weight: 700;
      color: var(--color-type-primary);
      height: 14px;
      text-align: center;
      margin: 0 0 20px;
    }

    .submit-button {
      position: static;
      background-color: unset;
      width: 100%;
      padding: 0;
      bottom: unset;
      left: unset;
    }
  }

  .my_location {
    position: absolute;
    bottom: 136px;
    right: 30px;
    background-color: var(--color-background);
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999;

    svg {
      g {
        fill: var(--color-type-primary);
      }
    }

    div#dots_loader {
      margin-top: 0;

      .dot {
        width: 5px;
        height: 5px;
        margin: 0 2px 0 2px;
        background: var(--color-type-primary);
      }
    }
  }
}

.page.installation-settings {
  &.hide {
    .above.location_map_page {
      .page__header {
        padding: 0 0 24px 0;
      }
    }
  }
}