body {

  .icon-signal {
    display: flex;
    align-items: center;

    .inactive {
      stroke: #CBCBD1;
      opacity: 0.75;
    }
  
    .active {
      stroke: #15141D;
  
      &.dot {
        fill: #15141D;
      }
    }
  }

  &.dark {
    .icon-signal {
      .inactive {
        stroke: #CBCBD1;
      }
    
      .active {
        stroke: #fff;
    
        &.dot {
          fill: #fff;
        }
      }
    }
  }
}
