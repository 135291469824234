.wifi-configuration {
  .page__content {
    display: flex;
    justify-content: center;

    .max-width-desktop {
      width: 100%;
    }
  }

  .refresh-btn {
    display: flex;
    align-items: center;
    border-radius: 50%;
    height: 36px;
    width: 36px;
    background-color: var(--color-element);
  }

  .all-checkbox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 16px;
  }
}

.connected-devices {
  .connected-item {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    .status-icon {
      height: 24px;
      width: 24px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
    }

    span {
      text-decoration: none;
    }
  }
}