.cover-button.button {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 16px 4px;

  > * {
    margin: 0;
  }

  > [class^='icon'] {
    margin-right: 16px;

    [stroke] {
      stroke: var(--color-type-primary);
    }

    &:not(.icon-vent) {
      [fill] {
        fill: var(--color-type-primary);
      }
    }
  }

  &.inactive {
    color: var(--color-element-inactive);
    background-color: var(--color-element);
    border: 1.5px solid var(--color-element);
    font-weight: 400;

    > [class^='icon'] svg path {
      stroke: var(--color-element-inactive);
      fill: none;
    }

    > [class^='icon'] {
      [stroke] {
        stroke: var(--color-element-inactive);
      }
    }

    &:active {
      background-color: var(--color-accent);
      color: var(--color-element);
      border: 1.5px solid var(--color-accent);

      [stroke] {
        stroke: var(--color-element);
      }

      > .icon-favorite {
        [fill] {
          fill: var(--color-element);
        }
      }
    }
  }

  &.is-on {
    background-image: var(--turn-on);
  }

  &:not(:active) {
    transition: background-color var(--button-active-transition), color var(--button-active-transition);
    background-color: var(--color-element);

    [stroke] {
      transition: stroke var(--button-active-transition);
    }

    [fill] {
      transition: fill var(--button-active-transition);
    }
  }

  &:active {
    [stroke] {
      stroke: var(--color-type-primary);
    }
  }

  &.hidden {
    display: none;
  }
}
