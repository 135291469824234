.edit-avatar {
  &__avatar {
    width: 40px;
    height: 40px;
    object-fit: cover;
    border-radius: 50%;
  }

  &__option {
    margin-top: 24px;

    &-header {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      opacity: 0.7;
      margin-bottom: 8px;
      display: inline-block;
    }
  }

  &__input {
    border-radius: 12px;
    box-sizing: border-box;
    padding: 22px 16px;
    display: flex;
    background-color: var(--color-element);
    height: 72px;
    align-items: center;
    cursor: pointer;

    &--left {
      display: flex;
      align-items: center;
    }

    .checkbox {
      margin-bottom: 0;
    }

    &--checkbox {
      padding-right: 0;
    }

    &-file {
      display: flex;
      flex: 1;
      justify-content: flex-end;

      span {
        max-width: 150px;
        display: inline-block;
      }
    }
  }
}
