.rgb-scene-form-list {
  padding-bottom: 149px;

  &-buttons {
    background: var(--color-element);
    box-sizing: border-box;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 24px;
    border-top: 1px solid var(--color-background);

    button {
      &:first-of-type {
        margin-bottom: 16px;
      }
    }
  }
}