@import '../../../../../../styles/mixins.scss';

.exalus-grouped-device-channels {
  .accordion {
    &:first-child {
      &::before {
        display: none;
      }
    }
  }
}