@import '../../styles/mixins.scss';

:root {
  --slider-input-height: 290px;
}

.slider {
  position: relative;
  height: calc(var(--slider-input-height) - 15px);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 315px;

  &--width-auto {
    width: auto;
  }

  &__value {
    border: 1px solid var(--color-element-inactive);

    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    width: 63px;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: calc(var(--target-position) - 24px);
    left: 0;

    &-handle {
      position: absolute;
      right: -20px;

      path,
      circle {
        stroke: var(--color-element-inactive);
      }
    }
  }

  &__bg-element-border {
    border: 1px solid var(--color-element-inactive);
    opacity: 0.3;
    border-radius: 32px;
    height: 320px;
    width: 135px;
  }

  &__bg-element {
    background-color: var(--color-element-inactive);
    opacity: 0.8;
    border-radius: 32px;
    height: 320px;
    width: 135px;
    box-sizing: border-box;
    position: relative;
    
    &.error {
      border: 2px solid var(--color-red);
    }

    &.no-bg-element {
      background-color: var(--color-element);
      border: 3px solid var(--color-element-inactive);
    }

    &.active {
      border: 2px solid var(--color-accent);
      animation: borderAnimation 2s linear infinite;

      &.no-bg-element {
        border: 3px solid var(--color-accent);
      }
    }

    @keyframes borderAnimation {
      0% {
        border-color: var(--color-accent);
      }
    
      50% {
        border-color: var(--color-element-inactive);
      }
  
      100% {
        border-color: var(--color-accent);
      }
    }
  }

  &__slider-element {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  input[type='range'] {
    width: var(--slider-input-height);
    height: 102px;
    border-radius: 16px;
    transform: rotate(90deg);
    touch-action: none;
    cursor: pointer;
    background: linear-gradient(to right, var(--color-accent), var(--color-accent)), var(--color-element);
    background-size: var(--slider-position, 0%) 100%;
    background-repeat: no-repeat;
    
    // &.animation-active {
    //   animation: sliderPulse 2s linear infinite;
    // }

    // @keyframes sliderPulse {
    //   0% {
    //     opacity: 1;
    //   }
    //   50% {
    //     opacity: 0.75;
    //   }
    //   100% {
    //     opacity: 1;
    //   }
    // }
  }

  input[type='range'],
  input[type='range']::-webkit-slider-thumb {
    -webkit-appearance: none;
  }

  input[type='range']:focus {
    outline: none;
  }

  input[type='range']::-webkit-slider-thumb {
    width: 18px;
    height: 112px;
    display: block;
    background: var(--color-element-inactive);
    border: 5px solid var(--color-element);
    box-shadow: 6.0625px 6.0625px 18.1875px rgba(0, 0, 0, 0.15);
    border-radius: 19.4px;
    margin-top: -5px; // (112 width handler - 102 width input) / 2, it is needed only on chrome
    cursor: pointer;
    top: 10%;
  }

  input[type='range']::-moz-range-thumb {
    width: 8px;
    height: 112px;
    background: var(--color-element-inactive);
    border: 5px solid var(--color-element);
    box-shadow: 6.0625px 6.0625px 18.1875px rgba(0, 0, 0, 0.15);
    border-radius: 19.4px;
    cursor: pointer;
  }

  input[type='range'].bar-accent::-webkit-slider-thumb {
    background: var(--color-accent);

  }

  input[type='range'].bar-accent::-moz-range-thumb {
    background: var(--color-accent);
  }

  &--reverse {
    .slider__value {
      top: auto;
      bottom: calc(var(--target-position) - 24px);
    }

    input[type='range'] {
      transform: rotate(-90deg);
    }
  }

  &.white-light {
    input[type='range'] {
      background: linear-gradient(90deg, rgba(255,215,61,1) 0%, rgba(255,255,255,1) 40%, rgba(255,255,255,1) 50%, rgba(168,223,255,1) 100%);
    }
  }

  &.wider {
    .slider {
      &__bg-element {
        width: 198px;
      }

      &__slider-element {
        input[type='range'] {
          height: 168px;
        }

        input[type='range']::-moz-range-progress {
          height: 168px;
        }

        input[type='range']::-webkit-slider-thumb {
          height: 178px;
        }

        input[type='range']::-moz-range-thumb {
          height: 178px;
        }
      }

      &__value {
        left: -28px;

        @include rwd(380) {
          left: 5px;
          padding: 8px;
          width: 50px;

          &-handle {
            display: none;
          }
        }
      }
    }
  }

  &.horizontal {
    height: auto;

    input[type='range'] {
      transform: none;
      height: 58px;
      width: 290px;
      border-radius: 24px;
    }

    input[type='range']::-webkit-slider-thumb {
      height: 65px;
    }

    input[type='range']::-moz-range-thumb {
      height: 65px;
    }

    .slider {
      &__bg-element {
        height: 80px;
        width: 320px;
      }
  
      &__value {
        display: none;
      }
    }
  }
}
