.page-start-flow {
  .page {
    &__content {
      display: flex;
      align-items: center;

      .center-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;

        .header__title {
          justify-content: center;

          &-main {
            max-width: 300px;
            text-align: center;
          }
        }

        .sub-header {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          margin: 0;
          color: var(--color-type-secondary);
          max-width: 280px;
          text-align: center;
        }
      }
    }
  }
}