.share-installation-count {

  &--info {
    color: var(--color-type-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    span {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}