.backdrop-popup {
  z-index: 10;
  color: var(--color-element);

  &--content {
    max-width: 600px;
    position: relative;
    background-color: var(--color-element);
    width: calc(100% - 24px);
    padding: 24px 0;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .linear-progress {
      margin-bottom: 24px;

      &:last-of-type {
        margin-bottom: 0;
      }
    }

    .backdrop-loader {
      margin: 0 0 18px 0;

      svg {
        color: var(--color-accent);
      }
    }

    .close-btn {
      margin-top: 24px;
      color: var(--color-type-primary);
      font-weight: 700;
      font-size: 16px;

      &:disabled {
        opacity: 0.4;
      }
    }

    .exalus-button-container {
      display: flex;
      justify-content: center;

      .button {
        color: var(--color-accent);
        border: none;
        min-width: 120px;
        padding: 0 16px;
        font-weight: 700;
        font-size: 16px;
        border-radius: 0;
        line-height: 18px;

        &:nth-child(2) {
          border-left: 1px solid var(--color-element-inactive);
        }
      }
    }

    .circle-button {
      position: absolute;
      border-radius: 50%;
      height: 78px;
      width: 78px;
      color: var(--color-element);
      background-color: var(--color-type-primary);
      min-width: 0;
      text-transform: none;
      font-size: 14px;
      top: 6px;
      left: 6px;
    }
  }

  &--header {
    color: var(--color-type-primary);
    font-size: 18px;
    font-weight: 700;
    text-align: center;
    padding: 0 12px;
  }

  &--info {
    color: var(--color-type-secondary);
    font-size: 14px;
    font-weight: 400;
    padding: 0 16px;
    text-align: center;
    margin: 0 0 24px 0;

    p {
      margin: 0;
    }

    &.bold {
      color: var(--color-type-primary);
      font-size: 18px;
      font-weight: 700;
    }

    span {
      font-weight: 700;
    }
  }

  &--warning {
    color: var(--color-accent);
    font-size: 12px;
    font-weight: 500;
    padding: 0 16px;
    text-align: center;
    margin: 12px 0 24px 0;
  }

  .accordion {
    color: var(--color-type-primary);
    width: 100%;
    padding: 0 24px;
    box-sizing: border-box;

    &::before {
      display: none;
    }

    &__header {
      justify-content: flex-start;

      &-content {
        flex-grow: unset;
      }
    }

    &__content {
      padding-top: 12px;
    }
  }
}