.icon-radio {
  line-height: 0;

  [stroke] {
    stroke: var(--color-type-primary);
  }

  &--selected {
    [stroke] {
      stroke: var(--color-accent);
    }

    .dot {
      fill: var(--color-accent);
    }
  }
}
