.energy-consumption {
  width: 100%;

  &__button-group {
    display: flex;
    width: 100%;
    margin-bottom: 32px;
    position: relative;
    z-index: 1;
    box-sizing: border-box;
  }
}