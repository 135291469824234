.grouped-device-channels {
  margin-bottom: 24px;

  .arrow {
    height: 34px;

    .icon-chevron {
      margin: 0;

      [stroke] {
        stroke: var(--color-accent);
      }
    }
  }

  .grouped-channels {
    padding-left: 16px;
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;

    &.expanded {
      margin-top: 16px;
      max-height: 1500px;
      transition: max-height 0.25s ease-in;
    }

    .device-box--exalus {
      &.device-box {
        cursor: auto;
      }
    }
  }
}