.icon-delete {
  line-height: 0;

  path,
  rect {
    stroke: var(--color-type-primary);
  }

  &--element {
    path,
    rect {
      stroke: var(--color-element);
    }
  }
}
