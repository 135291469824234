.dialog-time-picker {
  padding: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  &__heading {
    font-size: 18px;
    line-height: 24px;
  }

  &__time-wrapper {
    display: flex;
    justify-content: center;
  }

  &__time {
    flex: 1;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
  }

  &__divider {
    width: 1px;
    background-color: rgba(var(--color-type-primary), 0.1);
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 16px 0 0;
    position: sticky;
    bottom: 0;
    background-color: var(--color-element);
  }

  &__button {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    width: 50%;
    position: relative;
    padding: 10px 0;

    &--active {
      color: var(--color-accent);
      position: relative;

      &:before {
        content: "";
        position: absolute;
        border-left: 1px solid var(--color-type-primary);
        opacity: 0.1;
        height: 100%;
        left: 0;
        top: 0;
      }
    }
  }

  .time-picker-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }

  .time-picker-unit {
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    position: absolute;
    transform: translate3d(65px, 0, 0);
  }

  .picker {
    height: 200px;
    overflow: hidden;

    &-divider {
      font-size: 24px;
      font-weight: 700;
      line-height: 32px;
    }

    .swiper-slide {
      .time {
        font-size: 20px;
        line-height: 40px;
        font-weight: 500;
        color: var(--color-element-inactive);
        width: 88px;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      &.swiper-slide-active {
        .time {
          font-size: 40px;
          font-weight: 700;
          color: var(--color-type-primary);
        }
      }

      &.swiper-slide-duplicate-prev,
      &.swiper-slide-prev,
      &.swiper-slide-duplicate-next,
      &.swiper-slide-next {
        .time {
          font-size: 24px;
        }
      }
    }
  }

  .picker > div {
    width: 100%;
    height: 100%;
  }

  .picker > div .time {
    text-align: center;
    width: 48px;
  }

  &--font-small {
    .picker {
      .swiper-slide {
        .time {
          font-size: 14px;
        }

        &.swiper-slide-active {
          .time {
            font-size: 24px;
          }
        }

        &.swiper-slide-duplicate-prev,
        &.swiper-slide-prev,
        &.swiper-slide-duplicate-next,
        &.swiper-slide-next {
          .time {
            font-size: 18px;
          }
        }
      }
    }
  }
}
