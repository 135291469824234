.exalus-action-details {
  .text-info {
    margin-bottom: 24px;
  }

  .icon-actions {
    stroke: var(--color-accent);
  }

  .active-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 24px 0;

    p {
      margin: 0;
      font-size: 16px;
    }

    .switch {
      width: auto;
    }
  }

  &--condition {
    margin-bottom: 12px;

    .condition-header {
      display: flex;
      align-items: center;

      svg {
        margin-right: 12px;
      }
    }
  }

  &--task {
    margin-bottom: 12px;
  }
}