.icon-search {
  line-height: 0;

  path {
    stroke: var(--color-type-primary);
  }

  circle {
    fill: var(--color-type-primary);
  }
}
