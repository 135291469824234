.share-restriction {
  
  .page__content {
    display: flex;
    justify-content: center;

    .max-width-desktop {
      width: 100%;
    }
  }
}