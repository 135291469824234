.icon-password {
  line-height: 0;

  [stroke] {
    stroke: var(--color-type-primary);
  }
  circle {
    fill: var(--color-type-primary);
  }
}
