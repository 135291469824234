.control-wrapper {
  background-color: var(--color-element);
  overflow-y: auto;
  padding: 24px;
  position: relative;
  display: flex;
  justify-content: center;
  min-height: 350px;
  box-sizing: border-box;
  align-items: center;
  flex: 1;
  width: 100%;
  
  &--medium {
    max-height: 380px;
    height: 380px;
  }

  &--column {
    flex-direction: column;
    justify-content: flex-start;
    align-items: normal;
    max-height: 100%;
    overflow: hidden;
  }

  &--height-auto {
    min-height: auto;
  }

  &--stretch {
    align-items: stretch;
  }

  &--full-space {
    flex: 1;
    height: 100%;
    position: relative;

    &.active {
      border: 2px solid var(--color-accent);
    }

    &.column {
      flex-direction: column;
    }

    .empty-state-box {
      position: absolute;
      top: 24px;
      left: 24px;
      width: calc(100vw - 48px);
    }

    .not-connected,
    .icon-connection-error-wrapper {
      top: 24px;
      right: 24px;
      z-index: 3;
    }

    .absolute-info {
      position: relative;

      &--value {
        position: absolute;
        top: -80px;
        right: -80px;
        font-size: 28px;
        color: var(--color-accent);
        display: flex;
        align-items: center;

        &.no-state {
          opacity: .4;
        }
      }
    }

    .sensor {
      margin-top: 50px;

      &--value {
        font-size: 26px;
        color: var(--color-accent);
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 200px;

        &.no-state {
          opacity: .4;
        }

        .message {
          font-size: 18px;
        }
      }
    }
  }

  &--not-full-space {
    flex: none;
  }

  &.no-center {
    align-items: flex-start;
  }

  &--space-evenly {
    justify-content: space-evenly;
  }

  &--hidden {
    display: none;
  }

  .empty-state-box {
    position: absolute;
    top: 24px;
    left: 24px;
    right: 24px;
    width: auto;
  }
}
