.device-box--exalus {
  &--remote {

    .device-box {
      &__controls {
        align-items: center;
  
        .arrow {
          height: 34px;
  
          .icon-chevron {
            margin: 0;
  
            [stroke] {
              stroke: var(--color-accent);
            }
          }
        }
      }
    }

    .remote-channels {
      padding-left: 16px;
      max-height: 0;
      transition: max-height 0.15s ease-out;
      overflow: hidden;

      &.expanded {
        margin-top: 16px;
        max-height: 1500px;
        transition: max-height 0.25s ease-in;
      }
    }
  }
}

.exalus-dialog {
  .dialog__content {
    background: var(--color-background);
  
    .remote-channels {
      overflow-y: auto;
      max-height: 390px;
      padding: 24px 12px;
    }
  
    .close-button {
      margin-top: 24px;
      font-size: 16px;
      font-weight: bold;
      width: 100%;
      min-height: 40px;
      color: var(--color-accent);
    }
  }
}