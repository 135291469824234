.grid {
  display: grid;
  gap: 1rem;

  &--2 {
    grid-template-columns: repeat(auto-fill, minmax(154px, 1fr));
  }

  &--3 {
    grid-template-columns: repeat(auto-fill, minmax(88px, 1fr));
  }

  &--small-gap {
    gap: .5rem;
  }
}
