.icon-pause {
  line-height: 0;

  [stroke] {
    stroke: var(--color-type-primary);
  }

  text {
    fill: var(--color-type-primary);
  }

  &--container {
    display: flex;
    align-items: center;
  }
}
