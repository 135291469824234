.charts-wrapper {
  width: 100%;

  .control-wrapper {
    padding-bottom: 48px;
  }

  .empty-states {
    text-align: center;
    font-size: 18px;
    font-weight: 700;
    width: 100%;
  }

  .exalus-chart-container {
    width: 100%;

    .time-ranges {
      padding-top: 16px;
    }

    .recharts-legend-wrapper {
      bottom: -20px!important;

      .legend-custom {
        display: flex;
        flex-direction: column;
  
        li {
          display: flex;
          align-items: center;
  
          .dot {
            height: 8px;
            width: 8px;
            border-radius: 50%;
            margin-right: 12px;
          }
  
          span {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }
    }
  
    .date-picker {
      padding-bottom: 24px;
    }
  
    .binary-chart {
      position: relative;
    
      .axis-icon {
        position: absolute;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        bottom: 25px;
        left: -5px;
        
  
        &:nth-child(2) {
          left: -2px;
          bottom: unset;
          top: -25px;
        }
  
        span {
          color: var(--color-type-secondary);
          font-size: 10px;
        }
  
        .icon-sensor {
          height: 25px;
          width: 24px;
      
          [stroke] {
            stroke: var(--color-accent);
          }
        }
      }

      .recharts-layer.recharts-cartesian-axis {

        &.recharts-xAxis, &.recharts-yAxis {
          g {
            font-size: 12px;
          }
        }
      }
    }

    .arrow_btn {
      background-color: var(--color-background);
    }

    .chart-pagination {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-top: 20px;

      button {
        display: flex;
        align-items: center;
        padding: 12px;

        &:disabled {
          opacity: 0.5;
        }

        &.back {
          transform: rotate(180deg);
        }
      }
    }
  }
}