.dialog-select {
  padding: 0 24px;

  &__header {
    display: flex;
    align-items: center;
    line-height: 24px;
    text-align: left;
    margin-bottom: 8px;
  }

  &__option-item {
    display: flex;
    align-items: center;
    cursor: pointer;

    &-icon {
      flex-shrink: 0;
      margin-right: 16px;
    }

    &-icon-radio {
      flex-shrink: 0;
      margin-right: 16px;
    }

    &-icon-option {
      width: 38px;
      height: 38px;

      [class^=icon] {
        align-items: center;
        display: flex;
        height: 38px;
        justify-content: center;
        width: 38px;

        [stroke] {
          stroke: var(--color-type-primary);
        }
      }
    }

    &-label {
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      font-size: 16px;
      width: 100%;
      text-align: left;

      &.bold {
        font-weight: 600;
      }

      &-color {
        border: 1px solid var(--color-element-inactive);
        width: 16px;
        height: 16px;
        border-radius: 50%;
        flex-shrink: 0;
      }

      .sub-label {
        color: var(--color-type-secondary);
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
      }
    }

    &--selected {
      font-weight: 700;
    }

    &--disabled {
      opacity: 0.5;
    }

    &--only-label {
      
      .dialog-select__option-item {
        &-icon-radio {
          display: none;
        }

        padding: 12px 0;
        border-bottom: none!important;
        font-weight: 500;
        font-size: 12px;
      }
    }

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-element-inactive);
    }
  }
}
