.fixed-two-buttons {
  box-sizing: border-box;
  background-color: var(--color-background);
  padding: 20px 24px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
  z-index: 3;
  display: flex;
  flex-direction: column;
  align-items: center;

  &.separator {
    border-top: 1px solid var(--color-element-inactive);
  }

  &.reverse {
    flex-direction: column-reverse;

    button {
      &:first-of-type {
        margin-bottom: 0;
      }

      &:last-of-type {
        margin-bottom: 16px;
      }

      &.one-button {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    } 
  }

  button {
    max-width: 600px;

    &:first-of-type {
      margin-bottom: 16px;
    }

    &.one-button {
      &:first-of-type {
        margin-bottom: 0;
      }
    }
  }
}