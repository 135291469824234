.configuration_detail {
  margin-bottom: 24px;

  p {
    margin: 0;
  }

  .value {
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-type-primary);
    margin-top: -6px;
  }

  .label-container {
    display: flex;
    align-items: center;
    height: 36px;

    .label {
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-type-secondary);
    }
  }
}