.dots-loader {
  .dot-typing {
    position: relative;
    left: -9999px;
    width: 5px;
    height: 5px;
    border-radius: 5px;
    background-color: var(--color-type-primary);
    color:  var(--color-type-primary);
    box-shadow: 9984px 0 0 0  var(--color-type-primary), 9999px 0 0 0  var(--color-type-primary), 10014px 0 0 0  var(--color-type-primary);
    animation: dot-typing 1.5s infinite linear;
  }

  @keyframes dot-typing {
    0% {
      box-shadow: 9984px 0 0 0  var(--color-type-primary), 9999px 0 0 0  var(--color-type-primary), 10014px 0 0 0  var(--color-type-primary);
    }
    16% {
      box-shadow: 9984px -10px 0 0  var(--color-type-primary), 9999px 0 0 0  var(--color-type-primary), 10014px 0 0 0  var(--color-type-primary);
    }
    33% {
      box-shadow: 9984px 0 0 0  var(--color-type-primary), 9999px 0 0 0  var(--color-type-primary), 10014px 0 0 0  var(--color-type-primary);
    }
    50% {
      box-shadow: 9984px 0 0 0  var(--color-type-primary), 9999px -10px 0 0  var(--color-type-primary), 10014px 0 0 0  var(--color-type-primary);
    }
    66% {
      box-shadow: 9984px 0 0 0  var(--color-type-primary), 9999px 0 0 0  var(--color-type-primary), 10014px 0 0 0  var(--color-type-primary);
    }
    83% {
      box-shadow: 9984px 0 0 0  var(--color-type-primary), 9999px 0 0 0  var(--color-type-primary), 10014px -10px 0 0  var(--color-type-primary);
    }
    100% {
      box-shadow: 9984px 0 0 0  var(--color-type-primary), 9999px 0 0 0  var(--color-type-primary), 10014px 0 0 0  var(--color-type-primary);
    }
  }
}