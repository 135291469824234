.icon-checkbox {
  [fill]:not([fill='none']) {
    fill: var(--color-type-primary);
  }

  [stroke] {
    stroke: var(--color-type-primary);
  }

  &--disabled {
    [fill]:not([fill='none']) {
      fill: var(--color-element-inactive);
    }
  }
}
