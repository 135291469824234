.toast-content {
  display: flex;
  align-items: center;
}

.toast-icon {
  margin-right: 16px;
  display: flex;
  align-items: center;
}

.toast-message {
  flex: 1;
}

.toast_success,
.toast_info,
.toast_error {
  padding: 32px !important;
  background-color: var(--color-element) !important;
  border: 1.5px solid var(--color-background);
  border-radius: 16px !important;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);

  &.icon-hide {
    .icon-toast-success,
    .icon-toast-warning,
    .icon-toast-error {
      display: none;
    }
  }

  #notistack-snackbar {
    align-items: flex-start;
    padding: 0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: var(--color-type-primary);
    max-width: 100%;

    .icon-toast-warning {
      [fill] {
        fill: var(--color-blue);
      }
    }
  }

  .close_toast {
    padding: 0 6px;
    font-size: 14px;
    color: var(--color-accent);
  }

  &:not(.toast-action-visible) {
    .SnackbarItem-action {
      display: none;

      .close_toast {
        color: #fff;
        display: none;

        .icon-close {
          svg {
            path {
              stroke: #fff;
            }
          }
        }
      }
    }
  }

  .icon-toast-success,
  .icon-toast-warning,
  .icon-toast-error {
    svg {
      margin-right: 16px;
    }
  }

  .replace_content {
    background-color: var(--color-element);
  }

  &.toast-small {
    padding: 20px !important;

    #notistack-snackbar {
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
    }
  }
}
