.icon-toast-warning {
  line-height: 0;

  &.color-error {
    svg {
      [fill] {
        fill: var(--color-red);
      }
    }
  }
}