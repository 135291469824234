.action-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
  align-items: center;
  position: relative;
  border-radius: 16px;
  background-color: var(--color-element);
  padding: 20px 16px;
  box-sizing: border-box;
  height: 80px;

  &__draggable-handle {
    cursor: grab;
    touch-action: none;
    width: 32px;
    height: 32px;

    svg {
      width: 32px;
      height: 32px;
    }
  }
}
