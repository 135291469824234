.cover-detail-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  justify-content: center;
  box-sizing: border-box;

  &__slider {
    display: flex;
    align-items: center;
    position: absolute;
  }

  &__tilt {
    position: absolute;
    right: 0;
    top: 46%;
    transform: translate3d(0, -50%, 0);
  }

  &__controls {
    display: flex;
    width: 100%;
    align-items: center;
    height: 64px;
    min-height: 64px;
    padding: 0;
    box-sizing: border-box;
    background-color: transparent;
    cursor: initial;
    margin-top: 24px;

    &-up,
    &-stop,
    &-down {
      border-radius: 8px;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;

      &:not(:active) {
        transition: background-color var(--button-active-transition), color var(--button-active-transition);
        background-color: var(--color-element);
        color: var(--color-type-primary);

        [stroke] {
          transition: stroke var(--button-active-transition);
          stroke: var(--color-type-primary);
        }
      }

      &:active {
        background-color: var(--color-accent);
        color: var(--color-element);

        [stroke] {
          stroke: var(--color-element);
        }
      }
    }

    &-up,
    &-down {
      width: 20%;
      flex-shrink: 0;

      &--disabled {
        cursor: initial;
        pointer-events: none;
      }
    }

    &-stop {
      width: 100%;
      margin: 0 8px;
    }

    &--active {

      &:not(:active) {
        transition: background-color var(--button-active-transition), color var(--button-active-transition);
        background-color: var(--color-accent);
        color: var(--color-element);

        [stroke] {
          transition: stroke var(--button-active-transition);
          stroke: var(--color-element);
        }
      }
    }
  }

  &--full-space {
    flex: 1;
  }

  .control-wrapper {
    position: relative;

    .popover-button {
      position: absolute;
      top: 24px;
      right: 24px;
    }
  }
}
