.share-channelsEdit {

  .loader {
    height: auto;
  }
  
  &-item {
    padding: 24px 16px;
    border-radius: 12px;
    background-color: var(--color-element);;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;

    .left-section {
      display: flex;
      align-items: center;
    }

    .icon {
      border-radius: 12px;
      background: var(--color-element);
      line-height: 0;
      margin-right: 16px;

      [class^='icon'] {
        width: 32px;
        height: 32px;

        [stroke] {
          stroke: var(--color-accent);
        }

        [fill] {
          fill: var(--color-accent);
        }
      }
    }

    p {
      width: 100%;
      margin: 0;

      &.access {
        color: var(--color-type-secondary);
        font-weight: 400;
        line-height: 16px;
      }
    }
  }
}