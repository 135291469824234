.popup-nav {
  color: var(--color-type-primary);
  display: flex;
  align-items: center;
  position: relative;
  height: 32px;
  width: 32px;
  cursor: pointer;

  &__menu-root {
    background: linear-gradient(180deg, rgba(21, 20, 29, 0.8) 0%, rgba(21, 20, 29, 0) 100%);

    .popup-nav__menu-paper { 
      border-radius: 8px;
    }
  }

  &__menu { 
    padding: 8px 0;
    background-color: var(--color-element);
    box-sizing: border-box;
    max-width: 290px;

    &-item {
      white-space: nowrap;
      padding: 12px 16px;
      display: flex;
      align-items: center;

      &-label {
        width: 100%;
        font-size: 14px;
        color: var(--color-type-secondary);
        text-align: left;
        padding: 0;
        display: flex;
        align-items: center;

        .icon {
          margin-right: 6px;
          display: flex;
          align-items: center;
        }
      }
    }
  }

  &__divider {
    margin: 8px 0;
    position: relative;
    padding: 0 16px;
  }

  &--reverse {
    path {
      fill: var(--color-background);
    }
  }

  &.dark {
    .icon-more {
      svg {
        path {
          fill: var(--color-background-secondary);
        }
      }
    }
  }
}