.controller-qr-code {
  .buttons-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 24px;

    button {
      font-size: 16px;
      font-weight: bold;
      color: var(--color-accent);
    }
  }
}