.icon-microphone {
  &.active {
    [stroke] {
      stroke: var(--color-red);
    }

    [fill] {
      fill: var(--color-red);
    }
  }
}