.arrow_button {
  .arrow_label {
    opacity: 0.7;
    font-size: 12px;
    margin-bottom: 8px;
    display: inline-block;
    font-weight: 500;
  }

  .arrow_btn {
    height: 64px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    border-radius: 8px;
    padding: 16px;
    background: var(--color-element);
    color: var(--color-type-primary);
    border: 1px solid transparent;
    outline: 0;
    width: 100%;
    box-sizing: border-box;

    &.dark {
      background: var(--color-background);
    }

    .left_section {
      display: flex;
      align-items: center;
      overflow: hidden;
      width: 100%;

      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin: 0;
        font-size: 16px;
        text-align: start;
        line-height: 16px;
        font-weight: 600;
        min-height: 18px;

        &.sub_title {
          color: var(--color-type-secondary);
          font-size: 12px;
          font-weight: 500;
          min-height: 0;
        }

        &.status {
          color: var(--color-type-secondary);
          font-size: 12px;
          font-weight: 500;
          min-height: 0;

          &:not(.pending) {
            span {
              color: var(--color-red);
            }
          }
        }
      }

      .placeholder {
        color: var(--color-element-inactive);
      }

      svg {
        margin-right: 12px;
      }
    }

    &.small {
      height: 53px;

      .left_section {
        p {
          font-size: 14px;
        }
      }
    }

    &.not-clickable {
      cursor: default;
    }
  }

  &.disabled {
    opacity: 0.7;

    button {
      pointer-events: none;
    }
  }

  &.big-radius {
    .arrow_btn {
      border-radius: 16px;
    }
  }

  &--icon {
    width: 24px;
    // height: 24px;
    margin-right: 16px;

    svg {
      width: 100%;
      height: 100%;
    }

    &.accent {
      svg {
        width: 100%;
        height: 100%;

        [stroke] {
          stroke: var(--color-accent);
        }

        [fill] {
          fill: var(--color-accent);
        }
      }
    }
  }

  &.delete {
    .arrow_btn {
      color: var(--color-red);
    }

    .icon-delete {
      [stroke] {
        stroke: var(--color-red);
      }
    }
  }
}