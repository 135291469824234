.action-control {
  cursor: pointer;

  &__cover {
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__advanced {
    padding: 0;
    display: grid;

    &:not(:active) {
      path {
        transition: fill var(--button-active-transition);
        fill: var(--color-element);
      }

      circle {
        transition: fill var(--button-active-transition), stroke var(--button-active-transition);
        stroke: var(--color-type-primary);
        fill: var(--color-element);
      }
    }

    &:active {
      path {
        fill: var(--color-accent);
        stroke: var(--color-element);
      }

      circle {
        stroke: var(--color-accent);
        fill: var(--color-accent);
      }
    }

    &--disabled {
      pointer-events: none;

      &:not(:active) {
        [stroke] {
          stroke: var(--color-background-secondary);
        }
      }
    }
  }
}
