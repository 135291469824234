.on-off-control {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  align-items: center;

  &__icon {
    padding: 0;

    &--on {
      justify-content: flex-start;
      padding-left: 16px;
      width: 50%;
      display: flex;
    }

    &--off {
      justify-content: flex-end;
      padding-right: 16px;
      width: 50%;
      display: flex;
    }

    &:not(:active) {
      path {
        transition: fill var(--button-active-transition);
        fill: var(--color-type-primary);
      }

      circle {
        transition: fill var(--button-active-transition), stroke var(--button-active-transition);
        stroke: var(--color-type-primary);
        fill: var(--color-element);
      }
    }

    &:active {
      path {
        fill: var(--color-element);
      }

      circle {
        stroke: var(--color-accent);
        fill: var(--color-accent);
      }
    }
  }

  &--detail {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;

    .on-off-control {
      &__bg-element {
        background-color: var(--color-element-inactive);
        opacity: 0.4;
        border-radius: 32px;
        padding: 14px;
        margin: 28px 0;
        width: 280px;
        height: 140px;
        box-sizing: border-box;

        &.active {
          border: 2px solid var(--color-accent);
          animation: borderAnimation 2s linear infinite;

          @keyframes borderAnimation {
            0% {
              border-color: var(--color-accent);
            }
          
            50% {
              border-color: var(--color-element-inactive);
            }
        
            100% {
              border-color: var(--color-accent);
            }
          }
        }

        &.turn-on {
          box-shadow: var(--turn-on-shadow);
        }
      }

      &__btn-wrapper {
        box-shadow: 6.0625px 6.0625px 18.1875px rgba(0, 0, 0, 0.15);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 16px;
        background-color: var(--color-element);
        overflow: hidden;
        position: absolute;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }

      &__divider {
        width: 1px;
        height: 77px;
        background-color: var(--color-background);
      }

      &__btn {
        padding: 18px 40px;
        box-sizing: border-box;
        height: 112px;
        width: 123px;
        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        background-color: var(--color-element);
        z-index: 1;

        &--active {
          background-color: var(--color-accent);
          color: var(--color-element);
        }

        &--full-width {
          width: calc(280px - 2 * 14px); // width of background - padding right and padding left
        }

        &--on {
          border-bottom-left-radius: 16px;
          border-top-left-radius: 16px;
        }

        &--off {
          border-bottom-right-radius: 16px;
          border-top-right-radius: 16px;
        }

        &:not(:active) {
          transition: background-color var(--button-active-transition), color var(--button-active-transition);
        }

        &:active {
          color: var(--color-element);
          background-color: var(--color-accent);
          z-index: 2;

          svg {
            [stroke] {
              stroke: #fff;
            }
          }

          &.on-off-control__btn--on {
            box-shadow: 6.0625px 6.0625px 18.1875px rgba(0, 0, 0, 0.15);
          }

          &.on-off-control__btn--off {
            box-shadow: -6.0625px 6.0625px 18.1875px rgba(0, 0, 0, 0.15);
          }
        }
      }
    }

    &.on-off-control--blind {
      height: auto;

      .on-off-control {
        &__bg-element {
          height: 217px;
          margin: 0;
        }

        &__btn-wrapper {
          top: 16px;
          transform: translate3d(0, 0%, 0);

          &+.on-off-control__btn-wrapper {
            top: 142px;
            height: 60px;
            width: 247px;
            transform: translate3d(0, 0%, 0);
          }
        }

        &__btn {
          &--stop {
            padding: 0;
            height: 100%;
            width: 100%;
            font-size: 18px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
