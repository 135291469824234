@import '../../../styles/mixins.scss';

.list-measurement {
  margin-top: 8px;

  &--color-background {
    background-color: var(--color-background);
  }

  &__tab-container {
    padding-top: 16px;
    height: 64px;
    box-sizing: border-box;
  }

  &__wrapper {
    padding-top: 16px;
  }

  .filters {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 56px;

    @include sm() {
      flex-direction: column;
      height: auto;
    }

    .input {
      margin-bottom: 0;

      @include sm() {
        margin-top: 16px;
      }
    }

    .row-container {
      display: flex;
      align-items: center;
      width: 100%;
  
      .switch {
        width: auto;
  
        &+ {
          p {
            margin: 0 0 0 12px;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.measurements-details {
  .icon-error {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0) scale(2);
  }
}