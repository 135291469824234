.power-state {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  background: var(--color-element);
  border-radius: 16px;
  height: 72px;

  &--left {
    display: flex;
    align-items: center;
  }

  &.error {
    border: 2px solid var(--color-red);
  }

  svg {
    margin-right: 16px;
  }

  .battery-icon {
    svg {
      rect {
        fill: var(--color-accent);
      }
    }

    [stroke] {
      stroke: var(--color-accent);
    }
  }
  

  p {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
  }
}