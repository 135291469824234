.icon-gate-control {
  position: absolute;
}

body.dark {
  .icon-gate-control {
    &[class^='icon'] {
      rect {
        fill: var(--color-element);
      }

      .sub-icon {
        stroke: #fff;
      }
    }
  }
}