.form-create-edit {
  .change-avatar {
    margin-bottom: 24px;

    .caption {
      opacity: 0.7;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      margin: 0 0 0 16px;
      color: var(--color-accent);
    }

    label {
      display: flex;
      align-items: center;
    }

    .image-container {
      background: var(--color-element);
      border-radius: 12px;
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
  
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}