.installation-icon {
  background-color: var(--icon-color-accent, var(--color-accent));
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--icon-size);
  height: var(--icon-size);
  margin: 3px;
  position: relative;

  &::before {
    position: absolute;
    width: var(--icon-border-size);
    height: var(--icon-border-size);
    content: "";
    background: var(--color-background);
    top: -2.5px;
    left: -2.5px;
    border-radius: 50%;
    z-index: -1;
    border: 1.5px solid var(--color-element-inactive-dark);
  }

  [class^='icon'] {
    width: 24px;
    height: 24px;

    [stroke] {
      stroke: var(--color-element);
    }
  }
}
