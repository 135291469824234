.share-summary {
  height: 100%;

  .page {
    &__container {
      padding: 0;
    }

    &__header {
      padding: 24px 24px 0 24px;
    }

    &__content {
      padding: 24px;
      background-color: var(--color-element);;
      

      .summary-item {
        margin-bottom: 32px;

        .text {
          color: var(--color-type-primary);
          font-size: 16px;
          margin: 0;
          font-weight: 700;

          &.value {
            color: var(--color-type-secondary);
            font-weight: 500;

            h3 {
              font-size: 16px;
              font-weight: 500;
            }

            .list {
              p {
                color: var(--color-type-secondary);
                font-weight: 500;
                margin: 0;

                .lowercase {
                  text-transform: lowercase;
                }
              }

              .admin_role {
                display: flex;
                margin-top: 16px;

                .icon {
                  border-radius: 50%;
                  height: 16px;
                  min-width: 16px;
                  font-size: 12px;
                  border: 1px solid #FF4016;
                  font-weight: 600;
                  color: #FF4016;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  margin-right: 8px;
                }

                p {
                  font-size: 12px;
                  white-space: pre-line;
                }
              }
            }

            .accordion {
              padding: 0;

              &__content {
                padding-top: 0;
              }
            }
          }
        }
      }
    }
  }
}