.exalus-action-sources {
  .arrow_btn {

    .arrow_button--icon {
      height: 24px;
    }

    svg {
      [stroke] {
        stroke: var(--color-accent);
      }

      [fill] {
        fill: var(--color-accent);
      }

      &.icon-actions {
        stroke: var(--color-accent);
      }
    }
  }
}