.driver {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  min-height: 65px;
  background-color: var(--color-element);
  border-radius: 16px;
  padding: 16px;
  box-sizing: border-box;
  cursor: pointer;

  &__link {
    align-items: center;
    display: flex;
    margin: 0;
    width: 100%;
  }

  &__header {
    margin: 0;
    width: 100%;
    overflow: hidden;

    &-icon {
      line-height: 0;
      width: 32px;
      height: 32px;
      margin-right: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;

      svg {
        [fill]:not([fill='none']) {
          fill: var(--color-accent);
        }

        [stroke]:not([stroke='none']) {
          stroke: #fff;
        }

        circle[fill]:not([fill='none']) {
          fill: #fff;
        }
      }

      .icon-switch,
      .icon-cover,
      .icon-gate-close {
        height: 24px;
        width: 24px;

        [fill]:not([fill='none']) {
          fill: var(--color-accent);
        }

        [stroke] {
          stroke: var(--color-accent);
        }
      }
    }

    &-title {
      line-height: 1.5;

      &-name {
        font-size: 14px;
      }

      &-type {
        font-size: 12px;
        line-height: 16px;
        font-weight: normal;
        color: var(--color-type-secondary);
      }
    }
  }

  &__update {
    position: relative;

    &-version {
      font-size: 9px;
      line-height: 12px;
      margin: 0 0 4px;
      display: flex;
      align-items: center;
      position: absolute;
      left: 50%;
      bottom: 0;
      transform: translate3d(-50%, 0, 0);
      color: var(--color-type-secondary);

      svg {
        width: 10px !important;
        height: 10px !important;
        margin: 0 4px;
      }
    }
  }

  button {
    &.update {
      width: auto;
      padding: 8px 16px;

      > span {
        margin: 0;

        &.update {
          transform: translate3d(0, -5px, 0);
        }
      }
    }
  }
}
