.page {
  display: flex;
  min-height: 100%;
  flex-direction: column;

  &__container {
    padding: 24px;
    display: flex;
    scrollbar-width: none;
    flex-direction: column;
    flex: 1;

    .header {
      margin-top: 0;
    }

    &--flex-height {
      flex: 1 0 100%;

      .page__content {
        display: flex;
        flex-direction: column;
        flex: 1 0 100%;
      }

      &.header-accent {
        padding-top: 36px;
      }
    }

    &--no-padding {
      padding: 0;
    }

    &--no-padding-top {
      padding-top: 0;
    }

    &--carousel {
      padding: 0 24px;

      .carouselCustom-item {
        margin-top: 24px;
      }
    }

    &--white {
      padding-bottom: 0;

      .page__content {
        background: var(--color-element);
        margin: 0 -24px 0 -24px;
        padding: 24px;
        flex: 1;
      }
    }
  }

  &__content {
    &:not(.page__content--unset-height) {
      height: 100%;
    }

    &--center {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    &--flex {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &--background-element {
      background: var(--color-element);
    }

    &--scrollable {
      overflow-y: auto;
    }
  }

  &__header {
    &--sticky {
      padding: 24px 24px 0;
      position: sticky;
      top: 0;
      z-index: 3;
      background-color: var(--color-background);
      background-repeat: no-repeat;
      background-position: right top;

      &-active {
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;

        .tabs {
          border-bottom: none;
        }
      }

      .header-accent {
        background-color: var(--color-background);
        position: fixed;
        height: 144px;
        width: 100vw;
        top: 0;
        left: 0;
        z-index: -1;
      }
    }
  }

  &.dashboard {
    .page__header {
      &--sticky {
        background-color: var(--color-background-secondary);
      }
    }
  }

  &--fixed {
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    background-color: var(--color-background);
    z-index: 4;
  }

  @function getContainerHeight($quantity) {
    @return calc(20px * 2 + (54px * $quantity) + 24px); // padding of <SubmitButton /> container + button height + extra space
  }

  &__fixed-button-spacing {
    height: getContainerHeight(1);
  }

  &__fixed-two-buttons-spacing {
    height: getContainerHeight(2);
  }

  &.above {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background-color: var(--color-background);
    z-index: 4;
    height: 100vh;

    .page__container {
      overflow-y: auto;

      .page__content {
        &:not(.page__content--unset-height) {
          height: auto;
          white-space: pre-wrap;
        }
      }
    }
  }
}
