.switch {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;

  input[type='checkbox'] {
    cursor: pointer;
    opacity: 0;
    width: 100%;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: 0;
    height: 100%;
    z-index: 2;

    &:disabled {
      + {
        .switch__icon {
          opacity: 0.6;
        }
      }
    }
  }

  .switch__icon {
    [stroke] {
      stroke: var(--color-type-primary);
      fill: var(--color-element);
    }

    circle {
      fill: var(--color-type-primary);
      transform: translate3d(18px, 0, 0);
      transition: transform 0.25s ease 0s;
    }
  }

  input[type='checkbox']:not(:checked) {

    & ~ .switch__icon {
      [stroke] {
        stroke: var(--color-element-inactive);
      }

      circle {
        transform: translate3d(0, 0, 0);
        fill: var(--color-element-inactive);
      }
    }
  }
}
