.update-lavva-device {

  .backdrop-popup--content {
    .update-version {
      font-size: 14px;
      color: var(--color-type-primary);
      font-weight: 700;
    }

    .error-code {
      font-size: 14px;
      color: var(--color-red);
      font-weight: 700;
    }
  
    .close-btn {
      border: none;
      width: auto;
      margin-top: 12px;
    }
  }
}