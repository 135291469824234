.optimizer-preview {
  background-color: var(--color-element);
  padding: 16px 24px 12px 24px;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  box-shadow: 1px 11px 18px -18px rgba(66, 68, 90, 1);
  margin: 0 -24px;
  width: calc(100% + 2 * 24px);
  box-sizing: border-box;

  .phases {
    display: flex;
    align-items: flex-start;
    justify-content: space-around;

    .phase-preview {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      line-height: 1.2;
      border: 1px solid var(--color-background);
      border-radius: 8px;
      margin-right: 12px;
      padding: 6px;
      width: 100%;

      &:last-of-type {
        margin-right: 0;
      }

      div {
        white-space: nowrap;
        font-weight: 500;
        font-size: 12px;
        color: var(--color-type-secondary);

        &.phase-title {
          font-weight: 700;
          font-size: 14px;
          color: var(--color-type-primary);
          margin-bottom: 4px;
        }

        &.load {
          display: flex;
          align-items: center;
          font-size: 10px;
          font-weight: 500;
          color: var(--color-type-primary);

          .eco-bar {
            margin-right: 4px;

            &__element {
              height: 8px;
              width: 52px;
            }
          }
        }
      }
    }
  }
}