.copy-to-clipboard-btn {
  position: relative;

  .copy-msg {
    position: absolute;
    top: -16px;
    background-color: var(--color-element-inactive-dark);
    color: #fff;
    font-size: 10px;
    font-weight: 500;
    border-radius: 8px;
    padding: 4px;
  }
}