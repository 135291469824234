.cover-control-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  justify-content: center;

  &__control {
    display: flex;

    &--border {
      border: 1px solid var(--color-type-primary);
      border-radius: 50%;
      padding: 8px;
    }

    &:not(:active) {
      path {
        transition: stroke var(--button-active-transition);
        stroke: var(--color-type-primary);
      }

      circle {
        transition: fill var(--button-active-transition), stroke var(--button-active-transition);
        stroke: var(--color-type-primary);
        fill: var(--color-element);
      }
    }

    &:active,
    &--active {
      path {
        stroke: var(--color-element) !important;
      }

      circle {
        stroke: var(--color-accent) !important;
        fill: var(--color-accent) !important;
      }
    }

    &--left {
      justify-content: flex-start;
      margin-left: 16px;
      width: 33%;
      margin-right: 12px;
    }

    &--right {
      justify-content: flex-end;
      margin-right: 16px;
      width: 33%;
      margin-left: 12px;
    }
  }

  &__controls {
    display: flex;
    width: 100%;
    align-items: center;
    box-sizing: border-box;
    justify-content: center;

    &.column {
      flex-direction: column;
    }

    .button {
      padding: 8px;
      margin: 0 16px;
    }
  }

  &--control-view {
    .cover-control-box__control {
      &--left {
        width: 50%;
      }

      &--right {
        width: 50%;
      }
    }
  }

  &--list-view {
    .button {
      width: 80px;
    }
  }

  .icon_container {
    padding-right: 16px;
  }
}
