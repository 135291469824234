.loader {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 24px 0;
  box-sizing: border-box;

  svg {
    width: 72px;
    height: 72px;

    [stroke] {
      stroke: var(--color-default);
    }
  
    [fill] {
      fill: var(--color-default);
    }
  }

  &__label {
    margin-top: 40px;
  }
}
