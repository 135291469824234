.icon-hand {
  path {
    fill: var(--color-type-primary);
  }

  &--color-accent {
    path {
      fill: var(--color-accent);
    }
  }
}
