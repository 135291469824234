.drive-add {
  &.tutorial {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    flex: 1;
  
    .header {
      margin-bottom: 48px;
    }
  
    .tutorial--content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      .learn-more {
        text-decoration: underline;
        font-weight: 700;
        font-size: 14px;
      }
    }
  }

  .statement-container {
    display: flex;
    margin-top: 24px;

    p {
      margin: 0;
      line-height: 24px;
    }

    .question-mark {
      border: 1px solid var(--color-type-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      height: 20px;
      width: 20px;
      min-width: 20px;
      font-size: 12px;
      border-radius: 50%;
      margin-right: 12px;
      font-size: 16px;
    }

    .statement {
      font-weight: 400;
      font-size: 16px;
    }
  }
}