.icon-initial {
  background-color: var(--color-accent);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  &__text {
    font-size: 24px;
    font-weight: 400;
    color: var(--color-element);
  }
}
