.popover-custom {
  &--content {
    border-radius: 12px;
    padding: 6px;
    font-size: 12px;

    &.warning {
      border: 1px solid var(--color-warning-yellow);
    }

    &.error {
      border: 1px solid var(--color-red);
    }
  }
}

.popover-button {
  padding: 0;
}