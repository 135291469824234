@import '../../../styles/mixins.scss';

.configuration_details {
  p {
    margin: 0;
  }

  hr {
    margin: 24px 0;
  }

  .edit_section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 24px;
  }

  button.update {
    color: var(--color-element);

    svg {
      [stroke] {
        stroke: var(--color-element);
      }
    }

    &[disabled] {
      color: #FFFFFF;

      svg {
        [stroke] {
          stroke: #FFFFFF;
        }
      }
    }
  }

  .buttons_container {
    margin-top: 24px;
  }

  .recovery-section {
    &__box {
      margin-top: 16px;
    }
  }

  .ssid-container {
    display: flex;
    align-items: center;

    .signal-strength {
      margin-left: 6px;
    }
  }
}