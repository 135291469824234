@import '../../../styles/mixins.scss';

.installation-item {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  position: relative;
  border-radius: 16px;
  background-color: var(--color-element);
  padding: 20px 16px;
  box-sizing: border-box;
  height: 80px;

  button {
    position: relative;
  }

  &__image {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }

  &__active {
    position: absolute;
    left: 32px;
    bottom: 0;
  }

  &__name {
    margin-left: 16px;
    flex-grow: 1;
    cursor: pointer;
    margin-right: 24px;
    line-height: 1.5;
  }

  &__draggable-handle {
    cursor: grab;
    width: 36px;
    height: 36px;
    touch-action: none;
  }
}
