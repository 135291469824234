.edit-icon {
  &__list {
    display: grid;
    grid-gap: 12px;
    gap: 12px;
    grid-template-columns: repeat(auto-fill, minmax(55px, 1fr));
  }

  &__icon-wrapper {
    background: var(--color-element);
    border-radius: 12px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;

    [class^='icon'] {
      [stroke] {
        stroke: var(--color-type-primary);
      }

      &:not(.icon-group) {

        [fill] {
          fill: var(--color-type-primary);
        }
      }

      &.icon-meter {
        [fill] {
          fill: var(--color-type-primary);
        }
      }
    }

    &:not(.edit-icon__icon-wrapper--unset-size) {
      [class^='icon'] {
        width: 38px;
        height: 38px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    &--accent {
      [class^='icon'] {
        [stroke] {
          stroke: var(--color-accent);
        }
      }
    }
  }

  &__icon-status {
    position: absolute;
    right: 0;
    bottom: -12px;

    [class^='icon'] {
      width: 18px;
      height: 18px;

      [fill] {
        fill: var(--color-accent);
      }
    }
  }

  &__caption {
    display: inline-block;
    color: var(--color-type-primary);
    opacity: 0.7;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 8px;
  }
}
