.installation-color-btn {
  margin-bottom: 24px;

  &__btn {
    background-color: var(--color-element);
    border-radius: 8px;
    width: 100%;
    padding: 16px;
    height: 56px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    color: var(--color-type-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__icon {
    height: 16px;
    width: 16px;
    border-radius: 50%;
  }
}
