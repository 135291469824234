.forward-summary-installation {

  .subHeader {
    font-size: 16px;
    margin-bottom: 12px;
  }

  .forward-direction {
    display: flex;
    align-items: center;
    margin: 24px 0;

    .icon-chevron {
      width: 14%;
    }

    .user {
      display: flex;
      align-items: center;
      width: 43%;

      img {
        border-radius: 50%;
        margin-right: 16px;
      }

      .icon-initial {
        height: 40px;
        width: 40px;
        margin-right: 16px;
      }

      .name {
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;

        &.user-type {
          color: var(--color-accent);
        }
      }
    }
  }

  &-info {
    color: var(--color-type-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin: 0;

    &:first-of-type {
      margin-bottom: 24px;
    }

    span {
      font-weight: 700;
      text-decoration: underline;
    }
  }
}