@import '../../../../../../styles/mixins.scss';

body {
  .eco-diagram-container {
    max-height: 500px;
    min-height: 270px;
    position: relative;

    .icon-eco {
      position: absolute;
      right: 138px;
      top: 6px;

      @include rwd(365) {
        right: 100px;
        top: 23px;
      }

      [stroke] {
        stroke: var(--color-green);
      }
    }
  
    .icon-plug {
      position: absolute;
      top: 15px;
      left: 153px;
      height: 25px;
      width: 25px;

      @include rwd(365) {
        top: 35px;
        left: 113px;
      }
    }

    .eco-diagram {
      @include rwd(365) {
        width: 260px;
      }

      .route-dot {
        &.green {
          fill: var(--color-green);
        }
  
        &.red {
          fill: var(--color-red);
        }
      }

      .route-line {
        &.green {
          stroke: var(--color-green);
        }
  
        &.red {
          stroke: var(--color-red);
  
        }
      }

      .border-panel, .border-network {
        stroke: #CBCBD1;
      }
    
      @keyframes greenLine {
        0% {
          transform: translate3d(325px, 0, 0);
        }
        100% {
          transform: translate3d(5px, 0, 0);
        }
      }
    
      &.green {
        .border-panel {
          stroke: var(--color-green);
        }
    
        .border-network:not(.loadActive) {
          stroke: var(--color-green);
        }
    
        .border-home {
          stroke: var(--color-green);
        }

        .border-relay {
          stroke: var(--color-green);
        }
    
        &.load-active {
          .border-load {
            stroke: var(--color-green);
          }
        }
      }
    
      &.red {
        .border-network {
          stroke: var(--color-red);
        }
        
        .border-relay {
          stroke: var(--color-red);
        }

        &.load-active {
          .border-home {
            stroke: var(--color-red);
          }
        }
      }
    
    
      @keyframes l1HomeGreen {
        0% { transform: translate3d(325px, 112px, 0); opacity: 1; }
        33% { transform: translate3d(219px, 112px, 0); opacity: 1; }
        99% { transform: translate3d(219px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(219px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l2HomeGreen {
        0% { transform: translate3d(325px, 144px, 0); opacity: 1; }
        37% { transform: translate3d(207px, 144px, 0); opacity: 1; }
        99% { transform: translate3d(207px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(207px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l3HomeGreen {
        0% { transform: translate3d(325px, 176px, 0); opacity: 1; }
        41% { transform: translate3d(195px, 176px, 0); opacity: 1; }
        99% { transform: translate3d(195px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(195px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l1RelayGreen {
        0% { transform: translate3d(325px, 112px, 0); opacity: 1; }
        46% { transform: translate3d(177px, 112px, 0); opacity: 1; }
        99% { transform: translate3d(177px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(177px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l2RelayGreen {
        0% { transform: translate3d(325px, 144px, 0); opacity: 1; }
        50% { transform: translate3d(165px, 144px, 0); opacity: 1; }
        99% { transform: translate3d(165px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(165px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l3RelayGreen {
        0% { transform: translate3d(325px, 176px, 0); opacity: 1; }
        54% { transform: translate3d(153px, 176px, 0); opacity: 1; }
        99% { transform: translate3d(153px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(153px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l1LoadGreen {
        0% { transform: translate3d(325px, 112px, 0); opacity: 1; }
        59% { transform: translate3d(135px, 112px, 0); opacity: 1; }
        99% { transform: translate3d(135px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(135px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l2LoadGreen {
        0% { transform: translate3d(325px, 144px, 0); opacity: 1; }
        63% { transform: translate3d(123px, 144px, 0); opacity: 1; }
        99% { transform: translate3d(123px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(123px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l3LoadGreen {
        0% { transform: translate3d(325px, 176px, 0); opacity: 1; }
        67% { transform: translate3d(111px, 176px, 0); opacity: 1; }
        99% { transform: translate3d(111px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(111px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l1HomeRed {
        0% { transform: translate3d(5px, 112px, 0); opacity: 1; }
        67% { transform: translate3d(219px, 112px, 0); opacity: 1; }
        99% { transform: translate3d(219px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(219px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l2HomeRed {
        0% { transform: translate3d(5px, 144px, 0); opacity: 1; }
        63% { transform: translate3d(207px, 144px, 0); opacity: 1; }
        99% { transform: translate3d(207px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(207px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l3HomeRed {
        0% { transform: translate3d(5px, 176px, 0); opacity: 1; }
        59% { transform: translate3d(195px, 176px, 0); opacity: 1; }
        99% { transform: translate3d(195px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(195px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l1RelayRed {
        0% { transform: translate3d(5px, 112px, 0); opacity: 1; }
        54% { transform: translate3d(177px, 112px, 0); opacity: 1; }
        99% { transform: translate3d(177px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(177px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l2RelayRed {
        0% { transform: translate3d(5px, 144px, 0); opacity: 1; }
        50% { transform: translate3d(165px, 144px, 0); opacity: 1; }
        99% { transform: translate3d(165px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(165px, 30px, 0); opacity: 0; }
      }
    
      @keyframes l3RelayRed {
        0% { transform: translate3d(5px, 176px, 0); opacity: 1; }
        46% { transform: translate3d(153px, 176px, 0); opacity: 1; }
        99% { transform: translate3d(153px, 40px, 0); opacity: 1; }
        100% { transform: translate3d(153px, 30px, 0); opacity: 0; }
      }
    
      .l1, .l2, .l3 {
        &.green {
          fill: var(--color-green);
    
          &.line {
            animation: greenLine 6s linear infinite;
          }
        }
    
        &.red {
          fill: var(--color-red);
        }
      }
    
      .l1.green {
        &.home {
          opacity: 0;
          animation: 6s l1HomeGreen linear infinite;
        }
    
        &.load {
          opacity: 0;
          animation: 6s l1LoadGreen linear infinite;
        }
    
        &.relay {
          opacity: 0;
          animation: 6s l1RelayGreen linear infinite;
        }
      }
    
      .l2.green {
        &.home {
          opacity: 0;
          animation: 6s l2HomeGreen linear infinite;
        }
    
        &.load {
          opacity: 0;
          animation: 6s l2LoadGreen linear infinite;
        }
    
        &.relay {
          opacity: 0;
          animation: 6s l2RelayGreen linear infinite;
        }
      }
    
      .l3.green {
        &.home {
          opacity: 0;
          animation: 6s l3HomeGreen linear infinite;
        }
    
        &.load {
          opacity: 0;
          animation: 6s l3LoadGreen linear infinite;
        }
    
        &.relay {
          opacity: 0;
          animation: 6s l3RelayGreen linear infinite;
        }
      }
    
      .l1.red {
        &.home {
          opacity: 0;
          animation: 6s l1HomeRed linear infinite;
        }
    
        &.relay {
          opacity: 0;
          animation: 6s l1RelayRed linear infinite;
        }
      }
    
      .l2.red {
        &.home {
          opacity: 0;
          animation: 6s l2HomeRed linear infinite;
        }
    
        &.relay {
          opacity: 0;
          animation: 6s l2RelayRed linear infinite;
        }
      }
    
      .l3.red {
        &.home {
          opacity: 0;
          animation: 6s l3HomeRed linear infinite;
        }
    
        &.relay {
          opacity: 0;
          animation: 6s l3RelayRed linear infinite;
        }
      }
    }
  }

  &.dark {
    .eco-diagram-container {
      .eco-diagram {
        fill: var(--color-background);

        text {
          fill: var(--color-type-primary);
        }
    
        path {
          stroke: var(--color-type-primary);
        }

        .gray-box {
          fill: var(--color-background);
        }

        .border-load, .border-home {
          fill: var(--color-element);
        }

        .relay-rect {
          fill: var(--color-element);

          &-content {
            fill: var(--color-element);
          }
        }
      }

      .icon-plug {
        [stroke] {
          stroke: var(--color-type-primary);
        }
      }
    }
  }
}
