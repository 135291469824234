.radios-with-icons {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 600;
    margin-bottom: 24px;
    font-size: 16px;
    line-height: 24px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    &.disabled {
      color: var(--color-element-inactive-dark);
    }

    .icon-label {
      display: flex;
      align-items: center;
    }

    input {
      margin: 0;
      height: 20px;
      width: 20px;
      color: var(--color-accent);
      display: none;

      &+ *::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        border: 1.5px solid var(--color-type-primary);
        border-radius: 50%;
      }

      &:checked {
        &+ *::before {
          background: radial-gradient(var(--color-accent) 0%, var(--color-accent) 40%, transparent 50%, transparent);
          border-color: var(--color-accent);
        }
      }
    }
  }

  &--background {
    label {
      background: var(--color-element);
      border-radius: 8px;
      padding: 16px 12px;
      box-sizing: border-box;

      .icon-label {
        height: 32px;
      }
    }
  }
}