.text-info {
  display: flex;
  align-items: flex-start;

  &.column {
    flex-direction: column;

    p {
      &.label {
        margin-bottom: 8px;
      }
    }
  }

  &.big {
    p {
      &.label, &.value {
        font-size: 16px;
      }
    }
  }

  &.reverse {
    p {
      &.label {
        color: var(--color-type-primary);
        font-weight: 700;
      }

      &.value {
        color: var(--color-type-secondary);
        font-weight: 700;
      }
    }
  }

  p {
    width: 100%;
    margin: 0;
    text-align: start;

    &.label {
      padding-bottom: 0;
      margin-right: 6px;
      line-height: 18px;
      font-size: 14px;
      font-weight: 600;
      color: var(--color-type-secondary);

      &.input-label {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: var(--color-type-secondary);
        margin-bottom: 0;
      }
    }
    
    &.value {
      font-size: 14px;
      font-weight: 600;
      line-height: 18px;
      color: var(--color-type-primary);
      white-space: pre-line;
    }
  }

  &.no-width {
    p {
      width: unset;
    }
  }
}