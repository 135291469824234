.progress-with-label {
  margin-left: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  .percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 11px;
  }  
}