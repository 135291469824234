.signal-strength {
  display: flex;
  align-items: flex-end;
  margin-left: auto;

  &__bar {
    margin-left: 2px;
    border: 1px solid var(--color-accent);
    border-radius: 3.5px;
    width: 4px;

    &.background {
      background: var(--color-accent);
    }

    &--1 {
      height: 8px;
    }
    &--2 {
      height: 12px;
    }
    &--3 {
      height: 16px;
    }
    &--4 {
      height: 20px;
    }
    &--5 {
      height: 24px;
    }
  }
}