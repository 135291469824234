.driver {
  &__link {

    .signal-update-container {
      position: relative;
    }

    .exalus-version {
      font-size: 8px;
      line-height: 12px;
      margin: 0;
      display: flex;
      align-items: center;
      position: absolute;
      top: unset;
      left: unset;
      bottom: -14px;
      right: -6px;
      color: var(--color-type-secondary);

      svg {
        width: 8px !important;
        height: 8px !important;
        margin: 0 2px;
      }
    }
  }
}