.parameter-settings {
  .blind-direction-buttons {
    display: flex;
    align-items: center;
    margin-top: 24px;

    button {
      min-width: 60px;
      background-color: var(--color-element);
      border-radius: 8px;
      height: 60px;
      padding: 12px;

      &:nth-child(2) {
        width: 100%;
        margin: 0 6px;
      }
    }
  }

  .blind-percentage-buttons {
    display: flex;
    align-items: center;
    margin-top: 6px;

    .cover-button {
      color: var(--color-type-primary);
      width: 100%;

      &:nth-child(2) {
        margin: 0 6px;
      }
    }
  }

  .microventilation-button {
    height: 52px;
    width: 52px;
    background-color: var(--color-accent);
    border-radius: 50%;
    position: absolute;
    bottom: 24px;
    left: 24px;

    [stroke] {
      stroke: #fff;
    }
  }
}