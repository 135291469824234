.remote-control {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  align-items: center;

  &__icon {
    line-height: 0;
    border-radius: 50%;

    transition: background-color var(--button-active-transition), color var(--button-active-transition);

    &--active {
      background-color: var(--color-accent);
      color: var(--color-element);

      svg {
        [stroke] {
          stroke: #fff;
        }
      }
    }
  }
}