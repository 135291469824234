.list-details {
  width: 100%;

  .section {
    margin-bottom: 42px;
    padding-bottom: 24px;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-element-inactive);
    }

    &__date {
      font-size: 16px;
      font-weight: 700;
      text-align: center;
      text-transform: capitalize;
      margin-bottom: 24px;
    }

    .list-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 6px 12px;

      .icon-state {
        display: flex;
        align-items: center;

        div {
          font-weight: 500;
          font-size: 16px;
        }

        .icon {
          margin-right: 12px;
        }
      }

      .period {
        font-size: 16px;
        font-weight: 700;
      }
    }
  }
}