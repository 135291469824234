.installation-settings {

  &.hide {
    .page__container {
      padding: 0;

      .page__container {
        padding: 24px;
      }
    }
  }

  &__integration {

    h2 {
      margin-bottom: 16px;
    }

    &-form-close {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 24px;
      width: 100%;
      height: 40px;
      padding: 0;
      transform: translate3d(-6px, 0, 0);
    }

    &-page {
      position: fixed;
      top: 0;
      width: 100%;
      left: 0;
      right: 0;
      z-index: 2;
      background: var(--color-background);
    }

    &-element {
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: var(--color-element);
      color: var(--color-type-primary);
      border-radius: 8px;
      font-size: 14px;
      padding: 16px;
      box-sizing: border-box;
      width: 100%;
      height: 56px;
      margin: 24px 0;

      &-label {
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        display: flex;
        align-items: center;
        padding-bottom: 8px;
        color: var(--color-type-secondary);
      }
    }
  }
}

.installation_edit {
  .arrow_button {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}