.advanced-action-switch {
  display: grid;
  place-items: center;
  margin: 24px 0 48px;
  height: auto;
  min-height: 64px;
  background-color: var(--color-element);
  border-radius: 16px;
  width: 100%;
}
