.icon-close {
  line-height: 0;

  [stroke] {
    stroke: var(--color-type-primary);
  }

  &--no-space {
    transform: translate3d(-6px, 0, 0);
  }
}
