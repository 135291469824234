.lock-view {

  .reset-description {
    color: var(--color-type-secondary);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  .action-list {
    margin-top: 48px;

    .checkbox {
      align-items: center;
      background: var(--color-element);
      color: var(--color-type-primary);
      display: flex;
      font-size: 16px;
      font-weight: 600;
      border-radius: 12px;
      justify-content: space-between;
      margin-bottom: 24px;
      padding: 16px;
      width: auto;

      .action-checkItem {
        display: flex;
        align-items: center;

        .icon {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;
        }

        .title {
          color: var(--color-type-primary);
          margin-left: 16px;
        }
      }
    }
  }
}