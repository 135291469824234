.schedule-source {
  .checkbox {
    margin-bottom: 0;
  }

  .days-period {
    padding-left: 12px;
    margin-bottom: 24px;
  }

  .row-container {
    display: flex;
    align-items: center;

    &.flex-end {
      justify-content: flex-end;
    }

    &.margin-right {
      margin-right: 12px;
    }

    &--icon {
      margin-right: 12px;
      display: flex;
      align-items: center;

      svg {
        height: 25px;
        width: 25px;
      }

      [stroke] {
        stroke: var(--color-accent);
      }
    }

    p {
      font-size: 16px;

      &.secondary-text {
        color: var(--color-type-secondary);

        &.small {
          font-size: 12px;
        }
      }
    }
  }
}