.info-status-screen {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;

  &__page {
    height: 100%;
  }

  &--secondary-background {
    &__page,
    &__page .page__header {
      background-color: var(--color-element);
    }

    &__page {
      height: 100%;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    margin-top: 78px;
    max-width: 280px;
  }

  &__title {
    font-size: 24px;
    margin-bottom: 16px;
    margin-top: 24px;
    text-align: center;
  }

  &__subtitle {
    color: var(--color-type-secondary);
    font-size: 14px;
    line-height: 24px;
    font-weight: normal;
    text-align: center;
    padding: 0 16px;
  }

  &__buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      max-width: 600px;
      margin-bottom: 16px;
    }
  }

  &__empty-block {
    height: 56px;
  }

  &__note {
    color: var(--color-type-secondary);
    font-weight: 400;
    font-size: 14px;
    width: calc(100% - 48px);
    text-align: center;
    padding: 24px;
  }
}
