.energy-consumption-total-value {
  position: absolute;
  bottom: 13%;
  display: flex;
  font-size: 14px;
  line-height: 24px;
  align-items: center;

  &__text {
    margin-right: 8px;
    font-weight: normal;
  }

  &__value {
    font-weight: 600;
  }
}
