.live-tooltip {
  background: var(--color-element);
  padding: 10px;
  border-radius: 8px;
  border: 1.5px solid var(--color-background);
  text-align: center;
  box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);

  &__label {
    font-weight: 500;
    color: var(--color-type-secondary);
    font-size: 12px;
    line-height: 16px;
  }

  &__value {
    font-weight: 600;
    color: var(--color-type-primary);
    font-size: 12px;
    line-height: 16px;

    &--color-accent {
      color: var(--color-accent);
    }
  }
}