.checkbox {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin-bottom: 24px;
  max-width: 700px;

  &__label {
    width: calc(100% - 24px);
    text-align: left;

    // Sometime label content has some action onClick.  We wan't to
    // avoid triggering that action so whole checkbox label is used
    // only for changing checkbox state.
    & > * {
      pointer-events: none;
    }

    svg {
      width: 32px;
      height: 32px;

      [stroke] {
        stroke: var(--color-accent);
      }
  
      [fill] {
        fill: var(--color-accent);
      }
    }

    &.bold {
      font-weight: 700;
    }
  }

  &__icon {
    width: 24px;
    height: 24px;
  }

  &--full-width {
    margin-bottom: 16px;

    .checkbox__label {
      position: relative;
      width: 100%;

      + .checkbox__icon {
        position: absolute;
        right: 16px;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }
  }

  &--width-auto {
    width: auto;
  }

  &--color-accent {
    .checkbox__icon {
      .icon-checkbox {
        [fill]:not([fill='none']) {
          fill: var(--color-accent);
        }
      }
    }
  }

  &--unavailable {
    color: var(--color-element-inactive);

    .icon-box.box {
      svg {
        [stroke] {
          stroke: var(--color-element-inactive);
        }

        [fill] {
          fill: var(--color-element-inactive);
        }
      }
    }

    .checkbox__icon svg {
      [stroke] {
        stroke: var(--color-element-inactive);
      }
    }
  }

  &.reverse {
    label {
      flex-direction: row-reverse;

      .checkbox__icon {
        margin-right: 16px;
      }
    }
  }

  &.color-primary {
    .checkbox__label {
      color: var(--color-type-primary);
    }
  }
}

input[type='checkbox'] {
  position: absolute;
  opacity: 0;

  & + label {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 100%;
  }

  &:disabled {
    & + label {
      .checkbox__icon svg {
        [stroke] {
          stroke: var(--color-element-inactive);
        }
      }
    }
  }
}
