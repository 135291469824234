.device-settings-section {
  .arrow_btn {
    .left_section {
      .icon-device {
        [fill] {
          fill: var(--color-accent);
        }
      }

      .icon-dashboard, .icon-profile {
        [stroke] {
          stroke: var(--color-accent);
        }
      }
    }
  }
}