.brightness-parameters {
  .active {
    color: var(--color-accent);
  }

  .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis   {
    text {
      tspan {
        font-size: 10px;

        &.active {
          fill: var(--color-accent);
          font-weight: 700;
        }
      }
    }
  }
}