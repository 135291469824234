.astronomical-chart {
  .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis {
    line {
      stroke: #fff;
    }
  }
  
  .recharts-cartesian-grid {
    .recharts-cartesian-grid-horizontal {
      line {
        &:nth-child(2) {
          fill: #fff;
          stroke-width: 3px;
          stroke-dasharray: none;
        }
      }
    }
  }
  
  .recharts-layer.recharts-cartesian-axis.recharts-xAxis.xAxis {
    line {
      display: none;
    }
  }
}