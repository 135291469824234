.share-groups {

  .page__content {
    display: flex;
    justify-content: center;
  }

  .loader {
    height: auto;
  }
  
  .share-group-item {
    padding: 24px 16px;
    border-radius: 12px;
    background-color: var(--color-element);;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    p {
      width: 100%;
      margin: 0;
    }

    .checkbox {
      margin-bottom: 0;
    }
  }

  &-info {
    color: var(--color-type-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 48px;

    p {
      font-size: 14px;
      color: var(--color-type-secondary);
    }

    span {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  &-empty {
    color: var(--color-type-secondary);
    font-size: 14px;
    font-weight: 400;
    text-align: center;
  }
}