.optimizer-control {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: center;
  padding: 0 16px;

  &__value-wrapper {
    display: flex;
    align-items: center;
  }

  &__value {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }

  &__unit {
    margin-left: 4px;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;
  }

  .icon-arrow-link {
    margin-left: 6px;
  }

  .current-icon {
    display: flex;
    align-items: center;
    margin-right: 6px;

    svg {
      height: 22px;
      width: 22px;

      &.icon-eco {
        [stroke] {
          stroke: var(--color-green);
        }
      }
    }
  }
}