@import '../../styles/mixins.scss';

.navigation {
  width: 100vw;
  background-color: var(--color-background);
  color: var(--color-element-inactive-dark);
  border-top: 1px solid var(--color-element-inactive);
  padding: 10px 40px;
  box-sizing: border-box;
  left: 0;
  right: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 0;
  height: 72px;
  z-index: 4;

  &.with-chat {
    padding: 10px 30px;
  }

  &__list {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 420px;

    @include md() {
      width: 600px;
    }

    &-item {
      width: 32px;
      display: flex;
      justify-content: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .nav_title {
        font-size: 12px;
        margin: 4px 0 0 0;
        color: var(--color-element-inactive-dark);
      }

      [stroke] {
        stroke: var(--color-element-inactive-dark);
      }

      &--active {
        color: var(--color-accent);

        [stroke] {
          stroke: var(--color-accent);
        }

        .nav_title {
          color: var(--color-accent);
        }
      }
    }
  }

  +.page {
    padding-bottom: 72px;
    min-height: calc(100% - 72px);
  }
}
