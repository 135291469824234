.product-list-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
  min-width: 230px;
  max-width: 230px;
  border-radius: 10px;
  background-color: var(--color-element);
  box-sizing: border-box;
  padding: 24px;
  margin-right: 12px;
  height: 260px;
  cursor: pointer;

  .image {
    height: 110px;
    width: auto;
    max-width: 110px;
    margin-bottom: 16px;
  }

  p {
    margin: 0;
    line-height: 20px;

    &.name {
      font-weight: 600;
      font-size: 16px;
      text-align: center;
      margin-bottom: 12px;
    }

    &.model-name {
      font-weight: 400;
      font-size: 14px;
      text-align: center;
    }
  }

  &.more {
    min-width: 220px;
    max-width: 220px;
    height: 175px;
    max-height: 175px;
    padding: 12px;
    border: 1px solid var(--color-background);

    .image {
      height: 80px;
      max-width: 80px;
    }

    p {
      line-height: 18px;

      &.name {
        font-weight: 500;
        font-size: 12px;
        margin-bottom: 0;
      }

      &.model-name {
        font-size: 10px;
      }
    }
  }
}