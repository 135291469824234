.icon-arrow-link {
  width: 15px !important;
  height: 14px !important;
  [stroke] {
    stroke: var(--color-type-primary);
  }

  &--accent {
    [stroke] {
      stroke: var(--color-accent);
    }
  }

  &.up {
    transform: rotate(-90deg);

    [stroke] {
      stroke: var(--color-green);
    }
  }

  &.down {
    transform: rotate(90deg);

    [stroke] {
      stroke: var(--color-red);
    }
  }
}