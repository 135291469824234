.icon-schedule {
  [stroke] {
    stroke: var(--color-accent);
  }

  &--primary {
    [stroke] {
      stroke: var(--color-type-primary);
    }
  }
}
