.shared-user {
  &-content {
    .dates {
      display: flex;
      justify-content: space-between;
      margin-bottom: 12px;
    }

    .info {
      color: var(--color-type-primary);
      font-size: 14px;
      font-weight: 400;
      margin-top: 48px;
      line-height: 19px;
    }

    .user-content {
      padding: 24px 0;

      .arrow_button {
        margin-bottom: 24px;
      }
    }
  }

  @mixin shareInfoText {
    margin: 0;
    color: var(--color-type-primary);
    font-size: 16px;
    font-weight: 600;
    text-align: start;
  }

  &--value {
    @include shareInfoText;
  }

  &--label {
    @include shareInfoText;
    color: var(--color-type-secondary);
    font-weight: 500;
    font-size: 16px;
    margin-bottom: 6px;
  }
}