.icon-tip {
  position: relative;

  [fill] {
    fill: var(--color-accent);
  }

  .pulsating-circle {
    width: 15px;
    height: 15px;
    position: absolute;
    top: 6px;
    left: 15px;
    
    &:before {
      content: '';
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 45px;
      background-color: var(--color-accent);
      animation: pulse-ring 3.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
  }
  
  @keyframes pulse-ring {
    0% {
      transform: scale(.33);
    }
    80%, 100% {
      opacity: 0;
    }
  }
  
  @keyframes pulse-dot {
    0% {
      transform: scale(.8);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(.8);
    }
  }
}