.driver-settings {
  align-items: center;
  display: flex;
  margin: 0;
  height: auto;
  width: 100%;

  &__page {
    .edit_section {
      .header {
        &:last-of-type {
          margin-bottom: 0;
        }
      }
    }
  }

  &__icon {
    line-height: 0;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: var(--color-accent);
    border-radius: 10px;
    position: relative;
    
    svg {
      [fill]:not([fill='none']) {
        fill: var(--color-accent);
      }
    }
  }

  &__info {
    margin: 0;
    width: 100%;

    .driver-title {
      font-size: 18px;
      line-height: 24px;
    }

    .driver-type {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: var(--color-type-secondary);
    }
  }

  &__error-message {
    position: fixed;
    bottom: 24px;
    left: 24px;
    right: 24px;
    display: flex;
    border-radius: 8px;
    padding: 16px;
    background-color: var(--color-element);

    &-icon {
      margin-right: 16px;

      path {
        fill: var(--color-red) !important;
      }
    }

    &-heading {
      color: var(--color-red);
      margin: 0 0 16px;
    }

    &-text {
      line-height: 1.4;
      margin: 0;
    }
  }

  &__devices {

    .arrow_btn {
      .left_section {
        svg {
          height: 32px;
          width: 32px;
        
          [stroke] {
            stroke: var(--color-accent);
          }
          [fill] {
            fill: var(--color-accent);
          }
        }
      }
    }
  }

  &__device-button {
    margin-top: 64px;
  }
}
