@import '../../styles/mixins.scss';

.login-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 24px;
  box-sizing: border-box;

  &__up-content {
    height: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  &__logo {
    margin-bottom: 42px;

    &.lower {
      margin-bottom: 16px;
    }

    svg {
      width: 260px;
      height: auto;
    }

    .exalus {
      svg {
        transform: scale(1.5);
        width: auto;
      }
    }
  }

  &__second-slogan {
    font-weight: 700;
    font-size: 22px;
    line-height: 24px;
    text-align: center;
    width: 260px;
    opacity: 1;
    margin: 0;
  }

  &__buttons {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      height: 56px;
      font-size: 16px;
      max-width: 600px;
      font-weight: 600;

      &.facebook {
        background-color: #1877F2;
        border: 1px solid #1877F2;
        color: #fff;
        font-weight: 700;
        font-size: 16px;
      }

      &.google {
        background-color: #fff;
        border: 1px solid var(--color-type-primary);
        color: var(--color-type-primary);
        font-weight: 700;
        font-size: 16px;
      }

      &.apple {
        path {
          fill: #fff;
        }
      }
    }

    .or-separator {
      position: relative;
      width: 100%;
      max-width: 600px;

      &::before {
        content: '';
        width: 40%;
        border-bottom: solid 1px #55555c;
        position: absolute;
        top: 50%;
        right: 0;
        z-index: 1;
      }

      &::after {
        content: '';
        width: 40%;
        border-bottom: solid 1px #55555c;
        position: absolute;
        top: 50%;
        left: 0;
        z-index: 1;
      }
      
      div {
        background-color: var(--color-background);
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate3d(-50%, -50%, 0);
        z-index: 1;
        padding: 0 12px;
        color: var(--color-type-secondary);
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .support-link {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    button {
      display: flex;
      align-items: center;
      background-color: var(--color-element);
      border-radius: 12px;
      height: auto;
      color: var(--color-type-secondary);

      .icon-call-button-circle {
        height: 30px;
        width: 30px;

        path {
          stroke: var(--color-type-secondary);
        }
      }

      p {
        background-color: var(--color-element);
        padding: 0 12px;
        margin: 0;
        font-size: 14px;
      }
    }
  }

  &.wisniowski {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    background-image: url('../../../public/images/wisniowski-view.png');
    
    @include rwd(500) {
      background-image: url('../../../public/images/wisniowski-view-mobile.png');
    }

    .login-page__up-content {
      justify-content: flex-start;

      .lower {
        margin-top: 50px;
        margin-bottom: 0;

        svg {
          width: 400px;

          @include rwd(500) {
            width: 300px;
          }
        }

        [fill] {
          fill: #fff;
        }
      }
    }

    .login-page__buttons {
      .or-separator {
        &::before {
          border-bottom: solid 1px #fff;
        }
  
        &::after {
          border-bottom: solid 1px #fff;
        }

        div {
          background-color: transparent;
          color: #fff;
          z-index: 1;
        }
      }

      .button--secondary {
        color: #fff;
        border-color: #fff;
      }
    }    
  }
}

body {
  &.dark {
    .login-page__buttons button.google {
        background-color: #1877F2;
        border: 1px solid #1877F2;
        color: #fff;
    }

    .login-page__buttons button.apple {
      path {
        fill: #000;
      }
    }

    .support-link {
      button {
        .icon-call-button-circle {
          circle {
            stroke: var(--color-element);
          }
        }
      }
    }
  }
}