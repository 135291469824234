.found-driver {

  button {
    height: 36px;
    width: 36px;
  }

  .identify-btn {
    border: 1px solid black;
    border-radius: 50%;
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .register-btn {
    svg {
      height: 36px;
      width: 36px;
    }
  }
}