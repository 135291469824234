body {
  .location_select_page {
    .need_info {
      color: var(--color-type-primary);
      font-size: 14px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 48px;
  
      span {
        font-weight: 700;
        text-decoration: underline;
      }
    }
  
    .location_btn {
      display: flex;
      align-items: center;
      font-size: 16px;
      font-weight: 700;
      margin-bottom: 24px;
  
      &:last-of-type {
        margin-bottom: 0;
      }
  
      svg {
        margin-right: 14px;
      }
    }
  }
  
  &.dark {
    .location_btn {
      svg {
        fill: var(--color-type-primary);
      }
    }
  }
}