.icon-admin {
  [stroke] {
    stroke: var(--color-type-primary);
  }

  g {
    [fill] {
      fill: var(--color-type-primary);
    }
  }
}
