.trigger-icon {
  display: flex;
  margin-right: 20px;
  position: relative;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 42px;
    height: 42px;
    background: var(--color-element);
    line-height: 0;
    border: 0;
    border-radius: 10px;

    .icon-automatic {
      width: 22px;
      height: 18px;
    }
  }
}
