.action-conditions {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 24px;
  }

  &__heading {
    margin-bottom: 24px;
    line-height: 24px;
  }

  &__buttons {
    display: flex;
  }

  .action-condition {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    &--with-delete {
      justify-content: space-between;
    }

    &__wrapper {
      display: flex;
      align-items: center;
    }

    &__content {
      margin-left: 16px;
    }

    &__title {
      margin: 0;
      font-weight: 600;
      line-height: 24px;
    }

    &__text {
      margin: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      color: var(--color-type-secondary);
      white-space: pre-wrap;

      + div {
        font-size: 12px;
        color: var(--color-type-secondary);
      }
    }

    .icon-wrapper {
      svg {
        width: 32px;
        height: 32px;

        [stroke] {
          stroke: var(--color-accent);
        }

        [fill] {
          fill: var(--color-accent);
        }
      }
    }
  }
}