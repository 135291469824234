.gate-history-item {
  display: flex;
  align-items: center;
  padding: 12px 0;

  .history-icon {
    margin-right: 12px;
  }

  .history-details {
    p {
      margin: 0;
      font-weight: 500;

      &.title {
        font-size: 16px;
        line-height: 18px;
      }

      &.date {
        font-size: 12px;
        color: var(--color-element-inactive-dark);
      }
    }
  }
}