.linear-progress {
  width: 100%;
  padding: 0 24px;
  box-sizing: border-box;

  .update-name {
    color: var(--color-type-secondary);
    font-size: 12px;
    font-weight: 700;
    margin: 0 0 4px 0;
  }

  &--container {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: var(--color-type-secondary);
      font-size: 12px;
      font-weight: 500;
      margin: 0 0 4px 0;

      &.percentage {
        color: var(--color-accent);
        font-weight: 700;

        &.success {
          color: var(--color-success-green);
        }
    
        &.error {
          color: var(--color-red);
        }
      }
    }
  }

  &--line {
    height: 8px!important;
    border-radius: 12px;
    background-color: var(--color-element-inactive)!important;
    
    &.success {
      span {
        background-color: var(--color-success-green)!important;
      }
    }

    &.error {
      span {
        background-color: var(--color-red)!important;
      }
    }

    span {
      background-color: var(--color-accent)!important;
    }
  }

  .status-message {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-type-primary);

    &.success {
      color: var(--color-success-green);
    }

    &.error {
      color: var(--color-red);
    }
  }

  .speedMbps {
    font-size: 12px;
    font-weight: 600;
    color: var(--color-type-secondary);
  }
}