.user-manual {

  p {
    color: var(--color-type-secondary);
    font-weight: 400;

    &.user-manual {
      &--title {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  &--video {
    display: flex;
    justify-content: center;

    iframe {
      max-width: 450px;
    }
  }

  &--pdf-box {
    background-color: var(--color-background);
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;

    .upper {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      p {
        margin-left: 12px;
        font-weight: 700;
        font-size: 16px;
      }
    }

    a {
      border: 1px solid var(--color-type-primary);
      border-radius: 8px;
      padding: 6px 12px;
      color: var(--color-type-secondary);
      margin-bottom: 12px;
      max-width: 300px;
    }
  }

  .buy-section {
    a {
      background-color: var(--color-background);
      margin-bottom: 12px;
      width: 100%;
      padding: 12px;
      border-radius: 8px;
    }
  }
}