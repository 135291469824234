.hours-ranges-container {
  margin-bottom: 24px;

  .hours-ranges {
    display: grid;
    grid-template-columns: repeat(2, calc(50% - 12px));
    column-gap: 24px;
    
    .input {
      margin-bottom: 0;
    }
  }
}