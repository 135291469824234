.share-channels {

  .page__content {
    display: flex;
    justify-content: center;

    .page-list {
      width: 100%;
    }
  }

  .loader {
    height: auto;
  }

  .share-channel-item {
    border-radius: 12px;
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .left-section {
      width: 100%;
      display: flex;
      align-items: center;

      .icon {
        background-color: var(--color-element);
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 40px;
        width: 40px;
        min-width: 40px;
        margin-right: 16px;
  
  
        svg {
          [stroke] {
            stroke: var(--color-accent);
          }
  
          [fill] {
            fill: var(--color-accent);
          }
        }
      }
  
      p {
        margin: 0;
      }
    }    

    .checkbox {
      margin-bottom: 0;
      width: auto;
    }
  }
}