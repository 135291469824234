.info-status-screen__content {
  .migration-status-icon {
    ~ .info-status-screen {
      &__title {
        line-height: 32px;
        margin-top: 80px;
      }
      &__subtitle {
        padding: 0;
      }
    }
  }
}