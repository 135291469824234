.installation_create {
  .arrow_button {
    margin-bottom: 24px;

    &:last-of-type {
      margin-bottom: 0;
    }
  }
}

.integration-list {
  display: flex;
  justify-content: center;

  .radios-with-icons {
    width: 100%;
  }
}