.marker {
  &.current_loc {
    z-index: 9999999999999;
    background-color: var(--color-accent);
    height: 16px;
    width: 16px;
    border-radius: 50%;
    box-shadow: 0 0 1px 1px #ff4016;
    animation: location_pulse 2s infinite;
  }

  @keyframes location_pulse {
    0% {
      box-shadow: 0 0 0 0 rgba($color: #ff4016, $alpha: 0.5);
    }
    100% {
      box-shadow: 0 0 0 20px rgba($color: #ff4016, $alpha: 0);
    }
  }
}