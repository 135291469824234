.icon-off {
  line-height: 0;

  [stroke] {
    stroke: var(--color-type-primary);
  }

  [fill] {
    fill: var(--color-type-primary);
  }
}
