.electrical-strike {
  display: flex;
  align-items: center;

  .switch {
    width: auto;
    margin-right: 12px;
  }

  p {
    margin: 0 0 12px 0;
    font-size: 16px;
  }
}