.drive-add-identify {

  &--header {
    display: flex;
    align-items: center;

    svg {
      margin-right: 12px;
      [stroke] {
        stroke: var(--color-accent);
      }
    }
  }

  .device-bluetooth {
    display: flex;
    align-items: center;
    margin-bottom: 12px;

    svg {
      [fill] {
        fill: var(--color-accent);
      }
    }
  
    &--info {
      margin-left: 16px;
  
      p {
        margin: 0;
        line-height: 20px;
  
        &.device-name {
          font-weight: 700;
          font-size: 16px;
        }
  
        &.mac-address {
          color: var(--color-type-secondary);
          font-size: 12px;
        }
      }
    }
  }

  .button-container {
    display: flex;
    justify-content: center;

    .button {
      max-width: 600px;
    }
  }
}