.trigger-details {
  &__execution {
    &--last,
    &--next,
    &--remaining {
      display: flex;
      margin-bottom: 16px;

      p {
        margin: 0 0 0 8px;
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        color: var(--color-type-secondary);
      }
    }

    &--last {
      &-failed {
        p {
          color: var(--color-red);
        }
      }
    }
  }

  .trigger-header {
    &__switch {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(var(--color-type-primary), .1);

      .switch {
        height: 32px;
        width: auto;
      }
    }

    &__heading {
      margin: 0;
      font-weight: 600;
      line-height: 24px;
    }
  }
}
