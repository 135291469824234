.remotes {
  .remote-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 12px;
    background-color: var(--color-element);
    padding: 12px 16px;
    margin-bottom: 24px;
  }
}