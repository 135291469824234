.channel-groups {
  .group-item {
    height: 64px;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    border-radius: 8px;
    padding: 16px;
    background: var(--color-element);
    color: var(--color-type-primary);
    border: 1px solid transparent;
    outline: 0;
    width: 100%;
    box-sizing: border-box;

    .checkbox {
      margin-bottom: 0;
    }
  }
}