.history-gate-icon {
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  position: relative;

  svg {
    &:first-of-type {
      height: 32px;
      width: 32px;
  
      [stroke] {
        stroke: var(--color-accent);
      }
    }
  }
}