.slats-section {
  display: flex;
  padding: 16px;
  height: 320px;
  width: 135px;
  box-sizing: border-box;
  position: relative;

  &::before {
    content: '';
    width: 100%;
    height: 100%;
    background-color: var(--color-element-inactive);
    opacity: 0.8;
    border-radius: 32px;
    position: absolute;
    left: 0;
    top:0;
  }

  &.active {
    &::before {
      border: 2px solid var(--color-accent);
      animation: borderAnimation 2s linear infinite;

      @keyframes borderAnimation {
        0% {
          border-color: var(--color-accent);
        }
      
        50% {
          border-color: var(--color-element-inactive);
        }
    
        100% {
          border-color: var(--color-accent);
        }
      }
    }
  }

  &.disabled {
    opacity: 0.5;
  }

  &__wrapper {
    display: flex;
    align-items: center;
    position: relative;
    background-color: var(--color-element);
    border-radius: 16px;
    padding: 16px;
    box-sizing: border-box;
    width: 100%;
  }

  .vertical-slider {
    color: var(--color-accent);
    padding: 0;
    width: 6px;

    .vertical-slider__rail {
      background-color: var(--color-accent);
      opacity: 1;
    }

    .vertical-slider__thumb {
      width: 23px;
      height: 23px;
      border: 5px solid #fff;
      background-color: var(--color-element-inactive);
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);

      ::before {
        box-shadow: none;
      }
    }
  }
}