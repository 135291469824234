.status-item {
  display: flex;
  align-items: center;
  line-height: 8px;

  .icon-toast-error, .icon-toast-success {
    svg {
      height: 16px;
      width: 16px;
    }
  }

  p {
    margin-left: 12px;
    font-size: 12px;
    color: var(--color-type-secondary);
  }
}