.action-conditions {

  &--container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .action-conditions {
      width: 100%;
    }
  }

  .action-condition {
    box-sizing: border-box;
    width: 100%;
  
    &.list-item {
      justify-content: space-between;
      margin-bottom: 0;
      border-radius: 16px;
      background: var(--color-element);
      padding: 12px 16px;
    }
  }
}