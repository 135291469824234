.failure-mode {
  display: flex;
  justify-content: center;

  form {
    width: 100%;
  }

  .description {
    color: var(--color-type-secondary);
    font-size: 12px;
  }
}