.condition-list__box {
  background-color: var(--color-element);
  border-radius: 12px;
  display: flex;
  padding: 16px;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 24px;
  align-items: center;
  min-height: 80px;

  &:disabled {
    cursor: auto;
    opacity: 0.5;
  }

  &-icon {
    background: var(--color-background);
    border-radius: 6px;
    margin-right: 16px;
    height: 40px;
    width: 40px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 8px;
    justify-content: center;

    &.no-background {
      background-color: transparent;
    }

    [stroke] {
      stroke: var(--color-accent);
    }
  }

  &-wrapper {
    text-align: left;
  }

  &-content {
    color: var(--color-type-secondary);
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  &-title {
    line-height: 24px;
    font-weight: 600;
    font-size: 16px;
    color: var(--color-type-primary);
  }

  &.disabled {
    .condition-list__box-title,
    .condition-list__box-content {
      color: var(--color-element-inactive-dark);
    }
  }
}