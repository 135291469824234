.see-more {
  background-color: var(--color-element);
  display: flex;
  flex-direction: column;
  margin-left: -24px;
  box-sizing: border-box;
  width: calc(100% + 24px);
  padding: 24px;

  &--header {
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 24px 0;
  }

  &--list {
    display: flex;
    overflow-x: auto;
  }
}