.details-controls {

  .device-box--exalus.device-box--list-item.device-box--details {

    .device-box__head.space-between {
      justify-content: space-between;
      width: 100%;
    }
  }

  .left {
    display: flex;
    align-items: center;
  }

  &__detection-time {
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--color-element-inactive-dark);
  }

  &__state {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: var(--color-type-secondary);
  }
  
  &__additional-text {
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    color: var(--color-type-secondary);
    margin-bottom: 16px;
  }

  .row-container {
    display: flex;
    align-items: center;
    margin-bottom: 8px
  }
}

.list-controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 140px;

  &--list-item {
    padding: 7px 0;
    align-items: flex-end;
  }
}