.icon-scenario {
  &--alarm {
    mask {
      fill: var(--color-element) !important;
    }
  }
}

body.dark {
  .icon-scenario {
    &--alarm {
      mask {
        fill: var(--color-type-primary) !important;
      }
    }
  }
}