.accordion {
  padding: 20px 0;

  &.MuiAccordion-root {
    background: none;
    color: inherit;

    &.Mui-expanded {
      &::before {
        opacity: 1;
      }
    }
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;

    &.MuiAccordionSummary-root {
      padding: 0;
      min-height: auto;

      h3 {
        display: flex;
        align-items: center;

        span {
          &.with-icon {
            margin-right: 12px;
          }

          &.error {
            color: var(--color-red);
          }
        }
      }
    }

    &-content {
      &.MuiAccordionSummary-content {
        margin: 0;
        cursor: pointer;
      }

      &.not-bold {
        h3 {
          font-weight: 600;
          font-size: 14px;
        }
      }
    }
  }

  &__expand-icon {
    position: relative;
    left: 4px;
  }

  &__content-wrapper {
    overflow: hidden;
    transition-duration: 250ms;
    transition-property: max-height; // max height and transition duration are handled in JS
    transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);

    &.MuiAccordionDetails-root {
      padding: 0;
    }
  }

  &__content {
    padding-top: 32px;
    white-space: pre-wrap;

    .checkbox {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }


  &--border {
    border-bottom: 1px solid var(--color-element-inactive);
    &.accordion--hide-border {
      border-bottom-color: transparent;
    }
  }

  &--small-content {
    .accordion__content {
      padding-top: 16px;
    }
  }
}
