.meter-analysis {
  .empty-prices {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    span {
      margin-left: 12px;
      font-size: 12px;
      color: var(--color-type-secondary);
    }
  }
}