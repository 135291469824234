.icon-switch {
  &__clip-path {
    transition: transform 0.15s ease-in;
    transform-origin: center center;
  }

  &--on {
    .icon-switch__clip-path {
      transform: scale(2.3);
    }
  }
}
