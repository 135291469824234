.dialog-confirmation {
  padding: 0 24px;

  &__buttons-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
  }

  &__button {
    font-size: 16px;
    font-weight: bold;
    width: 100%;
    min-height: 40px;
    position: relative;

    &--primary {
      color: var(--color-accent);

      &:disabled {
        opacity: 0.5;
      }
    }

    &--secondary {
      &:disabled {
        opacity: 0.5;
      }
    }

    &-divider {
      width: 1px;
      height: 40px;
      background-color: var(--color-element-inactive);
    }
  }

  &__header {
    font-size: 18px;
    line-height: 24px;
    margin: 0 0 24px;

    &.with-icon {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &--container {
      font-weight: 700;
      display: flex;
      justify-content: space-between;

      span {
        min-width: 75px;

        &:first-of-type {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }

  &__content {
    font-size: 14px;
    color: var(--color-type-secondary);
    padding: 0 12px;
    font-weight: 400;

    span {
      font-weight: 700;
      text-decoration: underline;
    }

    a {
      color: var(--color-accent);
      font-weight: 600;
    }

    &-description {
      text-align: left;
    }
  }

  &--direction-column {
    .dialog-confirmation {
      &__buttons-wrapper {
        flex-direction: column;
      }

      &__button {
        width: 100%;

        &--secondary {
          padding-bottom: 20px;
        }

        &--primary {
          border-left: 0;
          border-top: 1px solid var(--color-element-inactive);
          padding-top: 20px;
        }
      }
    }
  }

  &--direction-column-reverse {
    .dialog-confirmation {
      &__buttons-wrapper {
        flex-direction: column-reverse;
      }

      &__button {
        width: 100%;

        &--primary {
          border-top: 0;
          padding-bottom: 20px;
          border-bottom: 1px solid var(--color-element-inactive);
        }

        &--secondary {
          padding-top: 20px;
        }
      }
    }
  }
}
