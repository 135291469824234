.eco-indicator {
  background: var(--color-element);
  padding: 24px;
  margin: 8px 0;
  box-sizing: border-box;

  .indicator {
    position: relative;
    height: 94px;

    &__list {
      display: flex;
      align-items: center;
    }

    &__tooltip {
      position: absolute;
      bottom: 0;
      left: var(--position);
      transform: translate3d(-50%, 0, 0);

      &-box {
        position: relative;
        border-radius: 8px;
        border: 1px solid var(--color-element-inactive);
        padding: 8px 12px;
      }

      &-handler {
        position: absolute;
        top: -12px;
        left: var(--left);
        transform: translate3d(-50%, 0, 0);
      }
    }
  }

  &__heading {
    display: flex;
    align-items: center;
    padding-bottom: 32px;
  }
}
