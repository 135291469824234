@import '../../../styles/mixins.scss';

.donut-chart {
  &__progress {
    transform-origin: center;
    transform: rotate(-225deg);
    stroke: var(--color-background);
  }

  &__sunlight-ticks {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(137deg) scale(1.0);

    @include rwd(350) {
      display: none;
    }
  }

  &__sunlight-tick {
    position: absolute;
    width: 9px;
    height: 1.5px;
    background: var(--color-element-inactive-dark);

    &--active {
      background: var(--background);
    }

    &--big {
      width: 11px;
    }
  }

  &__avg-indicator {
    top: var(--top);
    left: var(--left);
    position: absolute;
    transform: translate3d(-50%, -50%, 0);

    @include rwd(375) {
      display: none;
    }
  }

  &__progess {
    position: relative;
  }

  &__chart {
    transform-origin: center;
    transform: rotate(-225deg);
  }

  &__rect {
    transform: translate3d(20px, 21px, 0) rotate(45deg);
    fill: var(--color-element);
  }
}
