.details-page {
  display: flex;
  flex-direction: column;
  margin: 0 -24px;
  width: calc(100% + 48px);
  flex: 1 0 100%;

  &__subheader {
    box-sizing: border-box;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    position: relative;
  }

  &__controls {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &-slider {
      padding: 28px 0 80px;
    }


    .box {
      min-width: 240px;
      height: 64px;
      display: flex;
      justify-content: center;
      align-items: center;

      > * {
        padding: 0 7px;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      .switch {
        margin-left: 16px;
      }

      .icon {
        margin-left: 0;
      }

      .status {
        font-size: 16px;
      }
    }
  }

  &__message {
    text-align: center;
    font-size: 12px;

    &.box {
      padding: 16px 27px;
      font-weight: 500;
    }
  }

  &__status-error.box {
    cursor: initial;
  }

  &__color-item {
    background-color: var(--rgb-color);
    border-radius: 50%;
    border: 1px solid var(--color-element-inactive);
    width: 32px;
    height: 32px;
    box-sizing: border-box;
  }

  &__bg-wrapper {
    height: calc(100% - 32px - 24px);
    box-sizing: border-box;
    background: var(--color-element-inactive);
    overflow: hidden;
    margin: 0 -24px;
  }

  &__box {
    margin: 32px 24px 0;
    background-color: var(--color-element);
    border-radius: 16px;
    display: flex;
    align-items: center;
    padding: 24px 18px;
    box-sizing: border-box;

    &-text {
      display: flex;
      font-weight: 500;
      font-size: 30px;
      line-height: 32px;

      &--small {
        font-size: 16px;
        line-height: 24px;
      }
    }

    &-unit {
      font-weight: 600;
      line-height: 24px;
      margin-left: 8px;
      font-size: 14px;
      align-self: flex-start;
    }

    [class^='icon'] {
      height: 24px;
      width: 24px;
      margin-right: 16px;
    }
  }

  &__wrapper-margin {
    margin-left: 24px;
    margin-right: 24px;
  }

  &__element {
    background-color: var(--color-element);
    padding: 24px 24px;
  }
}
