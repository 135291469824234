.check-list {
  margin-top: 24px;

  li {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    font-size: 16px;

    &.error-message {
      span {
        color: var(--color-type-secondary);
        font-size: 12px;
      }
    }

    .status {
      margin-right: 12px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }
}