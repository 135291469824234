.icon-no-connection {
  height: 32px;

  rect {
    stroke: var(--color-accent);
  }

  &.unknown {
    rect {
      stroke: var(--color-element-inactive-dark);
    }
  }
}
