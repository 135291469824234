.update-controller {

  &--content {

    &.dialog-content {
      padding: 0 24px;
    }

    .resource-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 24px;

      .button {
        width: unset;
        height: 30px;
      }
    }

    .version-container {
      margin-bottom: 12px;

      &:last-of-type {
        hr {
          display: none;
        }
      }

      h3 {
        text-align: left;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 8px;
      }
    }
  }
}