.exalus-task-item-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--color-element);
  margin-bottom: 16px;
  border-radius: 16px;
  padding: 16px 12px;

  .exalus-task-item {
    display: flex;
    align-items: center;

    .icon-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      width: 40px;
      height: 40px;
      box-sizing: border-box;
      padding: 2px;

      svg {
        height: 30px;
        width: 30px;

        [stroke] {
          stroke: var(--color-accent);
        }

        [fill] {
          fill: var(--color-accent);
        }
      }
    }

    .exalus-task-item {
      &__content {
        margin-left: 16px;
      }

      &__title {
        margin: 0;
        font-weight: 600;
        line-height: 18px;
      }

      &__text {
        margin: 6px 0 0 0;
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        color: var(--color-type-secondary);
      }
    }
  }
}