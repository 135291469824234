.share-time {

  &-info {
    color: var(--color-type-secondary);
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;

    span {
      font-weight: 700;
      text-decoration: underline;
    }
  }

  .period_label {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    margin: 0 0 16px 0;
  }

  .date_time {
    display: flex;
    align-items: flex-end;
    margin-bottom: 32px;

    .custom-calendar {
      margin-right: 16px;
      width: 100%;
      max-width: 300px;
    }

    .input {
      width: 100%;
      max-width: 300px;
      margin-bottom: 0;
    }
  }
}