.popup-icon {
  [stroke] {
    stroke: var(--color-type-secondary);
  }

  circle {
    fill: var(--color-type-secondary);
  }

  rect {
    fill: var(--color-element);
  }
}