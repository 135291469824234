.configuration_details_exalus {
  form {
    .row-container {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .custom-slider {
    color: var(--color-accent);
  }
}