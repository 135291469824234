.action-advanced-form {
  &__list {
    &-item {
      &:not(:last-child) {
        border-bottom: 1px solid var(--color-element-inactive);
      }
    }
  }

  &__list-header {
    margin: 8px 0 24px;
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
    color: var(--color-type-secondary);
  }

  &__name-wrapper {
    position: relative;
  }

  &__name-label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    color: var(--color-type-secondary);
  }

  &__name-box {
    padding: 16px;
    background: var(--color-element);
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__name {
    margin: 0;
    line-height: 24px;
  }

  &__edit-button {
    display: flex;
    padding: 0;
  }

  &__devices-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px;
  }

  &__device-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &:not(:last-of-type) {
      margin-bottom: 24px;
    }
  }

  &__device-item-button {
    display: grid;
    place-items: center;
    width: 32px;
    height: 32px;
  }
}
