.state-sync-btn {
  background-color: var(--color-element);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;

  [stroke] {
    stroke: var(--color-type-primary)
  }

  @keyframes rotating {
    from{
      transform: rotate(0deg);
    }
    to{
      transform: rotate(360deg);
    }
  }

  &.rotating {
    animation: rotating 4s linear infinite;
  }

  &.ios {
    .icon-synchronize {
      transform: scale(1.5);
    }
  }
}