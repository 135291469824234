@import "react-responsive-carousel/lib/styles/carousel.min.css";

.store-device-details {

  &--title {
    font-weight: 700;
    font-size: 18px;
    text-align: center;
    line-height: 24px;
  }

  &--model {
    font-weight: 400;
    font-size: 14px;
    text-align: center;
    line-height: 24px;
  }

  &--description {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  &--parameters {
    font-weight: 500;
    font-size: 12px;
    line-height: 20px;
    color: var(--color-element-inactive);

    span {
      margin-left: 8px;
      color: var(--color-type-primary);
    }
  }

  &--pictures {
    height: 200px;
    margin-bottom: 56px;

    .device-carousel-pictures {

      .carousel.carousel-slider {
        overflow: visible;

        .slider {
          justify-content: unset;
          height: auto;
  
          .slide {
            display: flex;
            align-items: center;

            .slide-item {
              min-width: 350px;
              max-width: 450px;

              img {
                height: 220px;
                width: auto;
              }
            }

            iframe {
              margin: 0;
            }
          }
        }
      }
    }
  }

  .carousel {
    .control-dots {
      bottom: -32px;

      .dot {
        border-radius: 0;
        background-color: var(--color-element-inactive);

        &.selected {
          background-color: var(--color-accent);
        }
      }
    }
  }

  p {
    color: var(--color-type-secondary);
  }

  &--pdf-box {
    background-color: var(--color-background);
    border-radius: 8px;
    padding: 16px;
    box-sizing: border-box;

    .upper {
      display: flex;
      align-items: center;
      margin-bottom: 12px;

      p {
        margin-left: 12px;
        font-weight: 700;
        font-size: 16px;
      }
    }

    a {
      border: 1px solid var(--color-type-primary);
      border-radius: 8px;
      padding: 6px 12px;
      color: var(--color-type-secondary);
      margin-bottom: 12px;
      max-width: 300px;
    }
  }

  .buy-section {
    a {
      background-color: var(--color-background);
      width: 100%;
      padding: 12px;
      border-radius: 8px;
    }
  }
}