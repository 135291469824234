.dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  z-index: 5;
  color: var(--color-type-primary);
  font-size: 16px;
  padding: 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  &--full-width {
    .dialog__content {
      padding: 0;
    }
  }

  &--bottom {
    justify-content: flex-end;
    padding: 0;

    .dialog__content {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  &--active {
    opacity: 1;
    visibility: visible;
  }

  &__backdrop {
    background-color: var(--color-backdrop);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  &__content {
    padding: 24px 0;
    box-sizing: border-box;
    background: var(--color-element);
    border-radius: 8px;
    max-height: 100%;
    width: 100%;
    max-width: 600px;
    overflow-y: auto;
    position: relative;
    z-index: 1;
    white-space: pre-wrap;
  }

  &__closeBtn {
    position: absolute;
    top: 20px;
    right: 12px;
  }

  &__title {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

