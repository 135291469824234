.optimizer-balance {
  width: 100%;

  .mode {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: var(--color-type-secondary);
    margin: 0;
  
    span {
      font-weight: bold;
      color: var(--color-accent);

      &.blue {
        color: var(--color-blue);
      }

      &.green {
        color: var(--color-green);
      }

      &.yellow {
        color: var(--color-warning);
      }

      &.red {
        color: var(--color-red);
      }
    }
  }
  
  .balance-value {
    display: flex;
    justify-content: flex-end;
    @extend .mode;
    align-items: center;
    flex-direction: row;
    text-transform: none;
    min-width: 170px;

    &.start {
      justify-content: flex-start;
    }

    span {
      color: var(--color-type-secondary);
    }
  
    .icon-arrow-link {
      margin-left: 6px;
    }

    span {
      &:last-of-type {
        color: var(--color-red);
        margin-left: 4px;
      }
    }
  
    &.up {
      span {
        &:last-of-type {
          color: var(--color-green);
          margin-left: 4px;
        }
      }
    }
  }
}
