.user-box {
  padding: 0;
  border-radius: 12px;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;

  &.background {
    background-color: var(--color-element);
    padding: 24px 16px;
    margin-bottom: 24px;
  }

  .left-section {
    display: flex;
    align-items: center;

    .icon-initial {
      height: 40px;
      width: 40px;
      margin-right: 16px;
    }

    img {
      border-radius: 50%;
      margin-right: 16px;
    }
  }

  p {
    margin: 0;
    color: var(--color-type-primary);
    font-size: 12px;
    font-weight: 600;
    text-align: start;

    &.name {
      font-size: 16px;
      margin-right: 8px;
      font-weight: 700;
      line-height: 24px;

      .separator {
        color: var(--color-element-inactive);
      }

      .access {
        color: #676983;

        &.accent {
          color: var(--color-accent)
        }
      }
    }
  
    &.email {
      color: var(--color-type-secondary);
    }
  }
}