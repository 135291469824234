.dashboard__device-list {
  list-style-type: none;

  &.grid {
    .device-box {
      .device-box__controls {
        margin-left: 0;

        .cover-control-box {
          .cover-control-box__controls {
            justify-content: space-between;
            align-items: flex-start;

            &.center {
              justify-content: center;
            }
          }
        }
      }
    }

    .action-box {
      .cover-control-box {
        .cover-control-box__controls {
          justify-content: space-between;
        }
      }
    }
  }
}