.device-settings {
  align-items: center;
  display: flex;
  margin: 0;
  height: auto;
  width: 100%;

  &__icon {
    line-height: 0;
    width: 32px;
    height: 32px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    background: var(--color-accent);
    border-radius: 10px;

    svg {
      [fill]:not([fill='none']) {
        fill: var(--color-accent);
      }

      [stroke]:not([stroke='none']) {
        stroke: #fff;
      }

      circle[fill]:not([fill='none']) {
        fill: #fff;
      }
    }
  }

  &__info {
    margin: 0;
    width: 100%;

    .device-title {
      font-size: 18px;
      line-height: 24px;
    }

    .device-type {
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: var(--color-type-secondary);
    }
  }

  &__channels {
    .arrow_button {
      &.hidden {
        opacity: 0.6;
      }

      .left_section {
        svg {
          height: 32px;
          width: 32px;
        }
      }
    }
  }
}