.bindings {
  .remove-section {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;

    button {
      position: relative;

      .icon-delete {
        [stroke] {
          stroke: var(--color-red);
        }
      }

      .number {
        position: absolute;
        left: -3px;
        bottom: -3px;
        background-color: var(--color-element);
        color: var(--color-type-primary);
        height: 20px;
        width: 20px;
        border-radius: 50%;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}