.installation-shared {

  .loader {
    height: auto;
  }

  .empty_member_list {
    color: var(--color-type-secondary);
    font-size: 14px;
    line-height: 24px;
    font-weight: 500;
    text-align: center;
  }

  .arrow_btn {
    margin-bottom: 24px;
  }

  .header {
    &.column {
      .header {

        &__title {
          margin-bottom: 24px;
        }

        &__children {
          .input {
            margin: 0;
          }
        }
      }
    }
  }
}