.favourite {
  width: 100%;
  box-sizing: border-box;

  &__header {
    margin-top: 64px;
    position: relative;
    display: flex;
    justify-content: center;

    &-title {
      font-size: 14px;
      font-weight: 400;
      text-align: center;
      color: var(--color-type-secondary);
      background-color: var(--color-background);
      position: relative;
      z-index: 1;
      padding: 0 12px;
    }

    &-line {
      position: absolute;
      left: 0;
      top: calc(50%);
      width: 100%;
      height: 1px;
      background-color: var(--color-element-inactive);
      z-index: 0;
    }
  }

  &__buttons {
    margin: 8px 0;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 8px;

    &--cover {
      grid-template-rows: 1fr 1fr;
      > *:last-child {
        grid-column: 1 / 4;
        grid-row: 2 / 3;
      }

      &.flex {
        display: flex;
      }
    }

    &--gate {
      grid-template-rows: 1fr 1fr;
      grid-template-columns: 1fr 1fr;
      > *:last-child {
        grid-column: 1 / 3;
        grid-row: 2 / 3;
      }

      &.flex {
        display: flex;
      }

      &.no-grid {
        display: block;

        button {
          margin-bottom: 8px;
        }
      }

      .cover-button.button.inactive > [class^=icon] {
        [fill] {
          fill: var(--color-element-inactive);
        }
      }
    }
  }
}
