.tutorial-step-item {
  p {
    font-size: 16px;
  }

  &--title {
    color: var(--color-type-primary);
  }

  &--content {
    color: var(--color-type-secondary);
  }
}