.initiate-button-rectangle {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__bg-element {
    height: 70px;
    width: 232px;
    border-radius: 8px;
    background-color: var(--color-element-inactive);
    opacity: 0.4;
  }

  &:not(:active) {
    .initiate-button-rectangle {
      &__bg-element {
        transition: transform var(--button-active-transition), background-color var(--button-active-transition);
      }

      &__button-content {
        transition: background-color var(--button-active-transition);
      }
    }

    path {
      transition: stroke var(--button-active-transition);
    }
  }

  &:active {
    .initiate-button-rectangle {
      &__bg-element {
        transform: scale(0.83);
        background-color: var(--color-type-secondary);
        opacity: 0.1;
      }

      &__button-content {
        background-color: var(--color-accent);
      }
    }
  }

  &__button {
    border-radius: 8px;
    padding: 6px;
    background-color: var(--color-element);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);

    &-content {
      border-radius: 8px;
      box-sizing: border-box;
      width: 180px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-element);

      &--on {
        background-color: var(--color-accent);
        color: var(--color-element);
      }
    }
  }
}