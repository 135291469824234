.available-devices {
  .device-list-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--color-element);
    border-radius: 8px;
    box-sizing: border-box;
    color: var(--color-type-primary);
    font-size: 16px;
    height: 64px;
    line-height: 1;
    outline: 0;
    padding: 16px;
    width: 100%;
    margin-bottom: 24px;

    &.disabled {
      opacity: 0.6;
    }

    .left-side {
      display: flex;
      align-items: center;
      padding: 8px 0;

      .icon {
        position: relative;
        margin-right: 12px;

        .remembered {
          position: absolute;
          right: -10px;
          top: -10px;
          background: var(--color-element);
          border-radius: 50%;
          height: 20px;
          width: 20px;
          display: flex;
          align-items: center;
          justify-content: center;

          .icon-padlock {
            height: 14px;
            width: 14px;
          }
        }
      }

      .details {
        display: flex;
        flex-direction: column;

        .ssid {
          font-size: 12px;
          line-height: 16px;
          font-weight: normal;
          color: var(--color-type-secondary);
          margin-top: 2px;
        }
      }
    }

    .right-side {
      display: flex;
      align-items: center;

      .checkbox {
        margin: 0 0 0 12px;
      }
    }
  }
}