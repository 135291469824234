.installation {
  &-remove {
    &__header {

      &-title {
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
      }

      &-subtitle {
        font-weight: 600;
        font-size: 14px;
        line-height: 24px;
        margin: 24px 0;
      }
    }

    &__rules {
      margin: 24px 0;
      font-size: 14px;
      line-height: 24px;
      font-weight: 400;
      color: var(--color-type-secondary);

      &-text {
        font-weight: 600;
      }

      &-element {
        margin: 16px 0;
        display: flex;
      }

      &-bullet {
        width: 28px;
        height: 28px;
        margin-right: 8px;
      }
    }
  }
}
