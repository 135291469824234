.action-device-list {
  margin: 24px 0;
  padding-bottom: 24px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__list {
    margin-top: 32px;

    > div {
      display: block;
      margin-bottom: 24px;
      position: relative;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 24px 0;
  }
}
