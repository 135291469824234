@import '../../styles/mixins.scss';

.ip-input {
  display: flex;
  align-items: flex-end;

  &--container {
    label {
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      display: flex;
      align-items: center;
      padding-bottom: 8px;
      color: var(--color-type-secondary);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      margin-left: 4px;
    }
  }

  input {
    border-radius: 8px;
    height: 55px;
    width: 45px;
    margin: 0 4px;
    font-size: 24px;
    padding: 0 12px;
    text-align: center;
    background: var(--color-element);
    color: var(--color-type-primary);
    line-height: 1;
    outline: 0;
    box-sizing: border-box;
    border: 1px solid transparent;

    @include sm() {
      height: 45px;
      width: 100%;
      font-size: 16px;
      padding: 0 8px;
    }
  }

  .separator {
    font-size: 18px;
    font-weight: bold;
  }
}