.notification__tutorial {
  &--header {
    color: var(--color-type-primary);
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 24px;
  }

  &--icon {
    display: flex;
    justify-content: center;
  }

  ul {
    padding-left: 24px;

    li {
      color: var(--color-type-secondary);
      font-size: 14px;
      list-style: square;
      margin-bottom: 24px;
    }
  }
}