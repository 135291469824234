.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  width: 40px;
  height: 40px;
  box-sizing: border-box;
  background-color: var(--color-element);
  padding: 2px;

  &--reverse {
    background-color: var(--color-background);
  }

  &--relative {
    position: relative;

    div {
      &:not(.icon-error-small) {
        svg {
          [stroke] {
            stroke: var(--color-accent);
          }
    
          [fill] {
            fill: var(--color-accent);
          }
        }
      }
    }
  }

  &--reverse-icon {
    svg {
      [stroke] {
        stroke: var(--color-type-primary);
      }
      [fill] {
        fill: var(--color-type-primary);
      }
    }
  }

  &--size-32 {
    width: 32px;
    height: 32px;
  }

  &--no-padding {
    padding: 0;
  }
}
