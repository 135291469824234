.icon-sort-handle {
  line-height: 0;

  [stroke] {
    stroke: var(--color-type-primary);
  }

  &--active {
    [fill] {
      fill: var(--color-type-primary);
    }

    [stroke] {
      stroke: var(--color-element);
    }
  }
}
