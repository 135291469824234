.graphic {
  &-stroke-accent {
    stroke: var(--color-accent);
  }

  &-fill-accent {
    fill: var(--color-accent);
  }

  &-fill-element {
    fill: var(--color-element);
  }

  &-fill-element-inactive {
    fill: var(--color-element-inactive);
  }

  &-fill-background {
    fill: var(--color-background);
  }
}
