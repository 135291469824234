.configuration_info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  p {
    color: var(--color-type-primary);
    font-weight: 700;
    font-size: 16px;
    margin: 0;
  }

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 24px;
    width: 24px;
    background-color: transparent;
    border: 1px solid var(--color-type-primary);
    border-radius: 50%;
  }
}