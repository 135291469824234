.drive-add.start {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 48px;

  .header {
    &__title {
      justify-content: center;

      h2 {
        font-size: 24px;
        max-width: 300px;
        text-align: center;
        line-height: 32px;
      }
    }
  }
}