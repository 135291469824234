.empty-state {
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  position: relative;

  p {
    margin: 0;
  }

  &__heading {
    position: absolute;
  }

  &__background {
    display: flex;
    flex-direction: column;
    width: 100%;

    &-row {
      display: flex;
    }

    &-vertical {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 8px;

      span {
        font-size: 8px;
        font-weight: 500;
        color: var(--color-element-inactive);
      }
    }

    &-horizontal {
      margin-left: 32px;
      display: flex;
      justify-content: space-around;

      span {
        font-size: 12px;
        font-weight: 500;
        color: var(--color-element-inactive);
      }
    }

    &-bars {
      margin-left: 24px;
      width: 100%;
      display: grid;
      grid-template-columns: repeat(7, 1fr);
      grid-column-gap: 4px;
      align-items: end;

      .empty-state__background-bar {
        background: var(--color-background);

        &--1,
        &--5 {
          height: 69px;
        }
        &--2 {
          height: 140px;
        }
        &--3,
        &--6 {
          height: 129px;
        }
        &--4,
        &--7 {
          height: 100px;
        }
      }
    }
  }
}
