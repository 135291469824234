.current-value {
  display: flex;
  align-items: center;
  justify-content: center;

  &__label {
    color: var(--color-accent);
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }

  &__value {
    font-size: 31px;
    font-weight: 500;
    color: var(--color-type-primary);
    line-height: 32px;
  }

  &__unit {
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
  }
}