.eco-state {
  display: flex;
  margin: 8px 0;
  box-sizing: border-box;

  &__heading {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: var(--color-element);
    margin-right: 8px;
    padding: 24px;
    height: 100%;
    flex: 1;
    box-sizing: border-box;

    p {
      margin: 0;
    }

    &--title {
      color: var(--color-type-primary);
      font-size: 16px;
      font-weight: 700;
    }

    &--subTitle {
      font-size: 12px;
      color: var(--color-type-secondary);
      line-height: 16px;
      margin-top: 8px;
    }
  }

  &__eco {
    display: flex;
    align-items: center;
    padding: 24px;
    background: var(--background);
    box-sizing: border-box;

    .icon-eco {
      &:not(:last-child) {
        margin-right: 4px;
      }
    }
  }
}
