.icon-heart {
  line-height: 0;
  width: 24px;
  height: 24px;

  svg {
    width: 100%;
    height: 100%;
  }

  [stroke] {
    stroke: var(--color-type-primary);
  }

  [fill] {
    fill: var(--color-type-primary);
  }

  &--is-empty {
    [fill] {
      fill: none;
    }
  }
}
