.empty-state-box {
  background-color: var(--color-element);
  border-radius: 16px;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  white-space: pre-wrap;
  border: 1px solid var(--color-type-secondary-dark);
  max-width: 600px;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  hr {
    margin: 8px 0;
  }

  &__content {
    color: var(--color-type-secondary);
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
