.slats {
  position: relative;
  margin-right: 24px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .slat-diagonal {
    position: absolute;
    height: 100%;
    width: 4px;
    background: var(--color-element-inactive);
    opacity: .3;
    left: 17px;
    border-radius: 4px;
  }

  .slat {
    box-sizing: border-box;
    position: relative;
    width: 38px;
    height: 3px;
    transform-origin: 19px 1.5px;
    background: var(--color-element-inactive);
    border-radius: 4px;
  }
}