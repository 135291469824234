.calibrate-info {
  position: absolute;
  font-size: 12px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: #009EE3;
  top: -7px;
  right: -7px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 1;

  &.normal {
    position: relative;
    top: 0;
    right: 0;
  }

  &.details {
    top: -4px;
    right: -5px;
  }

  &.big {
    height: 18px;
    width: 18px;
  }
}