.chart-summary {
  &__caption {
    display: flex;
    align-items: center;
    padding: 32px 0 16px 0;

    &-text {
      margin-left: 16px;
      font-size: 16px;
      font-weight: 700;
    }
  }

  &__measurement {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;

    span {
      font-size: 16px;
      font-weight: 500;

      &:last-of-type {
        font-weight: 700;
      }
    }
  }
}