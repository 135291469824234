.schedule-list {
  &-item {
    padding: 16px;
    margin-bottom: 16px;
    background: var(--color-element);
    border-radius: 16px;
    box-sizing: border-box;

    .hours {
      font-weight: 600;
      font-size: 16px;
      color: var(--color-type-primary);
    }

    &--details {
      display: flex;
      align-items: center;
      justify-content: space-between;

      > div {
        display: flex;
        align-items: center;

        .icon {
          margin-right: 16px;
          width: 20px;
          display: flex;
          align-items: center;

          svg {
            width: 20px;

            [stroke] {
              stroke: var(--color-accent);
            }
          }
        }

        p {
          margin: 0;
          font-weight: 400;
          font-size: 14px;
          color: var(--color-type-secondary);
        }
      }
    }
  }
}