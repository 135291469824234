.message-box {
  border-radius: 8px;
  background-color: var(--color-element);
  display: flex;
  align-items: center;
  padding: 16px;
  box-sizing: border-box;
  width: 100%;

  &__icon {
    margin-right: 16px;
  }

  &__content {
    color: var(--color-accent);
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
  }
}
