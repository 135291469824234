.radios_group {
  display: flex;
  flex-direction: column;

  label {
    font-weight: 500;
    border-bottom: 1px solid var(--color-element-inactive);
    padding: 24px 0;
    font-size: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    background: var(--color-element);
    color: var(--color-type-primary);
    font-weight: 600;
    cursor: pointer;

    &:last-of-type {
      border-bottom: none;
    }

    &.checked {
      font-weight: 700;
    }

    &.transparent {
      background: transparent;
    }

    &.radios_group__label-weight {
      font-weight: 600;

      &.checked {
        font-weight: 600;
      }
    }

    input {
      margin: 0;
      height: 20px;
      width: 20px;
      color: var(--color-accent);
      display: none;

      &+ *::before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        margin-right: 16px;
        border-radius: 50%;
        border-style: solid;
        border-width: 1px;
        border-color: gray;
      }

      &:checked {
        color: teal;

        &+ *::before {
          background: radial-gradient(var(--color-accent) 0%, var(--color-accent) 40%, transparent 50%, transparent);
          border-color: var(--color-accent);
        }
      }
    }
  }

  &.with_image {

    label {
      justify-content: space-between;
      border-bottom: 0;
      border-radius: 12px;
      padding: 16px;
      width: auto;
      margin-bottom: 24px;
  
      .left_section {
        display: flex;
        align-items: center;
  
        img {
          border-radius: 50%;
        }
  
        .icon {
          width: 40px;
          height: 40px;
          display: flex;
          align-items: center;

          &.accent {
            [stroke] {
              stroke: var(--color-accent);
            }
          }

          [stroke] {
            stroke: var(--color-type-primary);
          }
        }
  
        .text_container {
          display: flex;
          flex-direction: column;
          color: var(--color-type-primary);
          margin-left: 16px;
          
          .sub_label {
            color: var(--color-type-secondary);
            font-weight: 400;
            line-height: 16px;
          }
        }
      }
    }
  }
}