.blind-direction-buttons {
  .button-action {
    &.active {
      transition: background-color var(--button-active-transition), color var(--button-active-transition);
      background-color: var(--color-accent);
      color: var(--color-element);

      [stroke] {
        transition: stroke var(--button-active-transition);
        stroke: var(--color-element);
      }
    }
  }
}