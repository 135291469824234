.energy-donut {
  position: relative;

  &.disabled {
    opacity: 0.4;
  }
  
  .phase-number {
    color: var(--color-type-secondary);
    font-weight: 500;
    font-size: 12px;
    text-align: center;
    margin: 0;
  }

  &--icon {
    position: absolute;
    bottom: 3px;
    right: 3px;

    .icon-warning {
      height: 14px;
      width: 14px;
    }

    .icon-cross {
      height: 14px;
      width: 14px;
    }

    .icon-sad-face {
      height: 14px;
      width: 14px;

      [stroke] {
        stroke: var(--color-red);
      }
    }
  }

  .donut-chart-wrapper {
    margin: 0;
  }
}