.carouselCustom {
  scrollbar-width: none;
  height: 100%;
  flex: 1 0 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: hidden;

  &__inner {
    display: flex;
    flex: 1 0 100%;
  }
}

.carouselCustom-item {
  display: none;

  &--active {
    display: inline-block;
  }
}
