.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 12px;
  margin-bottom: 24px;

  &.column {
    display: block;

    .header__title {
      margin-bottom: 8px;
      display: block;
    }

    &.center {
      .header__title {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &.center {
      justify-content: center;
      text-align: center;
    }

    .checkbox {
      margin-bottom: 0;
      width: auto;
    }

    &-main {
      line-height: 24px;

      &--fixed-height {
        height: 40px;
        display: flex;
        align-items: center;
      }

      &--with-subtitle {
        margin-bottom: 0;
      }
    }

    &--small {
      font-size: 16px;
    }

    &-subtitle {
      font-size: 14px;
      line-height: 24px;
      font-weight: normal;
      color: var(--color-type-secondary);
      margin-top: 8px;

      &--negative-margin {
        margin-top: -16px;
      }
    }
  }

  &__children {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex: 1 0 auto;

    > * {
      cursor: pointer;
    }

    > *:not(:last-child) {
      margin-right: 16px;
    }
  }
}
