@import '../../../../styles/mixins.scss';

.custom-slider {
  color: var(--color-accent);
}

.optimizer-configuration-form {
  padding-top: 24px;

  &__item {
    &-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 24px;

      &.full-space {
        grid-template-columns: repeat(1, 1fr);
      }

      @include sm() {
        display: flex;
        flex-direction: column;

        div {
          &:first-of-type {
            .input--no-bottom-margin {
              margin-bottom: 24px;
            }
          }
        }
      }
    }

    &-row-flex {
      display: flex;
      align-items: flex-start;
    }


    &.disabled {
      opacity: 0.5;
      pointer-events: none;
    }
  }

  .button-container {
    display: flex;
    justify-content: center;
  }
}
