.cover-detail-view.facade {
  .max-width-desktop {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  .slider-section {
    &.disabled {
      opacity: 0.5;
    }
  }
}