.edit-icon-control {
  display: flex;
  margin-bottom: 24px;
  padding: 0;
  flex-direction: column;
  align-items: flex-start;

  &__caption {
    opacity: 0.7;
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
  }

  &__icon-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;

    &--accent {
      color: var(--color-accent);
    }
  }

  &__icon {
    background: var(--color-element);
    border-radius: 12px;
    width: 56px;
    height: 56px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      height: 38px;
      width: 38px;
    }

    [class^='icon'] {
      [fill] {
        fill: var(--color-type-primary);
      }

      [stroke] {
        stroke: var(--color-type-primary);
      }
    }

    &--accent {
      [class^='icon'] {
        [fill] {
          fill: var(--color-accent);
        }

        [stroke] {
          stroke: var(--color-accent);
        }
      }

      .installation-icon {
        [stroke] {
          stroke: var(--color-element);
        }
      }
    }

    &.no-background {
      background: transparent;

      .installation-icon {
        &::before {
          border: none;
        }
      }
    }
  }

  &--preview {
    cursor: default;
  }
}
