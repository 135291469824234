.action-form {
  &__devices {
    &-header {
      font-weight: bold;
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 24px;
    }

    &-element {
      display: flex;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      align-items: center;

      &.invisible {
        opacity: 0.5;
      }
    }

    &-icon {
      background-color: var(--color-element);
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      border-radius: 6px;
      align-items: center;
      margin-right: 16px;

      [class^="icon"] {
        [stroke] {
          stroke: var(--color-accent);
        }

        [fill] {
          fill: var(--color-accent);
        }
      }
    }
  }
}
