.icon-more {
  line-height: 0;

  path {
    fill: var(--color-element);
  }

  circle {
    fill: var(--color-type-primary);
  }

  &--shape-square {
    path {
      fill: var(--color-background);
    }
  }
}
