.analyse-range-header {
  display: flex;
  align-items: center;
  justify-content: center;

  .header {
    width: auto;
    margin-bottom: 0;

    &__title-main {
      text-transform: capitalize;
    }
  }

  button {
    &:disabled {
      visibility: hidden;
    }
  }
}