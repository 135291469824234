.icon-chevron {
  line-height: 0;

  [stroke] {
    stroke: var(--color-type-primary);
  }

  &--disabled [stroke] {
    stroke: var(--color-element-inactive);
  }

  &__chevron {
    transform-origin: center 16.5px;

    &--direction-up {
      transform: rotate(0deg);
    }
    &--direction-right {
      transform: rotate(90deg);
    }
    &--direction-down {
      transform: rotate(180deg);
    }
    &--direction-left {
      transform: rotate(270deg);
    }
  }

  &--no-space {
    .icon-chevron__chevron {
      &--direction-up {
        transform: translate3d(0, -10px, 0) rotate(0deg);
      }
      &--direction-right {
        transform: translate3d(10px, 0, 0) rotate(90deg);
      }
      &--direction-down {
        transform: translate3d(0, 10px, 0) rotate(180deg);
      }
      &--direction-left {
        transform: translate3d(-10px, 0, 0) rotate(270deg);
      }
    }
  }
}
