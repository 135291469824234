.box {
  display: inline-block;
  padding: 8px 16px;
  box-sizing: border-box;
  background-color: var(--color-element);
  border-radius: 8px;
  cursor: pointer;

  &--even-space {
    padding: 16px;
  }

  &--rounder {
    border-radius: 16px;
  }

  &--no-border {
    border: 0;
  }
}
