.comparison-select {
  .row-container {
    display: flex;
    align-items: flex-start;
    width: 100%;

    .input {
      width: 60%;

      &:last-of-type {
        width: 40%;
        margin-left: 12px;
      }
    }
  }
}