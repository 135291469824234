.loading-state {
  height: 270px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;

  p {
    margin: 0;
  }
}
