.exalus-user {

  &.main-info {
    padding: 0;
    border-radius: 12px;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  
    .left-section {
      display: flex;
      align-items: center;
  
      .icon-initial {
        height: 40px;
        width: 40px;
        margin-right: 16px;
      }
  
      img {
        border-radius: 50%;
      }

      .user-data {
        margin-left: 16px;
      }

      .icon-initial {
        margin-right: 0;
      }
    }
  
    p {
      margin: 0;
      color: var(--color-type-primary);
      font-size: 12px;
      font-weight: 600;
      text-align: start;
  
      &.name {
        font-size: 16px;
        margin-right: 8px;
        font-weight: 700;
        line-height: 24px;
  
        .separator {
          color: var(--color-element-inactive);
        }
  
        .access {
          color: var(--color-accent);
        }
      }
    
      &.email {
        color: var(--color-type-secondary);
      }
    }
  }
}

.rest-info {
  .row-container {
    display: flex;
    align-items: center;

    .text-info {
      width: 100%;
    }
  }
}