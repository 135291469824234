.device-item {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__header {
    align-items: center;
    display: flex;
    margin: 0;

    &.invisible {
      opacity: 0.5;
    }

    .icon-box {
      line-height: 0;
      padding: 4px;
      position: relative;

      > svg {
        width: 32px;
        height: 32px;
  
        [stroke] {
          stroke: var(--color-accent);
        }
    
        [fill] {
          fill: var(--color-accent);
        }
      }
    }
  }

  &__content-wrapper {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
    margin-right: 16px;
  }

  &__name {
    line-height: 24px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    overflow-wrap: break-word;
  }

  &__details {
    font-size: 12px;
    line-height: 16px;
    font-weight: 500;
    color: var(--color-type-secondary);
  }

  &--show-hidden {
    opacity: 0.5;
  }
}
