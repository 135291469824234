.exalus-action-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: var(--color-element);
  border-radius: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
  padding: 12px 16px;

  &.disabled {
    .exalus-action-item__content {

      svg {
        [stroke] {
          stroke: var(--color-element-inactive);
        }
        [fill] {
          fill: var(--color-element-inactive);
        }
      }

      .name {
        color: var(--color-element-inactive);
      }
    }
  }

  &__content {
    display: flex;
    align-items: center;
    width: 85%;
    cursor: pointer;

    .icon {
      margin-right: 16px;
      width: 35px;
    }

    svg {
      line-height: 0;

      [stroke] {
        stroke: var(--color-accent);
      }
      
      [fill] {
        fill: var(--color-accent);
      }
    }
  
    .name {
      font-weight: 700;
      font-size: 14px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .action-btn {
    &:not(:active) {
      path {
        transition: fill var(--button-active-transition);
        fill: var(--color-element);
      }

      circle {
        transition: fill var(--button-active-transition), stroke var(--button-active-transition);
        stroke: var(--color-type-primary);
        fill: var(--color-element);
      }
    }

    &:active {
      path {
        fill: var(--color-accent);
        stroke: var(--color-element);
      }

      circle {
        stroke: var(--color-accent);
        fill: var(--color-accent);
      }
    }
  }
}