.light-slider {
  --light-slider-input-height: 64px;
  --slider-value-width: 68px;
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: var(--light-slider-input-height);

  .rail {
    height: var(--light-slider-input-height);
    border-radius: 16px;
    border: 1px solid var(--color-element-inactive);
    opacity: 1;
    background: linear-gradient(to right, var(--color-accent) 0%, rgba(255,255,255,1) 100%);
  }

  .thumb {
    width: 15px;
    height: 73px;
    background: var(--color-background);
    border: 5px solid var(--color-element);
    box-shadow: 6.0625px 6.0625px 18.1875px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    cursor: pointer;
  }

  &__value {
    border: 1px solid var(--color-element-inactive);
    box-sizing: border-box;
    border-radius: 8px;
    padding: 12px;
    width: var(--slider-value-width);
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: clamp(34px, var(--slider-position), calc(100% - 34px));
    transform: translate3d(-50%, 0, 0);
    bottom: -70px;

    &-handle {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0 auto;
      transform: rotate(-90deg);
      top: -13px;

      path,
      circle {
        stroke: var(--color-element-inactive);
      }
    }
  }

  &__icon {
    position: absolute;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    pointer-events: none;
    top: 14px;

    &--accent {
      [stroke] {
        stroke: var(--color-accent);
      }
    }

    &--off {
      left: 8px;
    }

    &--on {
      right: 8px;
    }
  }

  &__slider-element {
    width: 100%;
  }

  &--gradient {
    .rail {
      background: linear-gradient(to right, #000000, rgba(var(--slider-gradient-color), 1)) no-repeat;
    }

    .light-slider__icon {
      &--off {
        [stroke] {
          stroke: var(--color-element);
        }
      }
      &--on {
        [stroke] {
          stroke: var(--color-type-primary);
        }
      }
    }
  }

  &--basic {
    .rail {
      background: var(--color-element);
    }

    .light-slider__icon {
      &--off {
        [stroke] {
          stroke: var(--color-element-inactive);
        }
      }
      &--on {
        [stroke] {
          stroke: var(--color-element-inactive);
        }
      }
    }
  }

  &--active {
    .thumb {
      outline: 1px solid var(--color-accent);
    }
  }

  &--white {
    .rail {
      background: linear-gradient(to right, #606060 0%, rgba(255,255,255,1) 100%);
    }

    .light-slider__icon {
      &--accent {
        [stroke] {
          stroke: #606060;
        }
      }
    }
  }
}
