.initiate-button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  &__bg-element {
    height: 232px;
    width: 232px;
    border-radius: 50%;
    background-color: var(--color-element-inactive);
    opacity: 0.4;

    &.turn-on {
      box-shadow: var(--turn-on-shadow);
    }
  }

  &:not(:active) {
    .initiate-button {
      &__bg-element {
        transition: transform var(--button-active-transition), background-color var(--button-active-transition);
      }

      &__button-icon {
        transition: background-color var(--button-active-transition);
      }
    }

    path {
      transition: stroke var(--button-active-transition);
    }
  }

  &:active {
    .initiate-button {
      &__bg-element {
        transform: scale(0.83);
        background-color: var(--color-type-secondary);
        opacity: 0.1;
      }

      &__button-icon {
        background-color: var(--color-accent);
      }
    }
  }

  &__button {
    border-radius: 50%;
    padding: 10px;
    background-color: var(--color-element);
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 50%;
    transform: translate3d(0, -50%, 0);

    &.active {
      border: 2px solid var(--color-accent);
      animation: borderAnimation 2s linear infinite;

      @keyframes borderAnimation {
        0% {
          border-color: var(--color-accent);
        }
      
        50% {
          border-color: var(--color-element-inactive);
        }
    
        100% {
          border-color: var(--color-accent);
        }
      }
    }

    &-icon {
      border-radius: 50%;
      border: 5px solid var(--color-background);
      box-sizing: border-box;
      width: 129px;
      height: 129px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: var(--color-element);

      svg[class^='icon'] {
        [stroke] {
          stroke: var(--color-element-inactive);
        }

        [fill] {
          fill: var(--color-element-inactive);
        }
      }

      &--on {
        background-color: var(--color-accent);

        svg[class^='icon'] {
          [stroke] {
            stroke: var(--color-element);
          }

          [fill] {
            fill: var(--color-element);
          }
        }
      }
    }
  }

  &--small {
    button {
      padding: 0;
      line-height: 0;
    }

    &__button-icon {
      border: 3px solid #ebedf0;
      border-radius: 50%;

      &--on {
        background-color: var(--button-background-color, var(--color-accent));

        svg[class^='icon'] {
          [stroke] {
            stroke: var(--button-icon-color, var(--color-element));
          }

          [fill] {
            stroke: var(--button-icon-color, var(--color-element));
          }
        }
      }

      &.channel-item {
        border: 0;
      }
    }
  }
}
