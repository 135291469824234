.optimizer-schedule.step1 {
  .arrow_button {
    &--icon {
      [stroke] {
        stroke: var(--color-accent);
      }
    }

    &.error {
      .arrow_btn {
        border-color: var(--color-red);
      }
    }
  }
}