.optimizer-configuration-form__item {
  .relay-config-box {
    background: var(--color-element);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 24px;

    &.relayConfig1 {
      border-top-left-radius: 0;
    }

    &.relayConfig3 {
      border-top-right-radius: 0;
    }
  }

  .sum-exists {
    .relay-config-box {
      &.relayConfig3 {
        border-top-right-radius: 8px;
      }

      &.relayConfig4 {
        border-top-right-radius: 0;
      }
    }
  }

  .tabs--type-pills {
    .tabs__list-item {
      &--active {
        position: relative;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      
        &::before {
          content: '';
          position: absolute;
          height: 17px;
          top: 39px;
          left: 0;
          right: 0;
          background: var(--color-element);;
        }
      }
    }
  }
}
