.sensor-control-box {
  display: flex;
  align-items: center;
  font-size: 14px;
  text-align: center;

  &--details {
    .sensor-control-box {
      &__error-text {
        font-size: 16px;
        font-weight: 700;
      }

      &__status {
        font-size: 16px;
      }
    }
  }

  &__value-wrapper {
    display: flex;

    &.column {
      flex-direction: column;

      .sensor-control-box__unit {
        margin-left: 0;
      }
    }
  }

  &__value {
    font-size: 18px;
    line-height: 24px;
    font-weight: 700;
  }

  &__unit {
    margin-left: 4px;
    font-size: 12px;
    line-height: 24px;
    font-weight: 500;

    &--degree {
      line-height: 16px;
    }
  }

  &__icon {
    display: flex;
    align-items: center;
    height: 24px;
    line-height: 24px;
  }

  &__status {
    font-size: 14px;
    font-weight: 700;
  }

  &__error-text {
    margin-left: 8px;
    line-height: 24px;
    font-weight: 600;
    color: var(--color-red);
  }
}