.auto-calibration-flow {
  .info {
    line-height: 1.63;
    color: var(--color-type-primary);
    font-size: 20px;
    text-align: center;
    white-space: pre-line;

    &--sub {
      font-size: 16px;
      text-align: center;
      font-weight: 600;
      margin-top: 32px;

      &.tiny {
        font-size: 14px;
      }
    }
  }

  .channel-name {
    font-size: 18px;
    font-weight: 700;
    color: var(--color-accent);
    text-align: center;
  }

  .blind-icon {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 120px;

    .icon-cover {
      box-shadow: 4.84848px 4.84848px 14.5455px rgba(0, 0, 0, 0.15);
      border-radius: 12px;
      padding: 24px;
      width: unset;
      height: unset;
    }
  }
}