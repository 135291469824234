.icon-recovery {
  position: absolute;
  font-size: 12px;
  height: 16px;
  width: 16px;
  border-radius: 50%;
  background-color: var(--color-warning-yellow);
  top: -7px;
  right: -7px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  z-index: 1;

  &.normal {
    position: relative;
    top: 0;
    right: 0;
  }

  &.big {
    height: 18px;
    width: 18px;
    top: 7px;
    right: 7px;
  }

  &.details {
    top: -4px;
    right: -5px;
  }

  &.icon-toast {
    position: static;
    height: 22px;
    width: 22px;
    font-size: 18px;
    margin-right: 4px;
  }
}