.date-picker {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 0;
  width: 100%;

  &__button {
    padding: 0;

    .icon-chevron {
      width: 32px;
      height: 32px;
    }

    &--next {
      transform: rotate(180deg);
    }

    &[disabled] {
      svg {
        [stroke] {
          stroke: var(--color-element-inactive);
        }
        [fill] {
          fill: var(--color-element-inactive);
        }
      }
    }
  }

  &__date {
    display: flex;
    align-items: center;
    justify-content: center;

    &-value {
      font-size: 18px;
      line-height: 32px;
      font-weight: bold;
      text-transform: capitalize;
      text-align: center;
    }
  }
}
