.dialog-switcher {
  padding: 0 24px;

  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  &__button-back {
    display: flex;
    align-items: center;
    padding-left: 0;
    padding-right: 0;
  }

  &__option-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 0;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid var(--color-element-inactive);
    }

    &-label {
      display: flex;
      align-items: center;

      &-icon {
        margin-right: 16px;
        line-height: 0;
      }
    }
  }

  &__children {
    color: var(--color-type-secondary);
    text-align: center;
    font-size: 14px;
    width: 100%;
    display: inline-block;
    margin-top: 36px;
  }
}
