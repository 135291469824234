.rgb-color-list-item {
  margin-bottom: 24px;
  background: var(--color-element);
  border-radius: 12px;

  &__time {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 32px;

    &-value {
      margin-left: 8px;
      font-size: 12px;
      line-height: 16px;
      font-weight: 500;
      color: var(--color-type-primary);
    }
  }

  &__color-wrapper {
    background: var(--color-background);
    border-radius: 12px;
    height: 56px;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    border: 1px solid var(--color-background);
    box-sizing: border-box;
  }

  &__color {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 32px;
    background-color: var(--color);
    border-radius: 12px 0 0 12px;
  }

  &__icon {
    height: 24px;
    line-height: 24px;
  }

  &__draggable {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    &-handle {
      width: 36px;
      height: 36px;
      cursor: grab;

      // https://docs.dndkit.com/api-documentation/draggable#touch-action
      touch-action: none;
    }
  }
}