@import '../../../styles/mixins.scss';

.from-to-input-form {
  display: grid;
  grid-template-columns: repeat(2, calc(50% - 12px));
  column-gap: 24px;

  @include sm() {
    display: flex;
    flex-direction: column;
  }
}