.exalus-condition-item {
  .or-label {
    color: var(--color-type-secondary);
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
    text-align: center;
  }

  .icon-actions {
    stroke: var(--color-accent);
  }
}