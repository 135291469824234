.camera-details {
  position: relative;
  margin-top: 48px;

  .stopped-view {
    position: absolute;
    height: calc(100% - 10px);
    margin-left: -24px;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 24px;
    font-weight: 700;
  }

  video {
    margin-left: -24px;

    +.video-controls {
      display: flex;
      align-items: center;
      position: absolute;
      right: -18px;
      bottom: 12px;
      z-index: 1;

      .icon-pause, .icon-play {
        [stroke] {
          stroke: rgb(10, 2, 3);
        }
      }
    }
  }

  .pip-btn {
    position: absolute;
    right: -18px;
    top: 6px;

    button {
      padding: 4px;
      border-radius: 6px;
      display: flex;
      align-items: center;
      justify-content: center;
      backdrop-filter: blur(7px);
      background: rgba(255, 255, 255, 0.8);
      -webkit-box-shadow: 0px 0px 3px -1px rgba(66, 68, 90, 1);
      -moz-box-shadow: 0px 0px 3px -1px rgba(66, 68, 90, 1);
      box-shadow: 0px 0px 3px -1px rgba(66, 68, 90, 1);
    }
  }

  .button-control {
    width: 58px;
    height: 58px;
    backdrop-filter: blur(7px);
		background: rgba(255, 255, 255, 0.5);
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:nth-child(2) {
      margin-left: 8px;
    }

    &:hover {
      background: rgba(255, 255, 255, 0.5);
    }

    &.full-screen {
      position: absolute;
      top: 32px;
      left: 24px;
      z-index: 1;
    }
  }

  &--full-screen {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 0;
    height: 100%;
    width: 100%;
    background-color: #000;
    display: flex;
    align-items: center;
    justify-content: center;

    video {
      margin-left: 0;

      +.video-controls {
        right: 24px;
        bottom: 32px;
      }
    }

    .pip-btn {
      right: 24px;
      top: 26px;
    }

    .stopped-view {
      position: fixed;
      height: 100vh;
      width: 100vw;
      margin-left: 0;
    }
  }
}