.device-add-update {
 
  .page__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 88px 0 40px 0;
  }

  .text-container {
    margin: 48px 0 24px 0;

    p {
      font-size: 14px;
      text-align: center;
      margin: 0;
      line-height: 32px;
    }

    .text-header {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 16px;
    }
  }
}