.tip-view {
  position: fixed;
  height: 100%;
  width: 100vw;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.85);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;

  &--title {
    font-size: 24px;
    font-weight: 700;
    color: white;
    margin-bottom: 12px;
  }

  &--content {
    font-size: 16px;
    font-weight: 400;
    color: var(--color-element-inactive);
    padding: 0 24px;
    text-align: center;
  }

  .top-right {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .close-btn {
    position: fixed;
    bottom: 48px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    color: var(--color-accent);
    font-weight: 700;
    font-size: 24px;
  }
}