.dashboard {
  display: flex;
  flex-direction: column;
  background-color: var(--color-background-secondary);

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 12px 0 24px;

    &.accent {
      min-height: 50px;
      margin: 0;
      padding-bottom: 70px;
    }

    .notifications_row {
      display: flex;
      align-items: center;
    }
  }

  &__message-box {
    margin-top: 32px;
  }

  &__installation {
    display: flex;
    flex-grow: 1;
    align-items: center;
    max-width: 75%;
    position: relative;
    cursor: pointer;

    &.exalus {
      max-width: 65%;
    }

    &-image {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 16px;
    }

    &-connection-status {
      width: 16px;
      height: 16px;
      position: absolute;
      left: 32px;
      // This buttom and transform values ensures that connection
      // status icon stays in 12px below the middle no matter how many
      // lines installation name has.
      bottom: 50%;
      transform: translate3d(0, calc(50% + 12px), 0);
      z-index: 3;
    }

    &-name {
      font-size: 20px;
      font-weight: 700;
      line-height: 1.3;
      margin-left: 12px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__notification {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    padding: 8px 12px;
  }

  &__popup-nav {
    padding-left: 8px;
    flex-shrink: 0;
  }

  &__title {
    font-weight: 700;
    font-size: 16px;
    color: var(--color-type-primary);
    margin-top: 24px;
  }

  &__sensor-list {
    margin-top: 24px;
    overflow-x: auto;
    white-space: nowrap;
    scrollbar-width: none;
    display: flex;

    > *:not(:last-child) {
      margin-right: 8px;
    }
  }

  .carouselCustom-item {
    .dashboard__device-list {
      flex-grow: 1;
    }
  }

  .tabs__list-item--active {
    .tabs__list-item-content {
      color: var(--color-accent);;

      [class^='icon-'] {
        height: 24px;
      }

      svg {
        [fill] {
          fill: var(--color-accent);
        }

        [stroke] {
          stroke: var(--color-accent);
        }
      }
    }
  }

  &--no-selected-installation {
    // Notification has 8px padding around so it's easier to click on it.  But
    // if we don't have selected installation PopUpNav is not displayed and that
    // makes notification the first element on the right side and because of
    // padding it's not exactly on the right edge.  Negative margin fixes this.
    .dashboard__notification {
      margin-right: -8px;
    }

    .page__container {
      padding-top: 8px;
    }
  }
}
