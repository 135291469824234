.integration-exalus {
  position: fixed;
  background-color: var(--color-background);
  top: 0;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 4;

  &__header {
    padding: 24px;
  }
}
