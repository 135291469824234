.profile {
  &__btn-account {
    height: 53px;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 1;
    border-radius: 8px;
    padding: 16px;
    background: var(--color-element);
    color: var(--color-type-primary);
    border: 1px solid transparent;
    outline: 0;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    margin-bottom: 24px;
  }
}

.create-account-offer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  button {
    width: 95%;
    background-color: var(--color-accent);
    border-radius: 8px;
    height: 53px;
    color: #fff;
    cursor: pointer;
    animation: pulse 3.5s infinite;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

.logout-container {
  display: flex;

  button {
    max-width: 700px;
  }
}
