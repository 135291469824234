.store {

  .page__container {
    padding-bottom: 0;
  }

  &:not(.empty) {
    .page__container {
      padding-right: 0;
    }
  }
}