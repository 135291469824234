.icon-add {
  line-height: 0;

  [fill] {
    fill: var(--color-accent);
    opacity: 0.9;
  }

  [stroke] {
    stroke: #fff;
  }

  &--reverse {
    [fill] {
      fill: var(--color-background);
    }
  }
}
