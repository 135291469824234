@import '../../../../styles/mixins.scss';

.chat-page {

  .page__content {
    background-color: var(--color-element);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @mixin dateText {
    font-size: 12px;
    font-weight: 500;
    color: var(--color-type-secondary);
  }

  .tabs {
    &__list {
      &.full-width {
        width: auto;
        margin-left: 0;
        padding: 0;
      }
    }
  }

  .chat-view {
    flex: 1;
    width: 100%;
    padding-bottom: 24px;
    padding: 0 24px 64px 24px;
    box-sizing: border-box;

    &.empty {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .group-date {
      @include dateText;
      text-align: center;
      margin: 12px 0;
    }

    .message-container {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 24px;

      .message-with-icon {
        display: flex;
        align-items: flex-end;

        svg {
          margin-right: 12px;
        }
      }

      .message {
        border-radius: 12px;
        padding: 12px;
        max-width: 275px;
        min-width: 100px;

        @include md() {
          max-width: none;
        }

        &.dots {
          width: 36px;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        &-date {
          @include dateText;
        }
      }
    }

    .empty-chat-message {
      text-align: center;
      font-weight: 500;
      font-size: 16px;
      color: var(--color-element-inactive);
    }
  }

  .row-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 12px 16px;
    box-sizing: border-box;
    position: fixed;
    bottom: 70px;
    background-color: var(--color-element);

    .input {
      margin-bottom: 0;
      border: 1px solid var(--color-background);
      border-radius: 8px;
    }

    .action-btn {
      height: 32px;
      width: 32px;
      min-width: 32px;
      padding: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 16px;
      border-radius: 8px;

      &:not(&.active) {

        svg {
          [stroke] {
            stroke: var(--color-type-primary);
          }

          &.icon-microphone {
            path {
              &:first-child {
                fill: var(--color-type-primary);
              }
            }
          }
        }
      }

      &.active {
        background-color: #F7DADA;
      }
    }
  }
}