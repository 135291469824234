.action-create-basic {
  &__close-btn {
    margin-bottom: 24px;
    width: 100%;
    height: 40px;
    display: inline-block;
  }

  &__type {
    border-radius: 12px;
    background-color: var(--color-element);
    padding: 16px;
    width: 100%;
    box-sizing: border-box;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 24px;
    text-align: left;
    cursor: pointer;
  }
}
