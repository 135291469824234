.integration-create {
  &__icon {
    display: flex;
    justify-content: center;
    margin: 16px 0 24px;
  }

  &__label {
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: var(--color-type-secondary);
    text-align: center;

    & + .button {
      font-size: 16px;
    }
  }

  &__error {
    display: flex;
    justify-content: center;
    margin-top: -16px;
    margin-bottom: 24px;

    h5 {
      font-weight: 600;
      font-size: 14px;
      line-height: 24px;
      color: var(--color-red);
      text-align: center;
      width: 80%;
    }
  }

  .center-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .max-width-desktop {
      width: 100%;
    }
  }
}