.input-bindings-configuration {

  &.one-channel-selected {
    .checkbox-select {
      .input {
        margin-bottom: 0;
        
        &__input {
          border-bottom-right-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .channel-config-box {
    background: var(--color-element);
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 24px;

    &.first {
      border-top-left-radius: 0;
    }

    &.last {
      border-top-right-radius: 0;
    }
  }

  .tabs--type-pills {
    .item-flex {
      .tabs__list-item {
        &--active {
          position: relative;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
        
          &::before {
            content: '';
            position: absolute;
            height: 17px;
            top: 39px;
            left: 0;
            right: 0;
            background: var(--color-element);
          }
        }
      }
    }
  }
}
