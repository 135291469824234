.input {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  width: 100%;
  overflow: hidden;

  &--disabled {
    .input__input {
      color: var(--color-element-inactive);
    }

    .input__icon {
      opacity: 0.5;
    }

    .end_icon {
      [stroke] {
        stroke: var(--color-element-inactive);
      }
    }
  }

  &--readonly {
    input {
      cursor: default;
    }
  }

  &--no-bottom-margin {
    margin-bottom: 0;
  }

  &--margin-top {
    margin-top: 24px;
  }

  &--no-margin-bottom {
    margin-bottom: 0;
  }

  &--reverse {
    .input__input {
      background-color: var(--color-background);
    }
  }

  &--rows {
    .input__input {
      height: unset;
    }
  }

  &--clickable {
    .input__input {
      cursor: pointer;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    line-height: 1;
    font-size: 14px;
    border-radius: 8px;
    padding: 16px;
    background: var(--color-element);
    color: var(--color-type-primary);
    border: 1px solid transparent;
    outline: 0;
    box-shadow: none;
    width: 100%;
    box-sizing: border-box;
    height: 56px;

    &[autocapitalize = "none"] {
      text-transform: lowercase;

      &::placeholder {
        text-transform: capitalize;
      }
    }

    &:focus:not(:invalid) {
      border-color: var(--color-element-inactive);
    }

    &:disabled {
      color: var(--color-element-inactive);
    }

    &:valid {
      border-color: transparent;
    }

    &.has-error {
      border-color: var(--color-red);

      & ~ .input__error-message {
        display: block;
      }
    }

    &.clicable {
      cursor: pointer;
    }

    &.info {
      padding: 0;
      height: auto;
      background-color: transparent;
    }

    &--validate:invalid {
      border-color: var(--color-red);

      & ~ .input__error-message {
        display: block;
      }
    }

    &-icon {
      margin-right: 16px;
    }
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    padding-bottom: 8px;
    color: var(--color-type-secondary);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &--hidden {
      opacity: 0;
    }
  }

  &--switch {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
  }

  &__wrapper {
    position: relative;

    &.with_start_icon {
      input {
        padding-left: 48px;
      }
    }

    &.right_button {
      input {
        padding-right: 48px;
      }
    }

    .start_icon {
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      left: 16px;
      padding: 0;
    }

    .end_icon {
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      right: 16px;
      padding: 0;
      display: flex;
      align-items: center;
    }

    .clear_icon {
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      right: 16px;
    }

    .custom_counter {
      position: absolute;
      top: 50%;
      transform: translate3d(0, -50%, 0);
      right: 12px;
      display: flex;
      flex-direction: column;

      button {
        border-radius: 50%;
        padding: 0;
        height: 26px;
        width: 26px;
      }
    }

    .input__icon {
      background-color: transparent;
      border: 0;
      position: absolute;
      right: 16px;
      top: 8px;
      height: 36px;
      width: 36px;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &--unset {
        height: unset;
        width: unset;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }

      [class^='icon'] {
        display: inline-block;

        &.icon-password {
          margin-top: 4px;
        }
      }

      &--color {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        top: 50%;
        transform: translate3d(0, -50%, 0);
      }
    }

    textarea {
      padding-top: 20px;
    }
  }

  &__error-message {
    color: var(--color-red);
    font-size: 14px;
    margin-top: 4px;
  }

  &-edit {
    position: relative;

    &__btn {
      position: absolute;
      right: 16px;
      top: 55px;
      transform: translate3d(0, -50%, 0);

      &--submit {
        right: 0;
        top: 50%;
        color: var(--color-type-primary);
        padding: 18px;
      }
    }

    &.btn_save {
      input {
        padding-right: 72px;
      }
    }
  }
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}