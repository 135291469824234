.selected-days-select {
  .dialog-select {
    &__title {
      text-align: left;
      line-height: 24px;
    }

    &__option-item {
      text-align: left;
      font-weight: 400;

      &:not(:last-child) {
        .checkbox__label {
          border-bottom: 1px solid var(--color-element-inactive);
        }
      }

      .checkbox__label {
        margin-left: 16px;
      }

      &--selected {
        font-weight: 700;
      }
    }

    .dialog-time-picker__buttons {
      bottom: auto;
    }
  }
}