.icon-pip {
  position: relative;
  height: 32px;
  width: 32px;

  .icon-photo {
    position: absolute;
    transform: scale(0.5);
    backdrop-filter: blur(7px);

    [stroke] {
      stroke: rgb(10, 2, 3);
    }

    &:nth-child(2) {
      left: -2px;
      top: -1px;
    }

    &:nth-child(1) {
      right: -2px;
      bottom: -1px;
    }
  }
}