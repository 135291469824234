.horizontal-slider {
  color: var(--color-accent);
  padding: 0;
  width: 6px;

  .horizontal-slider__rail {
    background-color: var(--color-type-secondary);
    opacity: 1;
  }

  .horizontal-slider__track {
    background-color: var(--color-accent);
    opacity: 1;
    border: 0;
  }

  .horizontal-slider__thumb {
    width: 23px;
    height: 23px;
    background-color: #fff;
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);

    &.horizontal-slider--active {
      box-shadow: 0 0 4px rgba(0, 0, 0, 0.5);
    }

    ::before {
      box-shadow: none;
    }
  }
}