.tariffs-help {
  .tarrifs-list {
    padding-left: 12px;

    .tariff {

      .name {
        font-size: 14px;
        font-weight: 700;
      }
    }

    .hours {
      margin-bottom: 12px;
    }

    .details {
      &-item {
        display: flex;
        align-items: center;
        margin-bottom: 6px;

        span {
          width: 250px;
          color: var(--color-type-secondary);
        }

        div[class^='icon-toast'] {
          margin-left: 6px;
        }
      }
    }
  }
}