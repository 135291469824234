.device-add-success {
 
  .page__content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 88px 0 40px 0;
  }

  .text-container {
    margin: 48px 0 24px 0;

    p {
      font-size: 14px;
      text-align: center;
      margin: 0;
      line-height: 32px;
    }

    .text-header {
      font-size: 24px;
      font-weight: 700;
      margin-bottom: 16px;
    }
  }

  .success-buttons {
    z-index: 4;
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      max-width: 600px;

      &:first-child {
        margin-bottom: 16px;
      }
    }

    .text-button {
      text-decoration: underline;
      font-weight: 700;
      font-size: 14px;
      padding: 16px;
      margin-top: 8px;
    }
  }
}