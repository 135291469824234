.delete-profile-content {
  padding: 0;

  .forward-content {
    margin-bottom: 12px;

    p {
      line-height: 16px;
    }
  }

  .installation-content {
    max-height: 300px;
    overflow-y: auto;

    .installation {
      display: flex;
      align-items: center;
      justify-content: space-between;
  
      .forward-btn {
        border: 1.5px solid var(--color-type-primary);
        background-color: var(--color-type-primary);
        color: var(--color-element);
        border-radius: 12px;
        height: 28px;
      }
    }
  }
}