.user-item {
  margin-bottom: 24px;
  border-radius: 12px;
  background-color: var(--color-element);
  color: var(--color-type-primary);
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &--data {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    p {
      margin: 0;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .image-container {
      border-radius: 50%;
      width: 56px;
      height: 56px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 12px;
  
      img {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 50%;
      }
    }
  }
}