.notification-settings {
  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      color: var(--color-type-primary);
      font-size: 16px;
      font-weight: 500;
      margin: 0;
      line-height: 24px;
    }

    .switch {
      width: auto;
    }

    .email {
      color: var(--color-type-secondary);
      font-size: 12px;
    }

    .message {
      color: var(--color-element-inactive-dark);
      font-size: 14px;

      &.red {
        color: var(--color-red);
      }

      span {
        text-decoration: underline;
        color: var(--color-type-primary);
        margin-left: 8px;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
}