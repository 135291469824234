.MuiTableContainer-root {
  &.live-table-container {
    max-height: 340px;
    color: var(--color-type-primary);
    line-height: 24px;

    .live-table-container__head {
      .live-table-container__cell {
        font-weight: 500;
        font-size: 16px;

        &--value {
          font-weight: 700;
        }

        .MuiTableSortLabel-root.Mui-active {
          color: var(--color-type-primary);
        }
      }

      .live-table-container__icon {
        color: var(--color-accent);
        width: 20px;
        height: 20px;
      }
    }

    .live-table-container__row {
      &:last-child {
        td, th {
          border: none;
        }
      }
    }

    .live-table-container__cell {
      font-size: 14px;
      padding: 8px 0;
      color: var(--color-type-primary);
      background: var(--color-element);
      border-bottom: 1px solid var(--color-element-inactive-dark);

      &--value {
        font-weight: 600;
      }
    }
  }
}