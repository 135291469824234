.icon-status {
  [stroke] {
    stroke: var(--color-element);
  }

  [fill] {
    fill: var(--color-red);
  }

  &--is-on {
    [fill] {
      fill: var(--color-type-primary);
    }
  }
}
