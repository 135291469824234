.drive-add.success {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 1;

  >div {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .header {
    &__title {
      justify-content: center;
    }

    h2 {
      line-height: 32px;
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      max-width: 250px;
    }
  }

  .sub-header {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    margin: 0;
    color: var(--color-type-secondary);
    margin-bottom: 48px;
  }

  .buttons-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      &:last-of-type {
        padding: 16px;
        margin-top: 8px;
        text-decoration: underline;
        font-weight: 700;
        width: 100%;
      }
    }

  }
}