.color-picker-container {
  position: relative;

  .color-picker {
    --size: calc(268px - 24px);
    border: 12px solid var(--color-background);
    width: var(--size);
    height: var(--size);
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    touch-action: none;
    background: conic-gradient(
      hsl(0, 100%, 50%),
      hsl(30, 100%, 50%),
      hsl(60, 100%, 50%),
      hsl(90, 100%, 50%),
      hsl(120, 100%, 50%),
      hsl(150, 100%, 50%),
      hsl(180, 100%, 50%),
      hsl(210, 100%, 50%),
      hsl(240, 100%, 50%),
      hsl(270, 100%, 50%),
      hsl(300, 100%, 50%),
      hsl(330, 100%, 50%),
      hsl(360, 100%, 50%)
    );

    &.turn-on {
      box-shadow: var(--turn-on-shadow);
    }
  
    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: radial-gradient(rgba(255, 255, 255, 1) 10%, rgba(255, 255, 255, 0) 55%);
    }
  
    &::before {
      content: '';
      position: absolute;
      width: var(--circle-size);
      height: var(--circle-size);
      margin: var(--margin);
      border: 4px solid #FEFEFE;
      border-radius: 50%;
      transform: translate3d(var(--point-x), var(--point-y), 0);
      box-sizing: border-box;
      z-index: 2;
    }
  }

  .power-button-container {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate3d(-50%, -50%, 0);
    background-color: #fff;
    height: 96px;
    width: 96px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 3;

    .circle-container {
      border: 3px solid #ebedf0;
      height: 82px;
      width: 82px;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;

      &.double {
        background-color: var(--button-background-color, var(--color-element));
      }

      &--single-button {
        justify-content: center;
        border: none;

        button {
          width: 83px;
          height: 83px;

          .icon-power {
            transform: scale(1.5);
          }
        }
      }
    }

    &--with-inner-ring {
      height: 142px;
      width: 142px;

      .circle-container {
        border: none;
        height: 96px;
        width: 96px;
        box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.15);
        justify-content: center;
      }
    }

    button {
      min-width: 0;
      height: 60px;
      width: 40px;
      color: var(--button-icon-color, var(--color-type-primary));

      &.on {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 0;
      }

      &.off {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 0;
      }

      .icon-power {
        width: 80px;
      }
    }

    .separator {
      height: 28px;
      width: 2px;
      background-color: var(--button-icon-color, var(--color-type-primary));
    }
  }
}