.phases-button-group {
  display: flex;
  align-items: center;
  margin-top: 16px;
  overflow-x: auto;
  width: 100%;

  &::-webkit-scrollbar {
    display: none;
  }

  &--background-element {
    .phases-button-group__option {
      background-color: var(--color-element);
    }
  }

  &__option {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-background);
    color: var(--color-element-inactive);
    padding: 8px 12px;
    border-radius: 8px;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    flex: 1;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 16px;
    }

    &--active {
      color: var(--color-type-primary);

      .phases-button-group__color {
        opacity: 1;
      }
    }
  }

  &__color {
    border-radius: 50%;
    width: 12px;
    height: 12px;
    margin-right: 8px;
    opacity: .4;
  }
}
